import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { paymentService } from "../../utils/_services/payment.service.js";

function Completion(props) {
  const [messageBody, setMessageBody] = useState("");
  // const { stripePromise } = props;
  // const [stripePromise, setStripePromise] = useState(null);
  
  useEffect(() => {    
    paymentService.paymentConfig().then(async (response) => {
      const { publishableKey } = await response.data;
      
      const stripePromise = loadStripe(publishableKey);

      stripePromise.then(async (stripe) => {
        const url = new URL(window.location);
        const clientSecret = url.searchParams.get(
          "payment_intent_client_secret"
        );
        const { error, paymentIntent } = await stripe.retrievePaymentIntent(
          clientSecret
        );

        setMessageBody(
          error ? (
            `> ${error.message}`
          ) : (
            <>
              &gt; Payment {paymentIntent.status}:{" "}
              <a
                href={`https://dashboard.stripe.com/test/payments/${paymentIntent.id}`}
                target="_blank"
                rel="noreferrer"
              >
                {paymentIntent.id}
              </a>
            </>
          )
        );
      });
    });
  }, []);

  return (
    <>
      <h1>Thank you!</h1>
      <a href="/">home</a>
      <div
        id="messages"
        role="alert"
        style={messageBody ? { display: "block" } : {}}
      >
        {messageBody}
      </div>
    </>
  );
}

export default Completion;
