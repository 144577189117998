export const WelcomeHeader = () => {
    return (<div className="p-3 text-center">
        <h1 className="title m-2">
            Bienvenue à bord 👋
        </h1>
        <p className="text-muted" style={{ fontSize: "larger" }}>Nous allons personnaliser BtpGo pour vous</p>
    </div>)
}

export const KnowEachOtherHeader = () => {
    return (<div className="p-3 text-center">
        <h1 className="title m-2">
            Faisons connaissance 🤝
        </h1>
    </div>)
}

export const IsReadyHeader = () => {
    return (<div className="p-3 text-center">
        <h1 className="title m-2">
            Parfait, BtpGo est prêt 🚀
        </h1>
    </div>)
}