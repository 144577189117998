import React, { useEffect, useState } from "react";
import TextareaWithLimit from "../../components/Ui/TextareaWithLimit";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { v4 } from "uuid";

const ManageInsurance = ({ onSubmit, onCancel, insurance }) => {
  const [editedInsurance, setEditedInsurance] = useState({});

  const [errors, setErrors] = useState([]);

  useEffect(() => {
    setEditedInsurance({ ...insurance });
  }, [insurance])

  const onChange = (e) => {
    setEditedInsurance({
      ...editedInsurance,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmitChange = () => {
    const newErros = [];
    if (editedInsurance.title === undefined || editedInsurance.title === null || editedInsurance.title === '') {
      newErros.push("Le titre est obligatoire");
    }

    if (editedInsurance.startDate !== undefined && editedInsurance.endDate !== undefined && new Date(editedInsurance.startDate) > new Date(editedInsurance.endDate)) {
      newErros.push("La date de début de l'assurance doit être inférieur à la date de fin")
    }

    if (editedInsurance.guid === undefined) {
      editedInsurance.guid = v4();
    }

    if (newErros.length === 0) {
      onSubmit(editedInsurance)
    }


    setErrors(newErros);
  }

  return (
    <div>
      <div className="center-content">
        <div className="row ">
          <div className="col-12" >
            <FormGroup>
              <Label for="startDate">
                Titre de l'assurance <span className="text-danger">*</span>
              </Label>
              <TextareaWithLimit
                id={"insuranceTitle"}
                name={"title"}
                maxlength={200}
                value={editedInsurance.title}
                onChange={onChange}
                placeholder={"Nouvelle assurance"}
                rowsCount={1}
              />
            </FormGroup>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-6" >
            <FormGroup>
              <Label for="startDate">
                Date de début
              </Label>
              <Input
                id="startDate"
                name="startDate"
                value={editedInsurance.startDate ? editedInsurance.startDate.substring(0, 10) : undefined}
                placeholder="date de début"
                onChange={onChange}
                type="date"
              />
            </FormGroup>
          </div>
          <div className="col-6" >
            <FormGroup>
              <Label for="endDate">
                Date de fin
              </Label>
              <Input
                id="endDate"
                name="endDate"
                placeholder="date de début"
                value={editedInsurance.endDate ? editedInsurance.endDate.substring(0, 10) : undefined}
                onChange={onChange}
                type="date"
              />
            </FormGroup>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-12 col-md-6" >
            <FormGroup>
              <Label for="euroAmount">Montant de rembourssement </Label>
              <Input
                type="number"
                id="refundAmount"
                name="refundAmount"
                value={editedInsurance.refundAmount}
                onChange={onChange}
                placeholder="Entrez le montant en euro"
              />
            </FormGroup>
          </div>
        </div>
        {errors.length > 0 &&
          <div className="row ">
            <div className="col-12" >
              <ul>
                {errors.map((value, index) =>
                  <li key={`error-${index}`} className="text-danger">{value}</li>
                )}
              </ul>
            </div>
          </div>
        }
        <div className="row">
          <div className="col-12 text-right" >
            <Button
              className="my-3 btn btn-primary"
              title="Annuler"
              onClick={onCancel}
            >
              Annuler
            </Button>
            <Button
              className="my-3 btn btn-success"
              title="Sauvegarder"
              onClick={onSubmitChange}
            >
              Sauvegarder
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageInsurance;
