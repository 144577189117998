import React, { useEffect, useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import "./SignatureScreen.css"; // Import the external CSS file
import { removeUrlPartFromContentImage } from "../../utils/fileloader";
import { Label } from "reactstrap";

const SignatureScreen = ({ name, content, imageURL, onChangeImage, title, subtitle, uploadButtonText, canDraw = true }) => {
  const [imageData, setImageData] = useState(content ?? imageURL ?? '');
  const imageId = `upload-image-${name}`;

  useEffect(() => {
    if (imageURL) {
      setImageData(imageURL);
    }
  }, [imageURL]);

  const sigCanvas = useRef({});

  // To clear the drawn signature
  const clearImage = () => {
    setImageData(null);
    if (canDraw) {
      sigCanvas?.current?.clear();
    }
    onChangeImage({ content: null, name: null });
  };

  // To save the drawn signature
  const saveSignature = () => {
    if (!sigCanvas.current.isEmpty()) {
      const dataURL = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      onChangeImage({ content: removeUrlPartFromContentImage(dataURL) });
      setImageData(dataURL);
    }
  };

  // To handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        debugger;
        let dataContent = reader.result;
        onChangeImage({ content: removeUrlPartFromContentImage(dataContent), name: file.name });
        setImageData(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onUploadImage = () => {
    if (canDraw) {
      saveSignature();
    }
    document.getElementById(imageId).click();
  };

  return (
    <div className="text-center" >
      <Label>
        {title}
      </Label>
      <p className="subtitle">{subtitle}</p>

      <div className="buttons-container">
        {canDraw && (
          <button type="button" className={`tab-button ${!imageData ? 'active' : ''}`} onClick={clearImage}>
            Dessiner
          </button>
        )}
        <button type="button" className={`tab-button ${imageData ? 'active' : ''}`} onClick={onUploadImage}>
          {uploadButtonText ?? 'Télécharger une image'}
        </button>
        <input
          type="file"
          id={imageId}
          style={{ display: "none" }}
          onChange={handleImageUpload}
        />

      </div>

      <div className="signature-box">
        {imageData ? (
          <img
            src={imageData}
            alt="Signature"
            className="uploaded-signature"
          />
        ) : canDraw && (
          <SignatureCanvas
            ref={sigCanvas}
            canvasProps={{ width: 245, height: 170, className: "sigCanvas" }}
            onEnd={saveSignature}
          />
        )}
      </div>

      <div className="action-buttons">
        {/* <button className="save-button" type="button"  onClick={saveSignature}>Enregistrer la signature</button>  */}
        <button className="clear-button" type="button" onClick={clearImage}>Effacer</button>
      </div>
    </div>
  );
};

export default SignatureScreen;
