import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { workAvailabilityService } from "../../utils/_services/workAvailability.services.js";

const initialState = {
  workAvailabilities: [],
  status: "idle",
  error: null,
};

export const fetchWorkAvailabilities = createAsyncThunk(
  "workAvailability/getAll",
  async () => {
    const response = await workAvailabilityService.getAllWorkAvailability();
    return response.data;
  }
);

export const workAvailabilitySlice = createSlice({
  name: "workAvailabilities",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchWorkAvailabilities.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.workAvailabilities = action.payload;
      })
      .addCase(fetchWorkAvailabilities.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchWorkAvailabilities.rejected, (state, action) => {
        state.workAvailabilities = "failed";
        state.error = action.error.message;
      });
  },
});

export const getAllworkAvailabilities = (state) => state.workAvailabilities.workAvailabilities;
export const getWorkAvailabilitiesStatus = (state) => state.workAvailabilities.status;
export const getWorkAvailabilitiesError = (state) => state.workAvailabilities.error;

export default workAvailabilitySlice.reducer;
