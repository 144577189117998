import { useEffect } from "react";
import "react-notifications/lib/notifications.css";
import { Progress, Badge } from "reactstrap";
import { Container } from "reactstrap";
import UserHeader from "../../components/Headers/UserHeader.js";
import GenericHeader from "../../components/Headers/GenericHeader";
import PhotoUploader from "../../components/Photo/PhotoUploader";

import ViewProfile from "../../components/Profile/ViewProfile";
import EditProfile from "../../components/Profile/EditProfile";
import EditProfilePro from "../../components/Profile/EditProfilePro";
import EditCompany from "./company/editCompany.js";

import Buttons from "../../components/Ui/Button";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserStatus,
  getCurrentUser,
  fetchUser,
} from "../../features/slices/userSlice.js";
import ViewPublicProfile from "../../components/Profile/ViewPublicProfile";
import EditCompanyCommunication from "./company/editCompanyCommunication.js";
import ReviewsPage from "../Review/ReviewsPage";
import ScrolledTabs from "../../components/Tab/ScrolledTabs";
import { capitalizeFirstLowercaseRest } from "../../utils/_helpers/stringUtils.js";
import CardView from "../../components/Cards/CardView.js";

const Profile = () => {
  const { userData } = useParams();

  const user = useSelector(getCurrentUser);
  const userStatus = useSelector(getUserStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userStatus === "idle") {
      dispatch(fetchUser());
    }
  }, [userStatus, dispatch]);

  const isNumeric = (string) => Number.isFinite(+string);

  const getCommingSoon = (title) => {
    return (
      <CardView
        title={title}
        children={
          <div style={{            
            fontSize: '2rem',
            textAlign: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            padding: '20px',
            borderRadius: '10px',
          }}>
            Bientôt disponible
          </div>}
      />
    )
  };

  return (
    <>
      {/* {userStatus === "succeeded" && */}
      {user &&
        (userData && user.id === userData ? (
          <>
            <GenericHeader />

            <div className="d-flex justify-content-center">
              <Buttons
                linkTo={"/ap/user-profile"}
                buttonText="Modifier mon profil"
              />
            </div>

            <Container className="mt-2">
              <ViewProfile searchedUser={user} />
            </Container>
          </>
        ) : (isNumeric(userData) ?
          <>
            <GenericHeader />
            <ViewPublicProfile />
          </>
          : (
            <>
              <UserHeader currentUser={user} />
              <Container className="mt-2 mb-2" style={{ maxWidth: "1250px" }}>
                <div className="progress-info row">
                  <div className=" mb-2 col-md-5 col-sm-11 text-lg-left text-center">
                    <Badge
                      className="text-sm py-2 progress-label "
                      style={{ color: "#5e72e4", backgroundColor: "#f8f9fa" }}
                      // color={"info"}
                      pill
                    >
                      Profil rempli à{" "}
                      <strong>{user.profileFilledPercentage} %</strong>
                    </Badge>
                  </div>

                  <div className="col-md-7 col-sm-11 text-center text-lg-right">
                    <Buttons
                      linkTo={"/ap/" + user.id}
                      buttonText="Voir mon profil publique"
                    />
                    <Badge
                      className="text-sm py-2 "
                      color={user.status === "Validated" ? "success" : "danger"}
                      pill
                    >
                      Profil{" "}
                      {user.status === "Validated"
                        ? "validé"
                        : "en cours de validation"}
                    </Badge>

                  </div>
                </div>

                <Progress
                  animated
                  max="100"
                  value={user.profileFilledPercentage}
                // color="success"
                />
                <ScrolledTabs childrens={[
                  { title: "Perso", component: <EditProfile userData={user} tabTitle="Informations personnelles" /> },
                  { title: "Pro", component: <EditProfilePro userData={user} tabTitle="Informations professionnelles" /> },
                  { title: "Entreprise", component: <EditCompany companyData={user.company} userId={user.id} tabTitle="Informations sur l'entreprise" /> },
                  { title: "Communication", component: <EditCompanyCommunication companyData={user.company} userId={user.id} tabTitle="Communication de l'entreprise" /> },
                  { title: "Portfolio", component: <PhotoUploader tabTitle="Portfolio" /> },
                  { title: "Partenariat", component: getCommingSoon("Partenariat") },
                  { title: "Tarif", component: getCommingSoon("Tarif") },
                  { title: "Documents", component: getCommingSoon("Documents") },
                  { title: "Outils", component: getCommingSoon("Outils")  },
                  { title: "RSE", component: getCommingSoon("RSE")  },
                  {
                    title: "Avis",
                    component: user.company &&
                      <ReviewsPage
                        companyId={user.company?.id}
                        companyName={user.company?.name}
                        tabTitle={`Avis de l'entreprise ${capitalizeFirstLowercaseRest(user.company?.name)}`} />
                  },
                ]} />
              </Container>
            </>
          )))}
    </>
  );
};

export default Profile;
