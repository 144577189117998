import React, { useState } from 'react';
import {
    Button,
    Form,
    FormGroup,
    Label,
    FormFeedback
} from 'reactstrap';
import './AddReviewForm.css';
import GenerateFormInputWithLimit from '../Ui/GenerateFormInputWithLimit';
import { reviewsService } from '../../utils/_services/review.service';
import { NotificationManager } from 'react-notifications';

function AddReviewForm(props) {
    const { companyId, userId, onSubmitSuccess } = props;

    const [title, setTitle] = useState('');
    const [rating, setRating] = useState(0);
    const [hoverRating, setHoverRating] = useState(0);
    const [comment, setComment] = useState('');
    const [errors, setErrors] = useState({});

    const validate = () => {
        const newErrors = {};
        if (!title.trim()) newErrors.title = 'Le titre est requis.';
        if (rating === 0) newErrors.rating = 'La note est requise.';
        if (!comment) newErrors.comment = 'Le commentaire est requis.';
        return newErrors;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        } else {
            reviewsService
                .AddReview({
                    userId,
                    companyId,
                    rating,
                    comment
                })
                .then((response) => {
                    if (response.data.resultState === 0) {
                        NotificationManager.error(response.data.message);
                    } else {
                        onSubmitSuccess();
                    }
                })
                .catch((err) => NotificationManager.error(err));
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <FormGroup>
                <Label for="rating">Note générale</Label>
                <div id="rating">
                    {[1, 2, 3, 4, 5].map((star) => (
                        <span
                            key={star}
                            className={`star ${star <= (hoverRating || rating) ? 'filled' : ''}`}
                            onMouseEnter={() => setHoverRating(star)}
                            onMouseLeave={() => setHoverRating(0)}
                            onClick={() => setRating(star)}
                        >
                            ★
                        </span>
                    ))}
                </div>
                {errors.rating && <FormFeedback className="d-block">{errors.rating}</FormFeedback>}
            </FormGroup>
            <FormGroup>
                <GenerateFormInputWithLimit
                    forInput="title"
                    label="Ajouter un titre"
                    editModeClassName={true}
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    maxlength={100}
                    name="title"
                    rowsCount={1}
                    placeholder="Titre de votre avis"
                    type="text"
                    disabled={false}
                    displayFeedback={errors.title}
                    feedbackValue={errors.title}
                />
            </FormGroup>
            <FormGroup>
                <GenerateFormInputWithLimit
                    forInput="comment"
                    label="Ajouter un commentaire écrit"
                    editModeClassName={true}
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    maxlength={1000}
                    name="comment"
                    rowsCount={6}
                    placeholder="Recommanderiez-vous cette entreprise à d'autres ? Pourquoi ?"
                    type="textarea"
                    disabled={false}
                    displayFeedback={errors.comment}
                    feedbackValue={errors.comment}
                />
            </FormGroup>
            <Button type="submit" color="warning">Envoyer</Button>
        </Form>
    );
}

export default AddReviewForm;
