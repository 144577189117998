
import CardView from "../../../components/Cards/CardView";
import AccountAction from "./accountAction";
import { menus } from "./constants";

const Main = ({  onClick }) => {
    return (
        <>
            {menus.map((item) => {
                return (
                    <CardView
                        children={
                            <>
                                <h3 className="mb-4">{item.title}</h3>
                                {item.components.map((component) => {
                                    return (<AccountAction id={component.id} title={component.title} onClick={() => onClick(component.id)} />);
                                })}
                            </>
                        }
                    />
                )
            })}
        </>
    );
}

export default Main;