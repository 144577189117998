import React, { useEffect, useState } from "react";
import CustomNavLink from "../Forms/inputs/Navlink.js";
import {Notifications } from "../Icons/icons.jsx";
import { cronTask, datesDifference, newDate } from "../../utils/date.js";
import { useTranslation } from "react-i18next";
import {  UncontrolledDropdown, DropdownMenu, DropdownToggle, Media } from "reactstrap";
import { notificationService } from "../../utils/_services/notification.services.js";
import useFetchUser from "../../hooks/useFetchUser.js";

const NotificationMenu = (props) => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const [notReadCount, setNotReadCount] = useState(0);
  const user = useFetchUser();

  useEffect(() => {

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t])

  useEffect(() => {
    cronTask(60, loadData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadData = async () => {
    const result = await notificationService.getAllNotfications(user.id);

    setData(result.data.map(notif => ({
      id: notif.id,
      content: notif.content,
      notificationType: notif.notificationType,
      severity: notif.severity,
      creationDate: notif.creationDate,
      readed: notif.readed,
    })))
  }

  useEffect(() => {
    setNotReadCount(data.filter(d => !d.readed)?.length)
  }, [data])

  const onRead = (ids) => {
    if (ids.length === 0) return;

    const responce = notificationService.updateToRead(ids);

    const editedData = [...data];
    responce.forEach(element => {
      const item = editedData.find(i => i.id === element.id);
      if (item) {
        item.readed = element.readed
      }
    });
    setData(editedData)
  }

  const onClearAll = () => {
    const responce = notificationService.clearAll(user.id);

    if (responce)
      setData([]);
  }

  const pastTime = (date) => {
    const pastDate = newDate(date);
    const now = newDate();
    const diff = datesDifference(pastDate, now);

    if (diff.days > 0) return `${diff.days} ${t("day").slice(0, 1)}`
    else if (diff.hours > 0) return `${diff.hours} ${t("hour").slice(0, 1)}`
    else if (diff.minutes > 0) return `${diff.minutes} ${t("minute").slice(0, 3)}`
    else if (diff.secondes >= 0) return t("now")
    else return ""
  }

  return (
    <UncontrolledDropdown nav>
      <DropdownToggle  nav>
        <Notifications className="text-white" />
        {notReadCount > 0 && <span className="text-white notif-badge">{notReadCount}</span>}
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu-arrow notification" right>
        <div className="noti-head">
          <h6 className="d-inline-block mb-0">{t("Notifications")}</h6>
          <div className="float-right">
            <span className="text-xs text-muted mx-1" >
              <CustomNavLink
                title={t("mark as read")}
                action={() => onRead(data.filter(d => !d.readed).map(d => d.id))}
                to={"#"} />
            </span>
            <span className="text-xs text-muted mx-1" >
              <CustomNavLink
                title={t("clear all")}
                action={() => onClearAll()}
                to={"#"} />
            </span>
          </div>
        </div>
        <ul className="noti-body">
          {data.map(d => {
            if (d.notificationType === "WelcomeMessage") {
              const content = JSON.parse(d.content);

              return (
                <li className="notification" style={{ backgroundColor: !d.readed ? 'rgb(55 143 233 / .2)' : 'white' }} onClick={() => { if (!d.readed) onRead([d.id]) }}>
                  <Media className="d-flex align-items-center">
                    <Media body>
                      <div style={{fontWeight: "600"}}>
                        {content.title}
                      </div>
                      <div className="mt-1 ">
                        {" "} {content.message}
                      </div>
                    </Media>
                  </Media>
                  <div className="n-time text-primary mt-2" >
                    {pastTime(d.creationDate)}
                  </div>

                  {/* <div className="media">
                  {d.readed && <DraftsOutlined className="notif-icon" />}
                  {!d.readed && <MailOutline className="notif-icon" />}
                  <div className="media-body">
                    <p>
                      <strong className="text-adjust" style={{ color: d.severity ? severityColor[d.severity] : "inherited" }}>{t(d.title)}</strong>
                      <span className="n-time text-muted" >
                        {pastTime(d.creationDate)}
                      </span>
                    </p>
                    <p > <span className="text-adjust" style={{ color: d.severity ? adjustColor(severityColor[d.severity]).adjustedColor : "inherited" }}>{t(d.subTitle)}</span></p>
                  </div>
                </div> */}
                </li>
              )
            }
            return (<div className="empty-table  text-muted">{t(t("no notification"))}</div>);
          }
          )}
          {data.length === 0 && <div className="empty-table  text-muted">{t(t("no notification"))}</div>}
        </ul>

        {/* <div className="noti-footer">
          <CustomNavLink
            title={t("See All")}
            action={() => { }}
            to={"#"} />
        </div> */}
      </DropdownMenu>
    </UncontrolledDropdown>
  )
};

export default NotificationMenu;
