import React from "react";
import AuthNavbar from "../Navbars/Auth/AuthNavbar";

const BlogIntro = () => {
  return (
    <>
    <AuthNavbar />
      <div className="bienvenue">Bienvenue sur notre blog</div>
    </>
  );
};

export default BlogIntro;
