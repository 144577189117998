import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./contact.css";
import Buttons from "../../../Ui/Button";
import "../../../Ui/fond.css";
import { TitleWelcome } from "../../common.js";

const Contact = () => {
  useEffect(() => {
    Aos.init({
      duration: 3000,
    });
  }, []);
  return (
    <div className="container-fluid bgDefault" id="contact">
      <div
        className="row d-flex justify-content-center "
        style={{
          alignItems: "center",
          height: "auto",
        }}
      >
        <div className="row" style={{ marginRight: "0" }}>
          <div className="contact" data-aos="fade-right">
            <TitleWelcome>
              Choisisseaz BtpGO dès maintenant
            </TitleWelcome>
            <p className="contact-text">
              🔗 Découvrez BTP Go : La plateforme incontournable pour vos projets BTP et immobiliers ! 🚀
              <br />
              Vous êtes professionnel du BTP, artisan, architecte ou acteur de l'immobilier ? Facilitez-vous la vie avec BTP Go,
              <br />
              la plateforme conçue pour optimiser vos connexions, collaborations et opportunités d'affaires.
              Grâce à une interface intuitive et des fonctionnalités innovantes,
              BTP Go vous permet de vous concentrer sur l’essentiel : votre réussite professionnelle. 🏗️✨
              <br />
              🧩 Une Solution Complète pour Tous les Acteurs du Secteur
              <br />
              Que vous soyez entrepreneur, gestionnaire de patrimoine, promoteur immobilier ou chef de projet, BTP Go s'adapte à vos besoins en vous offrant :
              <br />
              ✅ Recherche simplifiée de partenaires : Trouvez des professionnels qualifiés pour chaque étape de vos projets.
              <br />
              ✅ Outils de collaboration efficaces : Échangez rapidement via notre messagerie instantanée. 📲
            </p>

            <Buttons linkTo={process.env.REACT_APP_WELCOME_PAGE_PATH} buttonText="S'inscrire gratuitement" />
          </div>
          <div className="col-md-6 ">
            <img
              style={{ width: "90%" }}
              alt="..."
              src={require("../../../../assets/img/welcome/contact.png")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
