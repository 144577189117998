import Profile from "./views/profile/profile";
import Subscription from "./views/subscription/index";
import MainStepper from "./views/CreateSite/MainStepper";
import Search from "./views/Search/Search.js";
import Blog from "./layouts/Blog";
import Social from "./views/social/Social";
import AddReviewPage from './views/Review/AddReviewPage';
import Billing from "./views/Billing/Main";
import Quotes from "./views/Quotes/Main";
import Roles from "./views/Settings/Roles/Roles";
import ManageMyAccount from "./views/Settings/ManageMyAccount/index";
import * as subjects from "./utils/constants/subjects";
import Users from "./views/Settings/Users/Users";
// import Projects from "./views/Projects/Main";

var routes = [
  {
    path: "/:userData",
    component: Profile,
    layout: "/ap",
    showInMenu: false,
    type: "item",
  },
  {
    path: "/blog",
    layout: "/blog",
    showInMenu: false,
    type: "item",
  },
  {
    id: subjects.PROFILE,
    path: "/user-profile",
    name: "Mon profil",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/ap",
    showInMenu: true,
    type: "item",
  },
  {
    path: "/addReview/:companyId",
    component: AddReviewPage,
    layout: "/admin",
    showInMenu: false,
  },
  {
    id: subjects.SEARCH,
    path: "/search",
    name: "Trouver un pro",
    icon: "fa fa-search text-orange",
    component: Search,
    layout: "/admin",
    showInMenu: true,
    type: "item",
  },
  {
    id: subjects.SOCIAL,
    path: "/social",
    name: "Réseau social",
    icon: "ni ni-world-2 text-green",
    component: Social,
    layout: "/admin",
    showInMenu: true,
    type: "item",
  },
  {
    id: subjects.CREATE_SITE,
    path: "/create-site",
    name: "Creation de site",
    icon: "ni ni-settings text-red",
    component: MainStepper,
    layout: "/admin",
    showInMenu: true,
    type: "item",
  },
  {
    id: subjects.BILLING,
    path: "/invoices",
    name: "Facturation",
    icon: "fa fa-file-invoice-dollar text-yellow",
    component: Billing,
    layout: "/admin",
    showInMenu: true,
    type: "item",
  },
  {
    id: subjects.DEVIS,
    path: "/quotes",
    name: "Devis",
    icon: "fa fa-file-invoice text-primary",
    component: Quotes,
    layout: "/admin",
    showInMenu: true,
    type: "item",
  },
  {
    id: subjects.SUBSCRIPTION,
    path: "/subscription",
    name: "Abonnement",
    icon: "ni ni-money-coins text-yellow",
    component: Subscription,
    layout: "/admin",
    showInMenu: true,
    type: "item",
  },
  {
    path: "/blogHelp",
    name: "Blog",
    icon: "fa fa-blog text-info",
    component: Blog,
    layout: "/admin",
    showInMenu: true,
    type: "item",
  },
  {
    id: subjects.ADMINISTRATION,
    // path: "/roles",
    name: "Administration",
    icon: "fa fa-cog text-info",
    // component: Roles,
    // layout: "/admin",
    showInMenu: true,
    type: "collapse",
    childrens: [
      {
        id: subjects.MY_ACCOUNT,
        path: "/manageMyAccount",
        name: "Mon compte",
        icon: "fa fa-users text-info",
        component: ManageMyAccount,
        layout: "/admin",
        showInMenu: true,
        type: "item",
      },      
      {
      id: subjects.USERS,
      path: "/users",
      name: "Utilisateurs",
      icon: "fa fa-users text-info",
      component: Users,
      layout: "/admin",
      showInMenu: true,
      type: "item",
    },
    {
      id: subjects.ROLES,
      path: "/roles",
      name: "Roles",
      icon: "fa fa-user-shield text-info",
      component: Roles,
      layout: "/admin",
      showInMenu: true,
      type: "item",
    }],
  },
];
export default routes;
