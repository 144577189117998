import CardView from "../../../components/Cards/CardView";
import { deleteAccount, mainView } from "./constants";
import { ArrowBack } from "../../../components/Icons/icons.jsx";
import DeleteAccount from "./deleteAccount";

const ActionContent = ({ actionView, onReturnClick, onClickDeleteAccount }) => {
    const getActionView = () => {
        switch (actionView) {
            case deleteAccount:
                return <DeleteAccount onClickDeleteAccount={onClickDeleteAccount}/>;
            default:
                return "Rien a faire";
        }
    }

    return (
        <>
            <CardView
                children={
                    <>
                        <div className="cursor-pointer  mb-3" onClick={() => onReturnClick(mainView)}>
                            <ArrowBack className="mr-2" /> Retour
                        </div>
                        {getActionView()}
                    </>
                }
            />
        </>
    );
};

export default ActionContent;