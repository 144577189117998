import Axios from "./caller.service.js";

let createPayment = () => {
  return Axios.post("/api/Payment");
};

let paymentConfig = () => {
  return Axios.get("/api/Payment/config");
};

let createCheckoutSession = (priceId, userId, email) => {
  return Axios.post("/api/Payment/create-checkout-session", {
    userId,
    email,
    priceId,
  });
};

let getUserSubscription = () => {
  return Axios.get("/api/Payment/get-user-subsciption");
};

let getUserPortalLink = () => {
  return Axios.post("/api/Payment/create-portal-link");
}

let getActivesPrices = async () => {
  var data = await Axios.get("/api/Payment/get-actives-prices").then(response => {
    return response.data;
  });

  return data.map(product => subProductDtoToSubproductView(product));
}

const subProductDtoToSubproductView = (price) => {
  const product = price.product;
  return {
    id: product.id,
    image: product.image,
    title: product.name,
    description: product.description,
    priceId: price.id,
    amount: price.unitAmount,
    currency: price.currency === 'eur' ? "€" : price.currency,
    features: product.features,
    // choosePlanText: "Choose",
    type: price.type,
    interval: price.interval,
    intervalCount: price.intervalCount,
    trialPeriodDays: price.trialPeriodDays
  }
};


export const paymentService = {
  createPayment,
  paymentConfig,
  createCheckoutSession,
  getUserSubscription,
  getUserPortalLink,
  getActivesPrices
};
