import React, { useEffect, useRef, useState } from "react";
import i18next from "i18next";
import QuotesView from "../../components/Tables/MaterialTable";
import {
  reference,
  priceHt,
  totalPrice,
  creationDate,
} from "./Model";
import NavLink from "../../components/Forms/inputs/Navlink";
import { Dialog, dialogType } from "../../components/Dialogs/Dialog";
import { DownloadIcon } from "../../components/Icons/icons.jsx";
import { numberWithCommas } from "../../utils/maths";
import { Button, FormGroup, Input, Label } from "reactstrap";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { formType } from "../../components/Forms/forms";

const newQuote = "Nouveau devis";
const addNewQuote = "+ Nouveau devis";

const Quotes = (props) => {
  const isLoading = false;
  const [showDialog, setShowDialog] = useState(false);
  const toggle = () => setShowDialog(!showDialog);
  const inputReferenceRef = useRef(null);

  const onInit = () => {
    // Access the input value correctly
    const referenceValue = inputReferenceRef.current.value;
    props.handleInit(-1, { reference: referenceValue });
    toggle();
  };

  useEffect(() => {
    if (showDialog === true && inputReferenceRef.current) {
      // Clear the input and set focus when the dialog opens
      inputReferenceRef.current.value = "";
      inputReferenceRef.current.focus();
    }
  }, [showDialog]);

  return (
    <>
      <QuotesView
        id={"Quotes"}
        rowId={"id"}
        isLoading={isLoading}
        title={
          <NavLink title={addNewQuote} action={toggle} to={"#"} />
        }
        data={props.data}
        columns={[
          reference,   
          {
            title: "Client",
            field: "clientName",
            formType: formType.text,           
          },       
          creationDate,
          {
            ...priceHt,
            ...{
              render: (rowData) => `${numberWithCommas(rowData.totalPriceHt)} ${rowData.currency}`,
            },
          },
          {
            title: i18next.t("VAT"),
            field: "vat",
            render: (rowData) =>
              rowData.includeTva ? `${rowData.tvaAmount} ${rowData.currency}` : "N/A",
          },
          {
            ...totalPrice,
            ...{
              render: (rowData) => `${numberWithCommas(rowData.totalPrice)} ${rowData.currency}`,
            },
          },
          {
            title: "",
            field: "actions",
            width: "5%",
            render: (rowData) => (
              <DownloadIcon
                onClick={(event) => {
                  props.handleDownload(rowData);
                  event.stopPropagation();
                }}
                title={i18next.t("Download")}
              />
            ),
            filtering: false,
          },
        ]}
        rowsPerPage={10}
        handleRowClick={props.handleRowClick}
        handleDelete={props.handleDeleteClick}
        handleFiltering={true}        
      />
      {showDialog && (
        <Dialog
          type={dialogType.medium}
          handleClose={toggle}
          title={newQuote}
          handleCancel={toggle}
          component={
            <>
              <DialogContent>
                <FormGroup>
                  <Label for="reference">Réference (numéro du devis)</Label>
                  <Input
                    type="text"
                    name="reference"
                    id="reference"
                    innerRef={inputReferenceRef}
                    placeholder="Enter reference"
                  />
                </FormGroup>
              </DialogContent>
              <DialogActions>
                <Button onClick={toggle} color="secondary">
                  Annuler
                </Button>
                <Button onClick={onInit} type="submit" color="primary">
                  Sauvegarder
                </Button>
              </DialogActions>
            </>
          }
        />
      )}
    </>
  );
};

export default Quotes;
