import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";
import logo from "../../../assets/img/welcome/logo_btpgo-btipou.png";
import AuthNavMobile from "./AuthNavMobile.js";
import "./auth.css";

const AuthNavbar = () => {
  const [collapseOpen, setCollapseOpen] = useState(false);

  const closeCollapse = () => {
    setCollapseOpen(!collapseOpen);
  };
  return (
    <>
      <Navbar
        className="navbar-top navbar-horizontal navbar-dark "
        expand="md"
        style={{ background: "#107aca" }}
      >
        <Container className="px-4">
          <NavbarBrand to={process.env.REACT_APP_WELCOME_PAGE_PATH} tag={Link}>
            <div>
              <img
                src={logo}
                alt="..."
                style={{ width: "15rem", height: "5rem" }}
              />
            </div>
          </NavbarBrand>
          <Nav className="nav_grand" navbar>
            <NavItem>
              <NavLink
                className="nav-link-icon"
                to={process.env.REACT_APP_WELCOME_PAGE_PATH}
                tag={Link}
              >
                <i className="ni ni-key-25" />
                <span
                  className="nav-link-inner--text"
                  style={{ color: "white" }}
                >
                  Connectez vous c'est gratuit
                </span>
              </NavLink>
            </NavItem>
          </Nav>
          <button
            className="navbar-toggler"
            id="navbar-collapse-main"
            onClick={closeCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {collapseOpen ? (
            <AuthNavMobile closeCollapse={closeCollapse} />
          ) : null}
        </Container>
      </Navbar>
    </>
  );
};

export default AuthNavbar;
