import i18next from "i18next";

const getEnv =  key => {
  if (window._env && window._env[`REACT_APP_${key}`]) {
      return window._env[`REACT_APP_${key}`];
  }
  if (typeof process !== 'undefined' && process.env[`REACT_APP_${key}`]) {
      return process.env[`REACT_APP_${key}`];
  }
  return '';
};

export const API_URL = getEnv('API_URL');

export const API_ROUTES = {
  REGISTER_USER: () => `${API_URL}/api/Users/register`,
  UPDATE_USER: (userId) => `${API_URL}/api/users/${userId}`,
  DELETE_USER: (userId) => `${API_URL}/api/users/${userId}`,
  GET_USER_BY_ID: (userId) => `${API_URL}/api/users/${userId}`,
  SEARCH_USERS: () => `${API_URL}/api/users/search`,
  GET_ALL_USERS: () => `${API_URL}/api/users/all`,
  GET_CURRENT_USER: () => `${API_URL}/api/Users`,
  AUTHENTICATE_USER: () => `${API_URL}/api/Users/authenticate`,
  LINKEDIN_USER: () => `${API_URL}/api/Users/linkedinUserFromUserCode`,
  GET_CURRENT_USER_KEYCLOAK: (keycloakId) => `${API_URL}/api/Users/${keycloakId}`,
  ADD_COMMENT: () => `${API_URL}/api/Comments`,
  GET_ALL_JOBS: () => `${API_URL}/api/Jobs`,
  GET_ALL_JOBS_FAMILIES: () => `${API_URL}/api/Jobs/families`,
  GET_ALL_CITIES: () => `${API_URL}/api/cities`,
  GET_ALL_COMPANIES: () => `${API_URL}/api/companies`,
  UPDATE_COMPANY: () => `${API_URL}/api/companies`,
  ADD_COMPANY: () => `${API_URL}/api/companies`,
  DELETE_COMPANY_BY_ID: (companyId) => `${API_URL}/api/companies/${companyId}`,
  SEARCH_CITIES: () => `${API_URL}/api/city/search`,
  GET_CITY_BY_ID: (id) => `${API_URL}/api/city/${id}`,
  GET_ALL_POSTS: () => `${API_URL}/api/Posts`,
  ADD_POST: () => `${API_URL}/api/Posts`,
};

export const UserRoles = {
  APATHEIA_ADMIN: 0,
  COMPANY_MANAGER: 'CompanyManager',
};

export const ApatheiaToken = {
  APATHEIA_TOKEN_NAME: 'Apatheia Token',
};

export const siretNumberCharactersCount = 14;

export const paid = "paid";
export const unpaid = "unpaid";

export const billStateLookup = () => ({
  [paid]: i18next.t("Paid"),
  [unpaid]: i18next.t("Unpaid"),
});

export const reactionType = {
  0: "heart",
  1: "like",
  2: "congratulation",
  3: "smile",
};

export const reactionText = {
  "like": "J'aime",
  "heart": "J'adore",
  "congratulation": "Bravo",
  "smile": "Drôle",
};

export const reactionColor = {
  "like": "blue",
  "heart": "red",
  "congratulation": "green",
  "smile": "#f9e157",
};

export const severityColor = {
  "warning": "#ffc107",
  "error": "#dc3545"
}

export const subProductType_recurring = "recurring";
export const subProductType_oneTime = "one_time";

export const subProductInterval_day = "day";
export const subProductInterval_week = "week";
export const subProductInterval_month = "month";
export const subProductInterval_year = "year";

export const subProductType = () => ({
  [subProductType_oneTime]: "One-off",
  [subProductType_recurring]: "Recurring",
})

export const subProductInterval = () => ({
  [subProductInterval_day]: "day",
  [subProductInterval_week]: "week",
  [subProductInterval_month]: "month",
  [subProductInterval_year]: "year",
})