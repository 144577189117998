import Multiselect from "multiselect-react-dropdown";
import { Label, InputGroup} from "reactstrap";
import "./search.css";
import { forwardRef, useImperativeHandle, useState } from "react";
import ButtonAddons from "./ButtonAddons";

const MultiselectAddons = forwardRef((props, ref) => {
  const {
    displayValue,
    title,
    isRequired,
    isReadOnly,
    placeholder,
    handleChange,
    handleAddon,
    addonTooltip,
    selectedValues,
    addonText,
    selectionLimit,
    options,
  } = props;

  const [values, setValues] = useState(selectedValues);

  useImperativeHandle(ref, () => ({
    onSelectChange: (newValues) => setValues(newValues)
  }));

  return (
    <>
      {title && <Label>{isRequired ? title + "*" : title}</Label>}
      <InputGroup>
        <Multiselect
          options={options}
          selectedValues={values}
          onSelect={handleChange}
          onRemove={handleChange}
          selectionLimit={selectionLimit}
          emptyRecordMsg={"Pas d'options disponible"}
          showCheckbox
          displayValue={displayValue}
          placeholder={placeholder}
          disable={isReadOnly}
        />
        <p>
          <ButtonAddons addonText={addonText} addonTooltip={addonTooltip} handleAddon={handleAddon} />
        </p>
      </InputGroup>
    </>
  );
});

export default MultiselectAddons;
