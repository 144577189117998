import React from "react";

const Cards = ({ data }) => {
  return (
    <div className="row d-flex justify-content-center">
      {data.map((element, k) => {
        return (
          <div
            key={k}
            className="card col-xs-12"
            style={{
              maxWidth: "25rem",
              boxShadow: "0 4px 18px 0 rgb(219 219 219 / 50%)",
              padding: "1rem",
              borderRadius: "14px",
              margin: "2rem 1rem",
            }}
          >
            <img
              style={{ maxWidth: "100%", height: "220px", objectFit: "cover" }}
              alt="..."
              src={element.image}
            />
            <div className="card-body">
              <h2
                className="card-title d-flex justify-content-center text-center"
                style={{ color: "rgb(50, 104, 136)" }}
              >
                {element.title}
              </h2>
              <p className="card-text">{element.text}</p>
              <p style={{ verticalAlign: "bottom" }}>{element.lien}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Cards;
