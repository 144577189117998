import Cards from "../../../Ui/Cards";
import prospection from "../../../../assets/img/principal/prospectionProperti.png";
import valorisation from "../../../../assets/img/principal/valorisationProperti.png";
import digitalisation from "../../../../assets/img/principal/digitalisationProperti.png";
import React from "react";
import "../../../Ui/fond.css"

const data = [
  {
    image: prospection,
    title: "Prospection",
    text: `Lors de votre recherche de professionnels, vous passez plus de temps à prospecter plutôt que d'agir là où vous êtes expert
    Coûteux, chronophage, énergivore.`,
  },
  {
    image: valorisation,
    title: "Valorisation",
    text: `De nombreux professionnels, plus compétents que les autres, ne brillent pas autant qu'ils le mériteraient, car ils ne disposent pas encore des outils pour valoriser autant qu'il le faudrait tous les projets fabuleux qu'ils ont menés.`,
  },
  {
    image: digitalisation,
    title: "Digitalisation",
    text: `Si vous vous êtes déjà retrouvé dans une situation où des travaux n'ont pas été menés comme prévu, vous en connaissez les conséquences.`,
  },
];

const ProblematiqueProperty = () => {
  return (
    <div className="bgDefault">
      <div className="col-md-12 pt-6 ">
        <h2
          className="display-4 pb-4 d-flex justify-content-center "
          style={{ fontWeight: "bold", gap: "1%", color: "rgb(50,104,136)" }}
        >
          Vos problématiques
        </h2>
      </div>
      <Cards data={data} />
    </div>
  );
};

export default ProblematiqueProperty;