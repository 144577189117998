import Cards from "../../../Ui/Cards";
import React from "react";
import search from "../../../../assets/img/principal/searchProperty.jpg";
import profil from "../../../../assets/img/principal/profilArtisans.png";
import tools from "../../../../assets/img/principal/toolsArtisans.jpg";
import "../../../Ui/fond.css";

const data = [
  {
    image: search,
    title: "A-search",
    text: ` BtpGO vous aide à être mis en relation avec des clients cherchant des professionnels pour leurs projets de rénovation.`,
    lien: "En savoir plus ...",
  },
  {
    image: profil,
    title: "A-profil",
    text: `BtpGO est également un réseau social qui vous permettra d'obtenir plus de visibilité ou de suivre vos artisans préférés et de voir ce qu'ils font.`,
    lien: "En savoir plus ...",
  },
  {
    image: tools,
    title: "A-tools",
    text: `Notre outil de G.M.A.O. permet à nos clients de gérer leur établissement de manière sereine et optimisée,`,
    lien: "En savoir plus ...",
  },
];

const SolutionsArtisans = () => {
  return (
    <div className="bgDefault">
      <div className="col-md-12 mt-5">
        <h2
          className="display-4 pb-4 d-flex justify-content-center"
          style={{ fontWeight: "bold", gap: "1%", color: "rgb(50,104,136)" }}
        >
          Nos solutions
        </h2>
      </div>
      <Cards data={data} />
    </div>
  );
};

export default SolutionsArtisans;
