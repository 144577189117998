import React from "react";
import PriceCard from "./PriceCard";
import i18next from "i18next";

const Pricing = (props) => {
    const { products, isLandingPage, selectedProduct, handleSelectChange } = props;


    const onChoosePlan = (item) => {
        handleSelectChange(item)
    }

    return <div className="pricing-section no-color text-center" id="pricing">
        <div>
            <div className="row">
                <div className="col-md-12 col-sm-12 ">
                    <div className="pricing-intro p-2">
                        <h2 className="wow fadeInUp" data-wow-delay="0s">
                            {i18next.t("Pricing Table")}
                        </h2>
                        <p className="wow fadeInUp" data-wow-delay="0.2s">
                            {`${i18next.t("Choose the subscription that suits you and start taking control of your business")}`}
                            {/* ${i18next.t("With Btpgo, all subscriptions give you full access to all features, allowing you to easily manage every aspect of your freelance business, all 100% online") */}
                        </p>
                    </div>
                    <div className="row p-3" style={{ background: "white" }}>
                        <PriceCard products={products} selected={selectedProduct} handleSelect={onChoosePlan} isReadonly={false} isLandingPage={isLandingPage} />
                    </div>
                </div>
            </div>
        </div>
    </div>
};

export default Pricing;
