import React, { useEffect, useState, useRef } from "react";
import { Card, CardBody, Button, CardHeader } from "reactstrap";
import Buttons from "../Ui/Button";
import { userService } from "../../utils/_services/user.service.js";
import "./photoUploader.css";
import { NotificationManager } from "react-notifications";

const PhotoUploader = ({ tabTitle }) => {
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [user, setUser] = useState([]);
  const fileInputRef = useRef(null);
  const flag = useRef(false);

  useEffect(() => {
    if (flag.current === false) {
      userService
        .getUser()
        .then((res) => {
          setUser(res.data);
          setSelectedPhotos(res.data.userWorkPhotos);
        })
        .catch((err) => console.log(err));
    }
    return () => (flag.current = true);
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();
    console.log(selectedPhotos);
    const data = [
      {
        op: "add",
        path: "/userWorkPhotos",
        value: selectedPhotos,
      },
    ];

    userService
      .updateUser(user.id, data)
      .then((res) => {
        NotificationManager.success(
          "Votre profil a été mis a jour avec succes"
        );
      })
      .catch((err) => {
        NotificationManager.error(
          "Un problème est survenu lors de la mise à jour du profil."
        );
      });
  };

  const handlePhotoUpload = (event) => {
    const files = event.target.files;
    const selectedFiles = Array.from(files);

    if (selectedFiles.length > 0) {
      const file = selectedFiles[0];

      const reader = new FileReader();
      reader.onloadend = () => {
        // Use a regex to remove data url part
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");

        setSelectedPhotos((prevPhotos) => {
          const photoInformation = {
            name: file.name,
            content: base64String,
            url: URL.createObjectURL(file),
          };
          const updatedPhotos = [...prevPhotos];
          updatedPhotos.push(photoInformation);
          return updatedPhotos;
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePhotoRemoval = (index) => {
    setSelectedPhotos((prevPhotos) => {
      const updatedPhotos = [...prevPhotos];
      updatedPhotos.splice(index, 1);
      return updatedPhotos;
    });
    console.log(selectedPhotos);
  };

  const handleAddPhotoClick = () => {
    fileInputRef.current.click();
  };

  const renderSelectedPhotos = () => {
    return selectedPhotos.map((photo, index) => (
      <div key={index} className="photo-container">
        <>
          <img
            src={photo.url}
            alt={`Mes travaux ${index + 1}`}
            className="photo"
          />
          <button
            className="remove-button"
            onClick={() => handlePhotoRemoval(index)}
          >
            <i className="fas fa-trash"></i>
          </button>
        </>
      </div>
    ));
  };
  return (
    <>
      <Card className="bg-white border shadow mb-4">
        <CardHeader>
          <h4>{tabTitle}</h4>
        </CardHeader>
        <CardBody className="pt-2">
          <label className="title form-control-label">
            Ajouter des photos de vos réalisations
          </label>
          <p className="subtitle">
            Ajoutez des photos de vos travaux pour mettre en valeur vos services. Elles seront visibles sur votre profil public et dans les résultats de recherche.
          </p>

          <div className="photo-list justify-content-between mt-4">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="d-flex flex-wrap">
                    {renderSelectedPhotos()}
                  </div>
                </div>
              </div>
            </div>
            <Buttons
              buttonText="Ajouter une photo"
              onClick={handleAddPhotoClick}
              className="btn btn-primary mt-2"
            />

            <input
              type="file"
              accept="image/*"
              multiple
              ref={fileInputRef}
              onChange={handlePhotoUpload}
              style={{ display: "none" }}
            />

            <Button
              className="my-3 btn btn-success"
              title="Sauvegarder"
              onClick={onSubmit}
            >
              Sauvegarder
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default PhotoUploader;
