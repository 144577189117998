import React, { useState } from "react";
import { Container, Navbar, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import WelcomeNavMobile from "./WelcomeNavMobile";
import logo from "../../../assets/img/welcome/logo_btpgo-btipou.png";
import "./Welcome.css";
import Rubrique from "./Rubrique";

const WelcomeNavbar = () => {
  const [burger_class, setBurgerClass] = useState("burger-bar unclicked");
  const [menu_class, setMenuClass] = useState("menu hidden");
  const [collapseOpen, setCollapseOpen] = useState(false);

  const style = {
    backgroundColor: "#FFFFFF",
    zIndex: 1030
  };

  const closeCollapse = () => {
    if (!collapseOpen) {
      setBurgerClass("burger-bar clicked");
      setMenuClass("menu visible");
    } else {
      setBurgerClass("burger-bar unclicked");
      setMenuClass("navbar-collapse hidden");
    }
    setCollapseOpen(!collapseOpen);
  };

  return (
    <div className="header" style={{ borderBottom: "2px solid #0D9DDA" }}>
      <Navbar
        className="navbar-top navbar-dark d-flex justify-content-center"
        expand="lg"
        style={style}
      >
        <Container>
          <Link to="/">
            <img
              src={logo}
              alt="..."
              style={{ width: "15rem", height: "6rem" }}
            />
          </Link>
          <Rubrique />

          <button
            className="navbar-toggler"
            id="navbar-collapse-main"
            onClick={closeCollapse}
            style={{ height: "6Orem" }}
          >
            <div className={burger_class}></div>
            <div className={burger_class}></div>
            <div className={burger_class}></div>
          </button>
          {collapseOpen ? (
            <WelcomeNavMobile
              menu_class={menu_class}
              closeCollapse={closeCollapse}
            />
          ) : null}
        </Container>
        <NavLink
          key="3"
          to={process.env.REACT_APP_WELCOME_PAGE_PATH}
          tag={Link}
          style={{
            display: "block",
            padding: "0.5rem 1rem",
            fontSize: "20px",
            borderBottom: "none",
            // marginTop: "10px"
          }}
        >
          <button type="button" className="login-mobile-btn">Connectez vous c'est gratuit</button>
        </NavLink>
      </Navbar>
    </div>
  );
};

export default WelcomeNavbar;
