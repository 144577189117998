import CardView from "../../components/Cards/CardView";

const CompanyCard = ({ company, onCompanyClick, message }) => {
    const cardClasses = onCompanyClick ? 'cursor-pointer hover-background' : '';
    const handleClick = () => {
        if (onCompanyClick) {
            onCompanyClick(company);
        }
    };

    return (
        <CardView
            children=
            {
                <div
                >
                    <div className={`d-flex justify-content-between`}>
                        <strong>{company.companyName}</strong>
                        <div>SIREN {company.siren}</div>
                    </div>
                    {(company.numeroVoieEtablissement || company.typeVoieEtablissement || company.libelleVoieEtablissement) && (
                        <div>
                            {[company.numeroVoieEtablissement, company.typeVoieEtablissement, company.libelleVoieEtablissement]
                                .filter(Boolean)
                                .join(' ')}
                        </div>
                    )}

                    {company.complementAdresseEtablissement &&
                        <div>{company.complementAdresseEtablissement}</div>
                    }

                    <div>
                        {`${company.codePostalEtablissement} ${company.libelleCommuneEtablissement}`}
                    </div>

                    {message && (
                        <div className="text-muted mt-2">{message}</div>
                    )}
                </div>
            }
            onClick={handleClick}
            cardCss={{ marginBottom: '15px' }}
            cardClasses={cardClasses}
        />
    );
}

export default CompanyCard; 