import React, { useEffect, useState, useRef } from "react";
import MaterialTable from "material-table";
import {
  GetAppOutlined,
  DeleteOutline,
  PublishOutlined,
} from "@material-ui/icons";
import { icons } from "../Icons/materialui-icons";
import { getMaterialTableParams } from "../../utils/table";
import { v4 } from "uuid";
import i18next from "i18next";
import { Dialog, dialogType } from "../Dialogs/Dialog";
import Save from "../Forms/buttons/Save";
import Cancel from "../Forms/buttons/Cancel";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  tableRoot: {
    "& .MuiTable-root": {
      width: "99% !important", // Enforce 100% width
      position: "relative",
      zIndex: 0,
    },
  },
});

const Table = (props) => {
  const {
    id,
    isLoading,
    title,
    columns,
    data,
    handleRowClick,
    handleUpload,
    handleDownload,
    handleDelete,
    handleDetailPanel,
    handleFiltering,
    rowId,
    rowsPerPage,
  } = props;

  let tableRef = useRef();
  const [items, setItems] = useState(data);
  const [query, setQuery] = useState("");
  const [columnsQueries, setColumnsQueries] = useState([]);
  const [pageSize, setPageSize] = useState(rowsPerPage ? rowsPerPage : 5);
  const defaultId = v4();

  const [deleteModal, setdeleteModal] = useState(false);
  const toggleDelete = () => setdeleteModal(!deleteModal);
  const [idToDelete, setId] = useState(-1);
  const classes = useStyles();

  const onDeleteClick = (id) => {
    setId(id);
    toggleDelete();
  };

  const onConfirmationDeleteClick = () => {
    toggleDelete();
    handleDelete(idToDelete);
  };

  useEffect(() => {
    setItems(data);
    // eslint-disable-next-line
  }, [props]);

  useEffect(() => {
    if (tableRef.current) tableRef.current.onQueryChange();
  }, [items]);

  useEffect(() => {
    if (query && tableRef.current) tableRef.current.onQueryChange();
  }, [query]);

  const findIndex = (data) =>
    items.findIndex((it) => it[rowId] === data[rowId]);

  const actions = () => {
    let allowedActions = [];
    if (handleUpload)
      allowedActions.push({
        icon: PublishOutlined,
        tooltip: i18next.t("Upload"),
        onClick: (_event, oldData) => handleUpload(findIndex(oldData)),
      });
    if (handleDownload)
      allowedActions.push({
        icon: GetAppOutlined,
        tooltip: i18next.t("Download"),
        onClick: (_event, oldData) => handleDownload(findIndex(oldData)),
      });
    if (handleDelete)
      allowedActions.push({
        icon: DeleteOutline,
        tooltip: i18next.t("Delete"),
        onClick: (_event, oldData) => onDeleteClick(findIndex(oldData)),
      });

    return allowedActions;
  };

  const getColumns = () => {
    const newColumns = [...columns];
    if (columnsQueries.length > 0) {
      newColumns.map((column) => {
        const columnQuery = columnsQueries.find(
          (c) => c.field === column.field
        );
        if (columnQuery) column.tableData = columnQuery.tableData;
        return column;
      });
    }

    return newColumns;
  };

  return (
    <>
      {deleteModal && (
        <Dialog
          type={dialogType.medium}
          handleClose={toggleDelete}
          title={i18next.t("Are you sure to delete ?")}
          handleCancel={toggleDelete}
          component={
            <React.Fragment>
              <React.Fragment>
                <Save
                  title={i18next.t("Delete")}
                  handleSave={onConfirmationDeleteClick}
                />
              </React.Fragment>
              <React.Fragment>
                <Cancel
                  title={i18next.t("Cancel")}
                  handleCancel={toggleDelete}
                />
              </React.Fragment>
            </React.Fragment>
          }
        />
      )}
      <MaterialTable
        key={id ? id : defaultId}
        className={classes.tableRoot}
        tableRef={tableRef}
        isLoading={isLoading}
        onSearchChange={setQuery}
        icons={icons}
        title={title !== undefined ? title : ""}
        columns={getColumns()}
        onChangeRowsPerPage={setPageSize}
        data={(query) =>
          new Promise((resolve) => {
            resolve();
          }).then(() => {
            setColumnsQueries(tableRef.current.props.columns);
            return getMaterialTableParams(query, items);
          })
        }
        onRowClick={(_event, selectedRow) =>
          handleRowClick
            ? handleRowClick(findIndex(selectedRow), _event, tableRef)
            : false
        }
        localization={{
          pagination: {
            labelRowsPerPage: "Lignes par page",
            labelDisplayedRows: "{from}-{to} de {count}",
          },
          toolbar: {
            searchTooltip: "Rechercher",
            searchPlaceholder: "Rechercher...",
          },
        }}
        actions={actions()}
        options={{
          pageSize: pageSize,
          // tableLayout: "fixed",
          // padding: "dense",
          // rowStyle: rowStyle,
          filtering: handleFiltering ? handleFiltering : false,
          actionsColumnIndex: -1,
          // detailPanelColumnStyle: { display: 'none' }
        }}
        detailPanel={[
          {
            icon: () => null,
            openIcon: () => null,
            disabled: false,
            render: handleDetailPanel
              ? (rowData) => handleDetailPanel(rowData)
              : false,
          },
        ]}
      />
    </>
  );
};

export default Table;
