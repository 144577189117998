export const getPatchUserObject = (user) => {
    return [
      {
        op: "add",
        path: "/firstName",
        value: user.firstName,
      },
      {
        op: "add",
        path: "/lastName",
        value: user.lastName,
      },
      {
        op: "add",
        path: "/phoneNumber",
        value: user.phoneNumber,
      },
      {
        op: "add",
        path: "/mail",
        value: user.mail,
      },
      {
        op: "add",
        path: "/hobbies",
        value: user.hobbies,
      },
      {
        op: "add",
        path: "/howIAmDescription",
        value: user.howIAmDescription,
      },
      {
        op: "add",
        path: "userType",
        value: user.userType,
      },
      {
        op: "add",
        path: "limitInterventionZone",
        value: user.limitInterventionZone,
      },
      {
        op: "add",
        path: "/skills",
        value: user.skills,
      },
      {
        op: "add",
        path: "/jobId",
        value: user.job?.id,
      },
      {
        op: "add",
        path: "/jobTitle",
        value: user.job?.title,
      },
      {
        op: "add",
        path: "/communeId",
        value: user.communeId,
      },
      {
        op: "add",
        path: "/workAvailabilities",
        value: user.workAvailabilities,
      },
      {
        op: "add",
        path: "/photo",
        value: user.photo
        // value: {
        //   name: user.photo?.name,
        //   content: user.photo?.content,
        //   url: user.photo?.url
        // },
      },
      {
        op: "add",
        path: "/personType",
        value: user.personType,
      },
    ];
  };