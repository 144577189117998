import i18next from "i18next";
import {  datesDifference, getDate } from "../../utils/date";
import { subProductInterval } from "../../utils/constants";

export const calculateUserSubscription = (user, products, subscription) => {
    if (subscription && subscription.status === "active") {
        const plan = products.find(p => p.priceId === subscription.priceId);
        const renewText = subscription.cancelAt > 0 ? `${i18next.t("Your subscription will be canceled on")}`
            : `${i18next.t("Your subscription renews on")}`
        const renewDate = subscription.cancelAt > 0 ? getDate(subscription.cancelAt) : getDate(subscription.currentPeriodEnd)
        return { active: true, amount: `${plan.amount}${plan.currency} /${subscription.intervalCount > 1 ? subscription.intervalCount + " " : ""}${i18next.t(subProductInterval()[plan.interval])}`, renewText: renewText, renewDate: renewDate, withPlan: false }
    }
    else if (user.isFree && datesDifference(user.startDate, Date.now()).days <= user.freePeriod) {
        return { active: true, amount: i18next.t("Free"), renewText: i18next.t("We recommend you to upgrade your plan"), renewDate: "Voir tableau ci-dessous", withPlan: true }
    }
    else if (subscription && subscription.status !== "active") {
        const plan = products.find(p => p.priceId === subscription.priceId);
        return { active: false, amount: `${plan.amount}${plan.currency} /${plan.intervalCount > 1 ? plan.intervalCount + " " : ""}${i18next.t(subProductInterval()[plan.interval])}`, renewText: i18next.t("Your subscription ended at"), renewDate: getDate(subscription.currentPeriodEnd), withPlan: true }
    }
    else if (!subscription && (!user.isFree || datesDifference(user.startDate, Date.now()).days > user.freePeriod)) {
        return { active: false, amount: i18next.t("Free"), renewText: i18next.t("We recommend you to upgrade your plan"), renewDate: "Voir tableau ci-dessous", withPlan: true }
    }
}