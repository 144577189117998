import React from "react";
import { useHistory } from "react-router-dom";
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import Aux from "../../hoc/_Aux";
import CustomNavLink from "../Forms/inputs/Navlink";

const CardView = (props) => {
  const { title, headerActions, children, footer, cardCss, headerCss, bodyCss, footerCss, seeMoreUrl, cardClasses, onClick } = props;
  let history = useHistory();

  return (
    <Aux>
      <Card style={cardCss} className={`shadow ${cardClasses ?? ""}`} onClick={onClick}>
        {(title) && (
          <strong className="cursor">
            <CardHeader style={{ ...{ width: "100%" }, ...headerCss }}>
              {(title && <h4>{title}</h4>)}
              {headerActions && headerActions.length > 0 &&
                <span style={{
                  float: "right"
                }}>
                  {headerActions.map(Action => Action)}
                </span>
              }
            </CardHeader>
          </strong>
        )}
        <CardBody style={bodyCss}>{children}</CardBody>
        {(footer || seeMoreUrl) && (
          <CardFooter style={footerCss}>
            {footer}
            {!footer && seeMoreUrl &&
              <div
                style={{
                  textAlign: "center",
                }}><CustomNavLink
                  title={
                    <span style={{ fontSize: "12px" }}>{"Voir plus"}</span>
                  }
                  action={() => history.push(seeMoreUrl)}
                  to={seeMoreUrl}
                /></div>}
          </CardFooter>
        )}
      </Card>
    </Aux >
  );
}

export default CardView;