import TextareaWithLimit from "../../components/Ui/TextareaWithLimit";

const DifferentiationStep = ({ onChange, websiteData }) => {
  const mainTitle = "En quoi vous démarquez-vous ?";

  return (
    <>
      <h1>{mainTitle}</h1>
      <div class="d-flex bd-highlight">
        <div class="p-2 flex-fill bd-highlight">
          <div class="form-group row">
            <label
              for="differentiation"
              class="col-sm-4 col-form-label text-right"
            >
              Vos points forts
            </label>
            <div class="col-sm-6">
              <TextareaWithLimit
                id="differentiation"
                name="differentiation"
                maxlength={300}
                value={websiteData.differentiation}
                onChange={onChange}
                placeholder="Points forts"
                rowsCount={4}
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="inputSlogan" class="col-sm-4 col-form-label text-right">
              Vos avantages
            </label>
            <div class="col-sm-6">
              <TextareaWithLimit
                id="advantages"
                name="advantages"
                maxlength={300}
                value={websiteData.advantages}
                onChange={onChange}
                placeholder="Vos avantages"
                rowsCount={3}
              />
            </div>
          </div>
          {/* <div class="form-group row">
            <label
              for="inputSpeciality"
              class="col-sm-4 col-form-label text-right"
            >
              Vos Certifications et labelles
            </label>
            <div class="col-sm-6">
              <textarea
                type="text"
                class="form-control"
                id="certificationsAndLabels"
                name="certificationsAndLabels"
                onChange={onChange}
                value={websiteData.certificationsAndLabels}
                rows="2"
                placeholder="Vos Certifications et labelles"
              />
            </div>
          </div> */}
          <div class="form-group row">
            <label
              for="inputSpeciality"
              class="col-sm-4 col-form-label text-right"
            >
              Vos engagements
            </label>
            <div class="col-sm-6">
              <TextareaWithLimit
                id="engagements"
                name="engagements"
                maxlength={300}
                value={websiteData.engagements}
                onChange={onChange}
                rowsCount={3}
                placeholder="Vos engagements"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DifferentiationStep;
