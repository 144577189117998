import React from 'react';
import './Review.css';
import UserAvatarInformation from '../User/UserAvatarInformation';
import { formatStringDate } from '../../utils/_helpers/dateUtils';
import { capitalizeFirstLowercaseRest } from '../../utils/_helpers/stringUtils';

function Review({ review }) {
    const { user, rating, title, date, comment} = review;

    return (
        <div className="review">
            <UserAvatarInformation
                firstName={user.firstName}
                lastName={user.lastName}
                jobTitle={user.job ? user.job.title : "Particulier"}
                companyName={capitalizeFirstLowercaseRest(user.company?.name)}
                photoUrl={user.photo?.url}
            />
            <div className="review-content mt-2">
                <div className="review-header">
                    <div className="review-rating">
                        {'★'.repeat(rating) + '☆'.repeat(5 - rating)}
                    </div>
                    <div className="review-title">
                        <strong>{title}</strong>
                    </div>
                </div>
                <div className="review-date">
                    Commenté le {formatStringDate(date)}
                </div>
                <div className="review-body">
                    {comment}
                </div>
                <div className="review-footer">
                    {/* <span>{helpfulCount} personnes ont trouvé cela utile</span>
                    <button className="helpful-button">Utile</button>
                    <button className="report-button">inutile</button> */}
                </div>
            </div>
        </div>
    );
}

export default Review;
