import React from "react";
import { Card, Container} from "reactstrap";
import "./search.css";
import ViewProfile from "../Profile/ViewProfile";

const UsersSearch = ({ user }) => {
  return (
    <>
      <Container className="p-3">
        <Card className="shadow border-0">
          <ViewProfile searchedUser={user} />
        </Card>
      </Container>
    </>
  );
};

export default UsersSearch;
