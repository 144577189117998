import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardText,
  CardBody,
  CardTitle,
} from "reactstrap";
import { cookiesPolicyData } from "../../components/Droits/CookiesPolicy";
import "./droits.css"

const CookiesPolicy = () => {
  return (
    <>
      <Container>
        <h1 className="d-flex justify-content-center">
          Politique relative aux cookies
        </h1>
        <Row className="justify-content-center">
          {cookiesPolicyData.map((item, index) => (
            <Col lg="12" key={index}>
              <Card style={{ border: "none" }}>
                <CardBody>
                  <CardTitle>{item.titre}</CardTitle>
                  <CardText><div dangerouslySetInnerHTML={{ __html: item.text }} /></CardText>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
        {/* <div lg="12" style={{ flexDirection: "column", padding: "1.5rem" }}>
          <p>Fait à Ajaccio, le 01/02/2023</p>
          <img className="imgCache" src={img} alt=".." />
        </div> */}
      </Container>
    </>
  );
};

export default CookiesPolicy;
