import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../features/slices/userSlice.js";
import workAvalabilityReducer from "../features/slices/workAvailabilitySlice.js";
import jobsReducer from "../features/slices/jobsSlice.js";
import customerWebsiteReducer from "../features/slices/customerWebsiteSlice.js";

export const store = configureStore({
  reducer: {
    user: userReducer,
    workAvailabilities: workAvalabilityReducer,
    jobs: jobsReducer,
    customerWebsite: customerWebsiteReducer,
  },
});
