import Axios from "./caller.service.js";

const getRolesUrl = "/api/role";
const getPricePlansRolesUrl = "/api/role/subRoles";
const updatePricePlansRolesUrl = "/api/role/subRoles";
const geRoleUrl = "/api/role/{roleId}";
const addRoleUrl = "/api/role";
const updateRoleUrl = "/api/role";

const removeRoleUrl = "/api/role/{roleId}";

export const getAll = async () => {
  return await Axios.get(getRolesUrl);
};

export const get = async (id) => {
  const response =  await Axios.get(geRoleUrl.replace("{roleId}", id));
  return response.data.result.data;
};

export const add = async (role) => {
  const response = await Axios.post(addRoleUrl, role);
  return { ...response.data.result.data, permissions: [] };
};

export const update = async (role) => {
  const response = await Axios.put(updateRoleUrl, role);
  return response;
};

export const remove = async (id) => {
  return await Axios.delete(removeRoleUrl.replace("{roleId}", id));
};

export const getPricePlansRoles = async () => {
  return await Axios.get(getPricePlansRolesUrl);
};

export const updatePricePlanRoles = async (item) => {
  const response = await Axios.put(updatePricePlansRolesUrl, item);
  return response;
};