import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import RolesTable from "../../../../components/Tables/MaterialTable";
import CustomNavLink from "../../../../components/Forms/inputs/Navlink";
import Dialog from "../../../../components/Modal/Dialog";
import { roleForm } from "../Model";
import FormGenerator from "../../../../components/Forms/FormGenerator";
import { getDateTime } from "../../../../utils/date.js";

const Roles = (props) => {
  const { roles, handleSave, handleRowClick, handleDeleteClick } = props;
  const [showDialog, setShowDialog] = useState(false);
  const toggle = () => setShowDialog(!showDialog);
  const columns = [
    {
      title: "Nom",
      field: "name",
      width: "30%",
      // render: (rowData) => (
      //   <Tooltip title={tooltipDisplay(rowData.name)} value={rowData.name} />
      // ),
    },
    {
      title: "Description",
      field: "description",
      width: "40%",
    },
    {
      title: "Date de création",
      field: "creationDate",
      width: "15%",
      render: (rowData) => (rowData.creationDate ? getDateTime(rowData.creationDate) : ""),
    },
    {
      title: "Dernière modification",
      field: "lastModificationDate",
      width: "15%",
      render: (rowData) => (rowData.lastModificationDate ? getDateTime(rowData.lastModificationDate) : ""),
    },
  ];

  const onSave = (index, item) => {
    handleSave(index, item);
    toggle();
  };

  return (
    <>
      {showDialog && (
        <Dialog
          title={"Nouveau rôle"}
          handleClose={toggle}
          component={<FormGenerator
                  form={roleForm}
                  data={{}}
                  index={-1}
                  handleSave={onSave}
                  handleCancel={toggle}
                />}
        />
      )}
      <Card>
        <CardBody>
          <RolesTable
            id={"roles"}
            title={
              // CanHandleRoles() ? (
              <CustomNavLink
                title={"+ Nouveau rôle"}
                action={toggle}
                to={"#"}
              />
              // ) : null
            }
            columns={columns}
            data={roles}
            handleRowClick={handleRowClick}
            handleDelete={handleDeleteClick}
            rowId={"name"}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default Roles;
