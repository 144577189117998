import React, { useState, useEffect } from "react";
import DragAndDrop from "../../../components/DragAndDrop/dragAndDrop";
import { permissions } from "../../../utils/authorization/constants/permissions";
import Label from "../../../components/Forms/inputs/Label";
import Choice from "../../../components/Forms/inputs/Choice";
import NavLink from "../../../components/Forms/inputs/Navlink";
import { Card, CardBody } from "reactstrap";

const RoleDetail = (props) => {
  const { index, role, handleSave, handleBackClick } = props;
  const [editedRole, setEditedRole] = useState(role);
  const [perms, setPermissions] = useState([]);
  const [projects, setProjects] = useState({});
  const [filtersCriteria, setFiltersCriteria] = useState({
    source: "",
    destination: "",
  });
  const [selectedProject, setSelectedProject] = useState(
    Object.keys(permissions)[0]
  );

  const [state, setState] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);

  const onChange = (field, key) => {
    setPermissions(
      permissions[key].filter((item) => !editedRole.permissions.includes(item))
    );
    setSelectedProject(key);
  };

  const source = {
    id: "source",
    hasFilter: true,
    title: (
      <Label
        field={"ref"}
        title={
          <h3 style={{ fontSize: "1.2em", margin: "0 auto" }}>
            {" "}
            {editedRole ? editedRole.name : ""}
          </h3>
        }
      />
    ),

    items: editedRole ? editedRole.permissions.sort() : [],
  };

  const destination = {
    id: "destination",
    hasFilter: true,
    title: (
      <Choice
        value={selectedProject}
        placeholder={"Select Menu ..."}
        lookup={projects}
        isRequired={false}
        isReadOnly={false}
        handleChange={onChange}
      />
    ),
    items: perms.sort(),
  };

  useEffect(() => {
    let lookup = {};
    Object.keys(permissions).forEach((perm) => (lookup[perm] = perm));
    setProjects(lookup);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!hasChanged && role) {
      setEditedRole(role);
      setPermissions(
        permissions[selectedProject].filter(
          (item) => !role.permissions.includes(item)
        )
      );
    }
    // eslint-disable-next-line
  }, [props, hasChanged]);

  useEffect(() => {
    if (editedRole) {
      setState([source, destination]);
    }
    // eslint-disable-next-line
  }, [editedRole, perms, filtersCriteria]);

  const manageState = (newState) => {
    const newPerms = newState[0].items;
    setEditedRole({ name: role.name, permissions: newPerms });
    setPermissions(
      permissions[selectedProject].filter((item) => !newPerms.includes(item))
    );
  };

  const onSave = () => {
    handleSave(index, state[0].items);
    setHasChanged(false);
  };

  const onCancel = () => {
    setHasChanged(false);
  };

  return (
    <div>
      <NavLink title={"< Back to roles "} action={handleBackClick} to={"#"} />
      <br />
      <br />
      <Card>
        <CardBody>
          <DragAndDrop
            state={state}
            onMove={manageState}
            hasChanged={hasChanged}
            setHasChanged={setHasChanged}
            filtersCriteria={filtersCriteria}
            setFiltersCriteria={setFiltersCriteria}
            onCancel={onCancel}
            onSave={onSave}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default RoleDetail;
