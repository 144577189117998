import React from "react";
import client from "../../assets/img/welcome/client-1.png";
import client1 from "../../assets/img/welcome/client-2.png";
import clientBni from "../../assets/img/welcome/client-bni.png";
import client3 from "../../assets/img/welcome/client-4.png";
import client4 from "../../assets/img/welcome/client-8.png";
import client5 from "../../assets/img/welcome/client-6.png";
import client6 from "../../assets/img/welcome/client-7.png";
import client7 from "../../assets/img/welcome/client-5.png";
import client8 from "../../assets/img/welcome/client-9.png";
import client9 from "../../assets/img/welcome/client-10.png";
import client10 from "../../assets/img/welcome/client-11.png";
import client11 from "../../assets/img/welcome/client-12.png";

import "../Welcome/Principal/offre/offre.css";
import { TitleWelcome } from "../Welcome/common.js";

const data = [
  {
    image: client,
    class: "h-50"
  },
  {
    image: client1,
    class: "h-50"
  },
  {
    image: clientBni,
    class: "h-50"
  },
  {
    image: client3,
    class: "h-50"
  },
  {
    image: client4,
    class: ""
  },
  {
    image: client5,
    class: ""
  },
  {
    image: client6,
    class: "h-50"
  },
  {
    image: client7,
    class: "h-50"
  },
  {
    image: client8,
    class: "h-75"
  },
  {
    image: client9,
    class: ""
  },
  {
    image: client10,
    class: ""
  },
  {
    image: client11,
    class: ""
  },
];

const Partenaires = () => {
  // const style = {
  //   backgroundColor: backgroundColor,
  // };
  return (
    <div className="container bgDefault" id="partenaire">
      <div
        style={{
          alignItems: "center",
          height: "auto",
          maxWidth: "1275px",
        }}
      >
        <div className="row ">
          <div className="col-md-12 mt-5 d-flex justify-content-center text-center">
            <TitleWelcome>
              Nos partenaires
            </TitleWelcome>
          </div>
          <div className="partenaire">
            {data.map((element, k) => {
              return (
                <div
                  key={k}
                  className="col-6 col-sm-6 col-md-3 mb-3 d-flex justify-content-center align-items-center"
                  data-aos="fade-up"
                >
                  <img style={{ maxWidth: "90%" }} className={element.class} alt="..." src={element.image} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partenaires;
