import DeleteIconButtonWithModal from "components/Buttons/DeleteIconButtonWithModal";


const DeleteAccount = ({ onClickDeleteAccount }) => {

    return (
        <>
            <h6 className="mt-3 mb-3 font-weight-bold">Supprimer mon compte</h6>

            <h6>⚠️ Attention, avant de partir !</h6>
            <p>
                En fermant votre compte, vous perdrez l'accès à toutes vos connexions, collaborations, et projets en cours sur BTPGO.
                La simplicité et l'efficacité que vous appréciez disparaîtront avec cette action définitive.
            </p>
            <p>
                <ul>
                    <li>Vos contacts et historiques de projets seront supprimés.</li>
                    <li>Vous ne pourrez plus recevoir de nouvelles opportunités ou mises en relation.</li>
                    <li>Votre réseau construit avec soin s'effacera.</li>
                </ul>
            </p>
            <p>
                Nous aimerions que vous restiez pour continuer à bénéficier de la plateforme et de ses fonctionnalités. Si quelque chose ne vous convient pas, dites-le-nous. Nous sommes là pour vous aider.
            </p>
            <div className="text-center mt-3 mb-3">
                <DeleteIconButtonWithModal
                    modalTitle={"Supprimer mon compte"}
                    modalText={"êtes-vous sûr de vouloir supprimer votre compte ?"}
                    deleteAction={onClickDeleteAccount}
                    deleteButtonText={"Continuer"}
                    showButtonForDelete={true}
                    className={"mt-3 mb-3 cursor-pointer"}
                ></DeleteIconButtonWithModal>
            </div>



        </>
    );
}

export default DeleteAccount;