import React from "react";
import particularBtpImg from "../../assets/img/welcome/particularBtp.jpg";
import professionalBtpImg from "../../assets/img/welcome/professionalBtp.jpg";

const UserType = ({ onSelectUserType }) => {
    const professional = 1;
    const particular = 2;

    return (<div className="center-content text-center">
        <h5 className="mb-3">
            Qu'est-ce qui vous décrit le mieux ?
        </h5>
        <div className="row  ">
            <div className="col-12 col-md-6">
                <div class="avatar avatar-xl rounded-circle mb-3 cursor-pointer" onClick={() => onSelectUserType(professional)}>
                    <img
                        style={{ height: "130px", width: "130px" }}
                        class="grayscale"
                        alt="Professionnel"
                        src={professionalBtpImg}
                    />
                </div>
                <div className="font-weight-bolder font-italic">
                    Professionnel
                </div>
                <p className="pt-3" >
                    Je suis un artisan ou un responsable et je cherche principalement des clients, des chantiers ou des professionels de bâtiment.
                </p>
            </div>
            <div className="col-12 col-md-6 ">
                <div class="avatar avatar-xl rounded-circle mb-3 cursor-pointer" onClick={() => onSelectUserType(particular)}>
                    <img class="grayscale" src={particularBtpImg} style={{ height: "130px", width: "130px" }} alt="Particulier" />
                </div>
                <div className="font-weight-bolder font-italic">
                    Particulier
                </div>
                <p className="p-3">
                    Je suis propriètaire et je cherche principalement des artisants pour mes chantiers de bâtiment.
                </p>
            </div>
        </div>
    </div>);
}

export default UserType; 