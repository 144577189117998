import Axios from "./caller.service.js";
import Keycloak from "keycloak-js";
import { getPatchUserObject } from "./utils/userUtils.js";

const _kc = new Keycloak("/keycloak.json");

const initKeycloak = () =>
  new Promise((resolve, reject) => {
    _kc
      .init({
        onLoad: "check-sso",
      })
      .then(() => resolve(_kc))
      .catch((error) => reject(error));
  });

const doLogin = _kc.login;

const doLogout = () => _kc.logout({ redirectUri: window.location.origin });

const getToken = () => _kc.token;

const getTokenParsed = () => _kc.tokenParsed;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

let getUser = () => {
  return Axios.get("/api/Users");
};

let getUserById = (id) => {
  return Axios.get(`/api/Users/${id}`);
};

let getUserAsync = () => {
  return Axios.get("/api/Users");
};

let getAllUsersAsync = () => {
  return Axios.get("/api/Users/all");
};

let getAllUsersWithRoles = () => {
  return Axios.get("/api/Users/allWithRoles");
};

let updateUser = (id, user) => {
  const data = getPatchUserObject(user);
  return Axios.patch("/api/Users/" + id, data);
};

let searchUser = (searchRequest) => {
  return Axios.post("/api/Users/search", searchRequest);
};

let getUserPermissions = () => {
  return Axios.get("/api/Users/permissions");
};

const getUserRoles = async (userId) => {
  return await Axios.get("/api/Users/userRole/" + userId);
};

const updateUserRoles = async (userRoles) => {
  return await Axios.put("/api/Users/userRole/", userRoles);
};

const updateProfileStatus = async (userId, value) => {
  return await Axios.put(`/api/Users/${userId}/validate/${value}`);
}

const updateShowInSearch = async (userId, value) => {
  return await Axios.put(`/api/Users/${userId}/showInSearch/${value}`);
}

const updateIsFirstConnection = async (userId, value) => {
  return await Axios.put(`/api/Users/${userId}/firstConnection/${value}`);
}

const confirmFirstConnection = async (data) => {
  return await Axios.post(`/api/Users/confirmFirstConnection`, data);
}

const deleteUser = async (userId) => {
  return await Axios.delete(`/api/Users/${userId}`);
}

export const userService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getTokenParsed,
  updateToken,
  getUsername,
  hasRole,
  getUser,
  getUserAsync,
  getAllUsersAsync,
  updateUser,
  searchUser,
  getUserById,
  getUserRoles,
  updateUserRoles,
  getUserPermissions,
  updateProfileStatus,
  updateShowInSearch,
  getAllUsersWithRoles,
  updateIsFirstConnection,
  confirmFirstConnection,
  deleteUser
};
