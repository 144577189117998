import {
  RiBuilding4Fill,
  RiCalendar2Fill,
  RiFacebookCircleFill,
  RiGoogleFill,
  RiGroupFill,
  RiInstagramFill,
  RiLinkedinBoxFill,
  RiLinksFill,
  RiMoneyEuroCircleLine,
  RiPinterestFill,
  RiTwitterFill,
  RiYoutubeFill,
} from "react-icons/ri";

export const inputConfigs = [
  {
    forInput: "input-siret",
    label: "Numéro de Siret",
    value: "siretNumber",
    placeholder: "siret",
    type: "text",
    required: true,
    icon: (
      <RiBuilding4Fill
        className="mb-2"
        fontSize="1rem"
        color="rgb(111,174,222)"
      />
    ),
  },
  {
    forInput: "input-city",
    label: "Nom de l'Entreprise",
    value: "name",
    placeholder: "Entreprise",
    type: "text",
    icon: (
      <RiBuilding4Fill
        className="mb-2"
        fontSize="1rem"
        color="rgb(111,174,222)"
      />
    ),
  },
  {
    forInput: "input-email",
    label: "Nombre d'employé(s)",
    value: "employeesNumber",
    type: "number",
    icon: (
      <RiGroupFill className="mb-2" fontSize="1rem" color="rgb(111,174,222)" />
    ),
  },
  {
    forInput: "input-email",
    label: "Chiffre d'affaire",
    value: "turnover",
    type: "number",
    icon: (
      <RiMoneyEuroCircleLine
        className="mb-2"
        fontSize="1rem"
        color="rgb(111,174,222)"
      />
    ),
  },
];

const compnayCreationDate = {
  forInput: "input-creationDate",
  label: "Date de création",
  value: "creationDate",
  placeholder: "Date de création",
  type: "date",
  required: false,
  icon: (
    <RiCalendar2Fill
      className="mb-2"
      fontSize="1rem"
      color="rgb(111,174,222)"
    />
  ),
};

const legalUnit = {
  forInput: "input-legalUnit",
  label: "Forme Juridique",
  type: "select",
  field: "legalUnitCode",
  value: "legalUnitCode",
  placeholder: "Choisir la forme juridique...",
  lookup: {
    "1000": "Entrepreneur individuel (EI)",
    "5300": "Société en nom collectif (SNC)",
    "5320": "Société en commandite simple (SCS)",
    "5330": "Société en commandite par actions (SCA)",
    "5480": "Entreprise unipersonnelle à responsabilité limitée (EURL)",
    "5498": "Société à responsabilité limitée (SARL)",
    "5499": "Société à responsabilité limitée (sans autre indication)",
    "5598": "Société anonyme (SA)",
    "5710": "Société par actions simplifiée (SAS)",
    "5720": "Société par actions simplifiée unipersonnelle (SASU)"
  },
  isRequired: false,
  isReadOnly: false
}

const inseeStatus = {
  forInput: "input-InseeStatus",
  label: "Statut Insee",
  type: "select",
  value: "inseeStatusCode",
  field: "inseeStatusCode",
  placeholder: "Votre Statut Insee ...",
  lookup: {
    "A": "Active",
    "C": "Cessé",
  },
  isRequired: false,
  isReadOnly: false
};

const addressLines = {
  forInput: "input-adress",
  label: "Adresse",
  value: "addressLines",
  placeholder: "Adresse de l'entreprise",
  type: "text",
  required: false,
  icon: (
    <RiCalendar2Fill
      className="mb-2"
      fontSize="1rem"
      color="rgb(111,174,222)"
    />
  ),
};

const zipCode = {
  forInput: "input-zipCode",
  label: "Code Postal",
  value: "zipCode",
  placeholder: "Code Postal",
  type: "text",
  required: false,
  icon: (
    <RiCalendar2Fill
      className="mb-2"
      fontSize="1rem"
      color="rgb(111,174,222)"
    />
  ),
};

const city = {
  forInput: "input-city",
  label: "Ville",
  value: "cityName",
  placeholder: "Viile",
  type: "text",
  required: false,
  icon: (
    <RiCalendar2Fill
      className="mb-2"
      fontSize="1rem"
      color="rgb(111,174,222)"
    />
  ),
};

export const companyAdressConfigs = {
  rows: [
    {
      columns: [
        { size: 6, render: { ...legalUnit } },
        { size: 3, render: { ...compnayCreationDate } },
        { size: 3, render: { ...inseeStatus } },
      ],
    },
    {
      columns: [
        { size: 6, render: { ...addressLines } },
        { size: 3, render: { ...zipCode } },
        { size: 3, render: { ...city } },
      ],
    },
  ],
};

const websiteLink = {
  forInput: "input-websiteLink",
  label: "Lien site web",
  value: "websiteLink",
  placeholder: "...",
  type: "text",
  required: false,
  icon: (
    <RiLinksFill
      className="mb-2"
      fontSize="1rem"
      color="rgb(111,174,222)"
    />
  ),
};

const facebookLink = {
  forInput: "input-facebookLink",
  label: "Lien Facebook",
  value: "facebookLink",
  placeholder: "...",
  type: "text",
  required: false,
  icon: (
    <RiFacebookCircleFill
      className="mb-2"
      fontSize="1rem"
      color="rgb(111,174,222)"
    />
  ),
};

const linkedinLink = {
  forInput: "input-linkedinLink",
  label: "Lien Linkedin",
  value: "linkedinLink",
  placeholder: "...",
  type: "text",
  required: false,
  icon: (
    <RiLinkedinBoxFill
      className="mb-2"
      fontSize="1rem"
      color="rgb(111,174,222)"
    />
  ),
};

const twitterLink = {
  forInput: "input-twitterLink",
  label: "Lien Twitter",
  value: "twitterLink",
  placeholder: "...",
  type: "text",
  required: false,
  icon: (
    <RiTwitterFill
      className="mb-2"
      fontSize="1rem"
      color="rgb(111,174,222)"
    />
  ),
};

const youtubeLink = {
  forInput: "input-youtubeLink",
  label: "Lien Youtube",
  value: "youtubeLink",
  placeholder: "...",
  type: "text",
  required: false,
  icon: (
    <RiYoutubeFill
      className="mb-2"
      fontSize="1rem"
      color="rgb(111,174,222)"
    />
  ),
};

const googleLink = {
  forInput: "input-googleLink",
  label: "Lien Google",
  value: "googleLink",
  placeholder: "...",
  type: "text",
  required: false,
  icon: (
    <RiGoogleFill
      className="mb-2"
      fontSize="1rem"
      color="rgb(111,174,222)"
    />
  ),
};

const tiktokLink = {
  forInput: "input-tiktokLink",
  label: "Lien Tiktok",
  value: "tiktokLink",
  placeholder: "...",
  type: "text",
  required: false,
  icon: (
    <RiLinksFill
      className="mb-2"
      fontSize="1rem"
      color="rgb(111,174,222)"
    />
  ),
};

const trustpilotLink = {
  forInput: "input-trustpilotLink",
  label: "Lien Trustpilot",
  value: "trustpilotLink",
  placeholder: "...",
  type: "text",
  required: false,
  icon: (
    <RiLinksFill
      className="mb-2"
      fontSize="1rem"
      color="rgb(111,174,222)"
    />
  ),
};

const pinterestLink = {
  forInput: "input-pinterestLink",
  label: "Lien Pinterest",
  value: "pinterestLink",
  placeholder: "...",
  type: "text",
  required: false,
  icon: (
    <RiPinterestFill
      className="mb-2"
      fontSize="1rem"
      color="rgb(111,174,222)"
    />
  ),
};

const houzzLink = { 
  forInput: "input-houzzLink",
  label: "Lien Houzz",
  value: "houzzLink", 
  placeholder: "...",
  type: "text",
  required: false,
  icon: (
    <RiLinksFill
      className="mb-2"
      fontSize="1rem"
      color="rgb(111,174,222)"
    />
  ),
};

const pageJauneLink = {
  forInput: "input-pageJauneLink",
  label: "Lien Page Jaune",
  value: "pageJauneLink",
  placeholder: "...",
  type: "text",
  required: false,
  icon: (
    <RiLinksFill
      className="mb-2"
      fontSize="1rem"
      color="rgb(111,174,222)"
    />
  ),
};

const instagramLink = {
  forInput: "input-instagramLink",
  label: "Lien Instagram",
  value: "instagramLink",
  placeholder: "...",
  type: "text",
  required: false,
  icon: (
    <RiInstagramFill
      className="mb-2"
      fontSize="1rem"
      color="rgb(111,174,222)"
    />
  ),
};

export const companyCommunicationLinks = {
  rows: [
    {
      columns: [
        { size: 6, render: { ...websiteLink } },
        { size: 6, render: { ...googleLink } },        
      ],
    },
    {
      columns: [
        { size: 6, render: { ...facebookLink } },
        { size: 6, render: { ...instagramLink } },        
      ],
    },
    {
      columns: [
        { size: 6, render: { ...youtubeLink } },
        { size: 6, render: { ...linkedinLink } },        
      ],
    },
    {
      columns: [
        { size: 6, render: { ...tiktokLink } },
        { size: 6, render: { ...twitterLink } },        
      ],
    },
    {
      columns: [
        { size: 6, render: { ...pageJauneLink } },
        { size: 6, render: { ...trustpilotLink } },        
      ],
    },
    {
      columns: [
        { size: 6, render: { ...houzzLink } },
        { size: 6, render: { ...pinterestLink } },        
      ],
    }    
  ],
};