import React from "react";

const PhotoCard = ({ photoData }) => {
  return (
    <>
      {photoData.map((element, k) => (
        <div className="d-flex row mb-2" style={{ marginRight: "0" }}>
          <div className="col-md-6 d-flex justify-content-end ">
            <img
              style={{ width: "35vh", height: "31vh", objectFit: "cover" }}
              alt="..."
              src={element.image}
            />
          </div>
          <div className="col-md-6 ">
            <div className="contact-title" style={{ color: "rgb(50,104,136)" }}>
              {element.title}
            </div>
            <p className="contact-text">{element.text}</p>

            <p style={{ verticalAlign: "bottom" }}>{element.lien}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default PhotoCard;
