import React, { useEffect, useState } from 'react';
import { Row, Button, Card, CardBody, InputGroup, Input, InputGroupAddon, InputGroupText } from 'reactstrap';
import { htToTvAmount } from '../../utils/maths';
import { deepEqual } from '../../utils/objects';

const QuoteLines = ({ items, onItemsChange }) => {
    const [lines, setLines] = useState([{ serviceName: '', quantity: 1, priceHt: 0, totalPriceHt: 0, tvaPercentage: 0, tvaAmount: 0 }]);

    // Sync lines state with parent 'items' prop if changed
    useEffect(() => {
        if (!deepEqual(items, lines)) {
            setLines(items);
        }
        // eslint-disable-next-line
    }, [items]);

    // Function to update a line
    const handleLineChange = (index, key, value) => {
        const newLines = [...lines];
        newLines[index][key] = value;

        // Update related fields if necessary (e.g., total price and tax amount)
        if (key === 'quantity' || key === 'priceHt' || key === 'tvaPercentage') {
            const quantity = newLines[index].quantity || 0;
            const priceHt = newLines[index].priceHt || 0;
            const tvaPercentage = newLines[index].tvaPercentage || 0;

            newLines[index].totalPriceHt = quantity * priceHt;
            newLines[index].tvaAmount = htToTvAmount(quantity * priceHt, tvaPercentage);
        }

        setLines(newLines);
        onItemsChange(newLines);
    };

    // Function to add a new line
    const addLine = () => {
        const newLines = [...lines, { serviceName: '', quantity: 1, priceHt: 0, totalPriceHt: 0, tvaPercentage: 0, tvaAmount: 0 }];
        setLines(newLines);
        onItemsChange(newLines);
    };

    // Function to remove a line
    const removeLine = (index) => {
        const newLines = lines.filter((_, i) => i !== index);
        setLines(newLines);
        onItemsChange(newLines);
    };

    return (
        <div>
            {lines && lines.length > 0 &&
                lines.map((item, index) => (
                    <Card className="mb-2 shadow-sm border rounded" key={index}>
                        <CardBody>
                            <h5 className="card-title">Ligne {index + 1}</h5>
                            <Row>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label className="form-control-label">Description</label>
                                        <textarea
                                            placeholder="Description du service"
                                            className="form-control"
                                            rows="3"
                                            value={item.serviceName}
                                            onChange={(e) => handleLineChange(index, 'serviceName', e.target.value)}
                                        />
                                    </div>
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-3 col-sm-6">
                                    <div className="form-group">
                                        <label className="form-control-label">Quantité</label>
                                        <input
                                            type="number"
                                            placeholder="Quantité"
                                            className="form-control"
                                            value={item.quantity}
                                            onChange={(e) => handleLineChange(index, 'quantity', Number(e.target.value))}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6">
                                    <div className="form-group">
                                        <label className="form-control-label">TVA</label>
                                        <InputGroup>
                                            <Input
                                                type="number"
                                                placeholder="TVA"
                                                className="form-control"
                                                value={item.tvaPercentage}
                                                onChange={(e) => handleLineChange(index, 'tvaPercentage', Number(e.target.value))}
                                                style={{ maxWidth: "70px" }}
                                            />
                                            <InputGroupAddon addonType="append">
                                                <InputGroupText>%</InputGroupText>
                                            </InputGroupAddon>
                                        </InputGroup>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6">
                                    <div className="form-group">
                                        <label className="form-control-label">Prix HT</label>
                                        <input
                                            type="number"
                                            placeholder="Prix HT"
                                            className="form-control"
                                            value={item.priceHt}
                                            onChange={(e) => handleLineChange(index, 'priceHt', Number(e.target.value))}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6">
                                    <div className="form-group">
                                        <label className="form-control-label">Total HT</label>
                                        <input
                                            type="number"
                                            placeholder="Total Prix HT"
                                            className="form-control"
                                            value={item.totalPriceHt}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </Row>
                            {(index > 0 || (index === 0 && lines.length > 1)) && (
                                <div className="d-flex justify-content-end">
                                    <Button
                                        color="danger"
                                        type="button"
                                        onClick={() => removeLine(index)}
                                        className="mt-2 cursor-pointer"
                                        style={{ backgroundColor: '#ff4d4f', borderColor: '#ff4d4f' }}
                                    >
                                        <i className="fas fa-trash-alt"></i> Supprimer
                                    </Button>
                                </div>
                            )}
                        </CardBody>
                    </Card>
                ))}
            <Button
                color="primary"
                type="button"
                onClick={addLine}
                className="my-2 cursor-pointer"
            >
                Ajouter une ligne
            </Button>
        </div>
    );
};

export default QuoteLines;
