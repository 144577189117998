import React from "react";
import { FormFeedback, FormGroup } from "reactstrap";
import TextareaWithLimit from "./TextareaWithLimit";
import "./fond.css";

const GenerateFormInputWithLimit = ({
  forInput,
  label,
  value,
  onChange,
  maxlength,
  name,
  placeholder,
  type,
  rowsCount,
  disabled,
  className,
  style,
  icon,
  feedbackValue,
  displayFeedback
}) => {
  return (
    <>
      <FormGroup className={className} style={style}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <label className="form-control-label" htmlFor={forInput}>
            {label}
          </label>
          {icon && (
            <div style={{ color: "rgb(111,174,222)", marginLeft: "7px" }}>
              {icon}
            </div>
          )}
        </div>
        <TextareaWithLimit
          name={name}
          id={name}
          maxlength={maxlength}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          rowsCount={rowsCount}
          type={type}
          disabled={disabled}
        />
        {displayFeedback && <FormFeedback className="d-block">{feedbackValue}</FormFeedback>}
      </FormGroup>
    </>
  );
};

export default GenerateFormInputWithLimit;
