export const deleteAccount = "DeleteAccount";
export const closeTemporaryAccount = "CloseTemporaryAccount";
export const manageAccountIndex = "ManageAccountIndex";
export const mainView = "MainView";

export const menus = [
    {
        id: "ManageAccountIndex",
        title: "Gestion de mon compte",
        components: [
            {
                id: deleteAccount,
                title: "Supprimer mon compte",
            },
            // {
            //     id: closeTemporaryAccount,
            //     title: "Fermer temporairement mon compte",
            // }
        ],
    },
];