import Axios from "./caller.service.js";

let login = (login) => {
  return Axios.post("/api/Users/authenticate", login);
};

let register = (register) => {
  return Axios.post("/api/Users/register", register);
};

let saveToken = (token) => {
  localStorage.setItem("token", token);
};

let logout = () => {
  localStorage.removeItem("token");
};

let isLogged = () => {
  let token = localStorage.getItem("token");

  return !!token;
};

let getToken = () => {
  return localStorage.getItem('token')
}

export const accountService = {
  login,
  register,
  saveToken,
  logout,
  isLogged,
  getToken
};
