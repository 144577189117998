import React, { useEffect, useState } from 'react';
import GenericHeader from "../../components/Headers/GenericHeader";
import Card from "../../components/Cards/CardView";
import { Badge, Button, Col, Container, Row } from "reactstrap";
import Pricing from "../../components/Pricing";
import { loadStripe } from "@stripe/stripe-js";
import { calculateUserSubscription } from './helper';
import { useTranslation } from 'react-i18next';
import useFetchUser from '../../hooks/useFetchUser';
import { paymentService } from '../../utils/_services/payment.service';

const Subscription = () => {
    const [stripePromise, setStripePromise] = useState(null);
    const { t } = useTranslation();
    const user = useFetchUser();
    const [userSubscription, setUserSubscription] = useState({ isFree: false, amount: "", currency: "", interval: "", renewDate: "", withPlan: false });
    const [subscription, setSubscription] = useState(null);
    const [showPlans, setShowPlans] = useState(false);
    const [products, setProducts] = useState([]);

    useEffect(() => {
        paymentService.getActivesPrices().then((response) => {
            response.push({ title: "Free", amount: 0, currency: "€", interval: "month", intervalCount: 1, priceId: "free" });
            setProducts(response);
        });

        paymentService.paymentConfig().then((response) => {
            setStripePromise(loadStripe(response.data.publishableKey));
        });

        paymentService.getUserSubscription().then((response) => {
            setSubscription(response.data);
        });
    }, []);

    useEffect(() => {
        if (user && products.length > 0) {
            const userSub = calculateUserSubscription(user, products, subscription);
            setUserSubscription(userSub);
            setShowPlans(true)
        }
    }, [user, products, subscription, t]);

    const onSelectProduct = (item) => {
        paySubscriptionClick(item.priceId)
    }

    const paySubscriptionClick = (priceId) => {
        debugger;
        paymentService
            .createCheckoutSession(priceId, user.id, user.mail)
            .then((response) => {
                var sessionId = response.data.sessionId;
                stripePromise.then(async (stripe) => {
                    stripe?.redirectToCheckout({ sessionId });
                });
            });
    };

    const goToBillingPortal = () => {
        paymentService.getUserPortalLink().then((response) => {
            var url = response.data;
            window.location.replace(url);
        });
    };

    return (
        <>
            <GenericHeader />
            <Container style={{ maxWidth: "2000px"}} className='p-3'>
                {stripePromise && (
                    <Card cardClasses="p-3 mb-3">
                        <Row>
                            <Col md="6">
                                <span style={{ fontSize: "18px", fontWeight: "bold", color: "black" }}>{t("Plan & Billing")}</span>
                                <p style={{ marginTop: "5px" }}>{t("Manage your plan and payments")}.</p>
                            </Col>
                            {subscription && <Col md="6">
                                <span style={{
                                    float: "right",
                                    marginTop: "5px"
                                }}>
                                    <Button variant="info" size="sm" style={{ fontWeight: "bold" }} onClick={goToBillingPortal}> {t("Manage subscription")} </Button>
                                </span>
                            </Col>}
                        </Row>
                        <hr />
                        <Row>
                            <Col md="6" >
                                <div className="plan-box">
                                    <div className="row align-items-center ">
                                        <div className="col">
                                            <p className="plan-title">{t("Current Plan")}</p>
                                        </div>
                                        <div className="col-auto">
                                            <label className=" float-right">
                                                <div className="plan-type">
                                                    {userSubscription.active &&  <Badge color="success" className='px-2 py-2'>{t("Active")}</Badge>}
                                                    {!userSubscription.active && <Badge color="danger" className='px-2 py-2'>{t("Inactive")}</Badge>}
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <h2 className="plan-price">{userSubscription.amount}</h2>
                                </div>
                            </Col>
                            <Col md="6" >
                                <div className="plan-box">
                                    <p className="plan-title">{userSubscription.renewText}</p>

                                    <h2 className="plan-price">{userSubscription.renewDate}</h2>
                                </div>
                            </Col>
                        </Row>
                        <hr />
                        {showPlans && <Row>
                            <Col md="12">
                                <div className="pricing-section no-color text-center">
                                    <Pricing products={products} selectedProduct={subscription} handleSelectChange={(onSelectProduct)} isLandingPage={false} /></div>
                            </Col>
                        </Row>}
                    </Card>
                )}
            </Container>
        </>);
};

export default Subscription;


