import { formType } from "../../../components/Forms/forms";

export const name = {
  field: "name",
  title: "Nom",
  formType: formType.text,
  isRequired: true,
  isReadOnly: false,
};

export let description = {
  field: "description",
  title: "Description",
  formType: formType.text,
  isRequired: false,
  isReadOnly: false,
};

export const submit = {
  title: "Sauvegarder",
  formType: formType.submit,
};

export const cancel = {
  title: "Annuler",
  formType: formType.cancel,
};

export const roleForm = () => {
  const form = {
    rows: [
      {
        columns: [{ size: 12, render: { ...name },  }],
      },
      {
        columns: [{ size: 12, render: { ...description } }],
      },
    ],
    footer: [{ render: { ...submit } }, { render: { ...cancel } }],
  };

  return form;
};
