import React, { useEffect, useState } from "react";
import CustomCarousel from "../../../components/Carousel/Carousel";
import UsersTable from "./Tables/Users";
import UserDetail from "./UserDetail";
import {
  userService
} from "../../../utils/_services/user.service.js";
import { CanHandleUsers } from "../../../utils/authorization/features/accessAuth";
import { getAll as getAllRoles } from "../../../utils/_services/role.service.js";
import { Container } from "reactstrap";
import GenericHeader from "../../../components/Headers/GenericHeader";
import { useDispatch } from "react-redux";
import { resetUserStatus } from "../../../features/slices/userSlice.js";

const Users = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedUserIndex, setSelectedUserIndex] = useState(-1);
  const [activeSlide, setActiveSlide] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const usersSlideIndex = 0;
  const userSlideIndex = 1;
  const nextSlide = () => setActiveSlide(activeSlide < 1 ? activeSlide + 1 : activeSlide);
  const previousSlide = () => setActiveSlide(activeSlide > 0 ? activeSlide - 1 : activeSlide);

  useEffect(() => {
    setIsLoading(true);
    loadUsers();
    loadRoles();
  }, []);

  const loadUsers = async () => {
    const result = await userService.getAllUsersWithRoles();
    setUsers(result.data);
    setIsLoading(false);
  };

  const loadRoles = async () => {
    const result = await getAllRoles();
    setRoles(result.data.result.data);
  };

  const onRowClick = async (index) => {
    const result = await userService.getUserRoles(users[index].id);
    const editedUsers = [...users];
    const rolesName = roles.reduce((a, v) => ({ ...a, [v.id]: v.name }), {});
    // roles.map((r) => {
    //   return { [r.id]: r.name };
    // });
    editedUsers[index].roles =
      result.data.result.data.length > 0 ? result.data.result.data.map((r) => rolesName[r]) : [];
    setUsers(editedUsers);
    setSelectedUserIndex(index);
    nextSlide();
  };

  const onSave = async (index, data) => {
    const editedUsers = [...users];
    const rolesIds = roles.reduce((a, v) => ({ ...a, [v.name]: v.id }), {});
    const result = await userService.updateUserRoles({
      userId: editedUsers[index].id,
      roleIds: data.map((d) => rolesIds[d]),
    });
    if (result) {
      editedUsers[index].roles = data;
      setUsers(editedUsers);
    }
  };


  const onChangeClick = async (index, field, value) => {
    const editedUsers = [...users];
    let result;
    switch (field) {
      case "status":
        let changedValue = value === true ? "Validated" : "PendingValidation";
        editedUsers[index][field] = changedValue;
        result = await userService.updateProfileStatus(editedUsers[index].id, changedValue);
        break;
      case "showInSearch":
        editedUsers[index][field] = value;
        result = await userService.updateShowInSearch(editedUsers[index].id, value);
        break;     
      default:
        break;
    }

    if (result) {
      dispatch(resetUserStatus());
      setUsers(editedUsers);
    }
  };

  const onUserSave = async (index, item) => {
    const editedUsers = [...users];
    const result = "test"; //await updateFreeAccess(item);

    if (result) {
      editedUsers[index] = {
        ...editedUsers[index], ...result
      };
      setUsers(editedUsers);
    }
  };

  const onDeleteClick = async (index) => {
    // await remove(users[index].id);
    const editedUsers = [...users];
    editedUsers.splice(index, 1);
    setUsers(editedUsers);
  };

  const items = [
    {
      id: usersSlideIndex,
      component: (
        <UsersTable
          isLoading={isLoading}
          users={users}
          handleRowClick={onRowClick}
          handleDeleteClick={CanHandleUsers() ? onDeleteClick : false}
          handleChangeClick={onChangeClick}
        />
      ),
    },
    {
      id: userSlideIndex,
      component: (
        <UserDetail
          index={selectedUserIndex}
          allroles={roles.map((r) => r.name)}
          user={users[selectedUserIndex]}
          handleSave={onSave}
          handleUserSave={onUserSave}
          handleBackClick={previousSlide}
        />
      ),
    },
  ];

  return <>
    <GenericHeader />
    <Container style={{ maxWidth: "2000px", marginTop: "25px" }}>
      <CustomCarousel items={items} activeIndex={activeSlide} />
    </Container>
  </>
};

export default Users;
