import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Buttons from "../../../Ui/Button";
import "../../../Ui/fond.css";
import { TitleWelcome } from "../../common.js";

const About = () => {
  useEffect(() => {
    Aos.init({
      duration: 3000,
    });
  }, []);

  return (
    <div className="container-fluid bgDefault" id="about">
      <div
        className="row d-flex justify-content-center "
        style={{
          alignItems: "center",
          height: "auto",
        }}
      >
        <div
          className="row mt-9 "
          data-aos="fade-right"
          style={{ textAlign: "justify", marginRight: "0" }}
        >
          <div className="col-md-6 ">
            <img
              style={{ width: "100%" }}
              alt="..."
              src={require("../../../../assets/img/welcome/inovation.png")}
            />
          </div>

          <div className="col-md-6" style={{ padding: "66px 60px" }}>
            <TitleWelcome>
              A propos de BtpGo
            </TitleWelcome>
            <p style={{ color: "black" }}>
              BtpGO est la plateforme du secteur BTP qui peut aider jusqu'à 2
              millions de professionnels français à faire de chaque prestation
              la meilleure expérience possible. Les acteurs du monde entier
              pourront utiliser BtpGO pour consulter les avis et opinions de
              professionnels du BTP.
            </p>
            <p style={{ color: "black" }}>
              Qu'il s'agisse de l'organisation du chantier ou pendant les
              différentes phase de travaux. les professionnels consultent sur
              BtpGO pour comparer les tarifs avantageux des prestataires,
              valeurs ajoutées, certifications, réserver un échange en
              visioconférence pour convenir des modalités d'opérations dans les
              meilleures conditions le plus rapidement possible.
            </p>
            <p style={{ color: "black" }}>
              BtpGO, le meilleur compagnon de gestion de chantier est disponible
              aujourd'hui partout en France.
            </p>
            <Buttons linkTo={process.env.REACT_APP_WELCOME_PAGE_PATH} buttonText="Inscrivez-vous c'est gratuit" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
