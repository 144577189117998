import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { PropTypes } from "prop-types";
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import "react-circular-progressbar/dist/styles.css";
import DropDownNavbarMenu from "../Navbars/DropDownNavbarMenu.js";
import { getAuthorizedMenu } from "../../utils/authorization";
import useUserPermissions from "../../hooks/useUserPermissions";
import * as subjects from "../../utils/constants/subjects";

import { ChevronDown, ChevronRight } from "../Icons/icons.jsx";

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState(true);
  const { routes, logo, currentUser } = props;
  const permissions = useUserPermissions();

  const [openMenus, setOpenMenus] = useState([subjects.ADMINISTRATION]);

  const toggleCollapseSubLevel = (id) => {
    setOpenMenus((prevState) =>
      prevState.includes(id)
        ? prevState.filter((menuId) => menuId !== id)
        : [...prevState, id]
    );
  };

  // verifies if routeName is the one active (in browser input)
  // const activeRoute = (routeName) => {
  //   return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  // };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  // const createLinks = (routes) => {
  //   const authorizedMenus = getAuthorizedMenu(permissions.data.permissions);
  //   const filtredRoutes = routes
  //     .filter(
  //       (prop) =>
  //         prop.icon );        

  //   return filtredRoutes
  //     .filter(
  //       (prop) => authorizedMenus.find((r) => r === "All" || authorizedMenus.find((r) => r === prop.id))
  //     ) // Filtrer les éléments qui ont une icône
  //     .map((prop, key) => {
  //       const pathValue =
  //         prop.path === "/:userData" ? "/user-profile" : prop.path;
  //       return (
  //         <NavItem key={key}>
  //           <NavLink
  //             to={prop.layout + pathValue}
  //             tag={NavLinkRRD}
  //             onClick={closeCollapse}
  //             activeClassName="active"
  //           >
  //             <i className={prop.icon} />
  //             {prop.name}
  //             <i className={prop.icon} />
  //           </NavLink>
  //         </NavItem>
  //       );
  //     });
  // };

  const createLinks2 = (routes) => {
    const authorizedMenus = getAuthorizedMenu(permissions.data.permissions);
    const authorizedMenusIds = getAllIds(authorizedMenus);
    const filtredRoutes = routes.filter((prop) => prop.icon);
    
    const renderMenu = (route) => {
      const pathValue = route.path === "/:userData" ? "/user-profile" : route.path;

      if (route.childrens) {
        return (
          <NavItem key={route.id}>
            <NavLink
              onClick={() => toggleCollapseSubLevel(route.id)}
              className="menu-item d-flex"
            >
              <i className={route.icon} />
              <span className="w-100">{route.name} </span>
              {openMenus.includes(route.id) ? <ChevronDown style={{
                height: '30px',
                fill: 'rgb(4, 169, 245)',
                color: '#ffffff',
                width: '30px'
              }} /> : <ChevronRight style={{
                height: '30px',
                fill: 'rgb(4, 169, 245)',
                color: '#ffffff',
                width: '30px'
              }} />}

            </NavLink>
            <Collapse isOpen={openMenus.includes(route.id)}>
              <Nav>
                {route.childrens.filter(
                  (prop) =>
                    authorizedMenusIds.find(
                      (r) => r === "All" || authorizedMenusIds.find((a) => a === prop.id)
                    )
                ).map((child) => renderMenu(child))}
              </Nav>
            </Collapse>
          </NavItem>
        );
      }

      return (
        <NavItem key={route.id}>
          <NavLink
            to={route.layout + pathValue}
            tag={NavLinkRRD}
            onClick={closeCollapse}
            activeClassName="active"
          >
            <i className={route.icon} />
            {route.name}
          </NavLink>
        </NavItem>
      );
    };

    return filtredRoutes
      .filter(
        (prop) =>
          authorizedMenusIds.find(
            (r) => r === "All" || authorizedMenusIds.find((a) => a === prop.id)
          )
      )
      .map(renderMenu);
  };

  const getAllIds = (list) => {
    const ids = [];
    if (list.length === 1 && list[0] === "All") {
      return ["All"];
    }

    list.forEach((item) => {
      ids.push(item.id); // Add parent ID
      if (item.childrens) {
        ids.push(...item.childrens.map((child) => child.id)); // Add children IDs
      }
    });
    return ids;
  };

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <>
      {permissions && permissions.isLoading === false && (
        <Navbar
          className="navbar-vertical fixed-left navbar-light bg-white"
          style={{ paddingRight: 0 }}
          expand="md"
          id="sidenav-main"
        // style={{ padding: 0 }}
        >
          <Container fluid>
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleCollapse}
            >
              <span className="navbar-toggler-icon" />
            </button>
            {/* Brand */}
            {logo ? (
              <NavbarBrand
                className="pt-0 pr-4 d-flex flex-column pb-0"
                {...navbarBrandProps}
              >
                <div className="d-flex justify-content-center mt-2">
                  <img
                    alt={logo.imgAlt}
                    className="navbar-brand-img"
                    src={logo.imgSrc}
                  // style={{ maxHeight: "5rem", width: "5rem" }}
                  />
                </div>
              </NavbarBrand>
            ) : null}
            <Nav className="align-items-center d-md-none">
              <DropDownNavbarMenu currentUser={currentUser} />
            </Nav>

            <Collapse
              navbar
              isOpen={collapseOpen}
              className="align-items-center"
            // style={{ padding: 0, marginLeft: "3vh" }}
            >
              <div className="navbar-collapse-header d-md-none">
                <Row>
                  {logo ? (
                    <Col className="collapse-brand" xs="6">
                      {logo.innerLink ? (
                        <Link to={logo.innerLink}>
                          <img alt={logo.imgAlt} src={logo.imgSrc} />
                        </Link>
                      ) : (
                        <a href={logo.outterLink}>
                          <img alt={logo.imgAlt} src={logo.imgSrc} />
                        </a>
                      )}
                    </Col>
                  ) : null}
                  <Col className="collapse-close" xs="6">
                    <button
                      className="navbar-toggler"
                      type="button"
                      onClick={toggleCollapse}
                    >
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav navbar style={{}}>
                {createLinks2(routes)}
              </Nav>
            </Collapse>
          </Container>
        </Navbar>)}
    </>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
