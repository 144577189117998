import Axios from "./caller.service.js";

let manageCustomerWebsite = (data) => {
  return Axios.post("/api/CustomerWebsite/manage", data);
};

let getCustomerWebsite = () => {
  return Axios.get("/api/CustomerWebsite");
};

export const customerWebsiteService = {
  manageCustomerWebsite,
  getCustomerWebsite
};
