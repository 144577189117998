import { Container } from "reactstrap";
import GenericHeader from "../../../components/Headers/GenericHeader";

import { mainView } from "./constants";
import { useState } from "react";
import Main from "./main";
import ActionContent from "./actionContent";
import useFetchUser from "../../../hooks/useFetchUser";
import { userService } from "../../../utils/_services/user.service";
import { NotificationManager } from "react-notifications";

const ManageMyAccount = () => {
    const [activeView, setActiveView] = useState(mainView);
    const user = useFetchUser();

    const onClickDeleteAccount = () => {
        userService.deleteUser(user.id)
            .then((response) => {
                userService.doLogout();
            })
            .catch((er) => {
                NotificationManager.error("Une erreur est survenue. Contactez le support.");
            });
    }

    const getView = () => {
        switch (activeView) {
            case mainView:
                return <Main onClick={setActiveView} />
            default:
                return <ActionContent actionView={activeView} onReturnClick={setActiveView} onClickDeleteAccount={onClickDeleteAccount} />;
        }
    }

    return (
        <>
            <GenericHeader />
            <Container className="p-3">
                {getView()}
            </Container>
        </>
    );
};

export default ManageMyAccount; 