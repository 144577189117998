import { ConfirmationMessage } from "../../components/Generic/confirmationMessage";
import GenericHeader from "../../components/Headers/GenericHeader";
export default function Success() {
  return (
    <>
      <GenericHeader />
      <ConfirmationMessage mainMessage={"Votre inscription a été mis à jour."} />
    </>
  );
}
