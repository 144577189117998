import Axios from "./caller.service.js";

let getAllJobs = () => {
  return Axios.get("/api/Jobs");
};

let getAllJobsFamilies = () => {
  return Axios.get("/api/Jobs/families");
};

export const jobService = {
  getAllJobs,
  getAllJobsFamilies,
};
