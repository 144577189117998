import Axios from "./caller.service.js";

const getAllCompanies = () => {
  return Axios.get("/api/Companies/all");
};

const getCompanyById = (id) => {
  return Axios.get(`/api/Companies/${id}`);
};

const getCompanyBySiretNumber = (siretNumber) => {
  return Axios.get(`/api/Companies/name/siret/${siretNumber}`);
};

const searchCompany = (value) => {
  return Axios.post(`/api/Companies/search`, { value: value });
};

const updateCompany = (company) => {
  return Axios.put("/api/Companies", company);
};

const addCompany = (company, userId) => {
  return Axios.post("/api/Companies", {
    Company: company,
    UserId: userId,
  });
};

export const companyService = {
  getAllCompanies,
  getCompanyById,
  updateCompany,
  addCompany,
  searchCompany,
  getCompanyBySiretNumber,
};
