import React from "react";
import { Button, Table } from "reactstrap";
import { subProductInterval } from "../../utils/constants";
import { useTranslation } from "react-i18next";
import { CheckCircleOutline, CloseRounded } from "../Icons/icons";
import './PriceCard.css'
import { sortByPropertyAsc } from "../../utils/converter";
import { features } from "./constants";

const PriceCard = (props) => {
    const { t } = useTranslation();
    const { products, selected, isLandingPage, handleSelect, isReadonly } = props;

    const getFeature = (product, feature) => {
        let selectedFeature;
        switch (product.title) {
            case "Basic":
                selectedFeature = feature.basic;
                break;
            case "Entreprise":
                selectedFeature = feature.entreprise;
                break;
            case "Pro":
                selectedFeature = feature.premium;
                break;
            default:
                selectedFeature = feature.free; // Default to basic if no match
                break;
        }

        return (
            <span className="feature-container">
                {selectedFeature.checked ? (
                    <CheckCircleOutline size="1.5em" className="feature-check-icon" />
                ) : (
                    <CloseRounded size="1.5em" className="text-danger" />
                )}
                {selectedFeature.comment && <span className="feature-comment text-muted">{selectedFeature.comment}</span>}
            </span>
        );
    }

    return <Table className="pricing-features" hover borderless responsive>
        <thead>
            <tr>
                <th></th>
                {sortByPropertyAsc(products.filter(x => x.interval === "month"), "amount", true).map(item => (
                    <th>
                        <div className="pricing-card">
                            <div className="header">
                                <h2>{t(item.title)}</h2>
                                <div style={{ width: "210px", color: "cadetblue", display: "contents" }}>{<br />} </div>
                            </div>
                            <div className="plan-price">
                                {`${item.amount} ${item.currency}`}
                                <span className="ml-2 text-muted text-xs">
                                    {`${t("TTC")} / ${item.intervalCount > 1 ? item.intervalCount + " " : ""} ${t(subProductInterval()[item.interval])}`}
                                </span>
                            </div>
                            {/* <div style={{ marginTop: '-5px' }}>
                                <p className="plan-frequency">
                                    {`${t("HT")} / ${item.intervalCount > 1 ? item.intervalCount + " " : ""} ${t(subProductInterval()[item.interval])}`}</p>
                            </div> */}
                            {/* <div style={{ margin: "15px 10px 15px 10px" }}>
                                <p className="plan-ttc" >{`${t("That is")} ${Number.parseFloat(parseFloat(item.amount) * 1.2).toFixed(2)}€ ${t("TTC")} / ${item.intervalCount > 1 ? item.intervalCount + " " : ""} ${t(subProductInterval()[item.interval])}`}</p>
                            </div> */}
                            <div style={{ margin: "30px 10px 30px 10px" }}>
                                {!(selected?.id === item.id) && !isReadonly && (
                                    <Button color="primary" onClick={() => handleSelect(item)} block>
                                        {isLandingPage ? t("Start 30-Day Free Trial") : t("Choose")}
                                    </Button>
                                )}
                                {(selected?.id === item.id) && !isReadonly && (
                                    <Button color="primary" block disabled>
                                        {t("Selected plan")}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </th>
                ))}
            </tr>
        </thead>
        <tbody>
            {features.map(feat =>
                <>
                    <tr>
                        <td className="feature-header">{feat.title}</td>
                        {products.filter(x => x.interval === "month").map(() => (
                            <>
                                <td className="feature-header"></td>
                                <td className="feature-header"></td>
                            </>
                        ))}
                    </tr>
                    {feat.features.map(feature =>
                        <tr>
                            <td className="feature-col border-bottom">{feature.name}</td>
                            {sortByPropertyAsc(products.filter(x => x.interval === "month"), "amount", true).map((product) => (
                                <td className="feature-col border-bottom">{getFeature(product, feature)}</td>))}
                        </tr>
                    )}
                </>
            )}
        </tbody>
    </Table>
};

export default PriceCard;
