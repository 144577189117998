import Axios from "./caller.service.js";

const getAllCities = () => {
  return Axios.get("/api/cities");
};

const getCityById = (id) => {
  return Axios.get(`/api/city/${id}`);
};

const searchCities = (searchRequest) => {
  return Axios.post("/api/city/search", searchRequest);
};

export const cityService = {
  getAllCities,
  searchCities,
  getCityById,
};
