import React from "react";
import CustomNavLink from "../inputs/Navlink.js";
import { ArrowLeftCircle } from "../../Icons/icons.jsx";

const Back = (props) => {
    const { title, handleClick } = props;
    return (
        <CustomNavLink
            title={<div style={{
                display: 'inline-block',
                borderRadius: '8px',
                marginBottom: "10px"
            }}><ArrowLeftCircle style={{
                height: '30px',
                fill: 'rgb(4, 169, 245)',
                color: '#ffffff',
                width: '30px'
            }} /><span style={{
                fontWeight: '600',
                fontSize: '14px',
                top: '1.5px',
                position: 'relative',
                marginLeft: '2px',
                color: 'rgb(4, 169, 245)',
                textDecoration: 'underline'
            }}>{title}</span></div>}
            action={handleClick}
            to={"#"}
        />
    );
};

export default Back;
