import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import "./offre.css";
import "../../../Ui/fond.css";
import SliderPhoto from "../../../Photo/Slider/SliderPhoto";
import { TitleWelcome } from "../../common.js";
import Buttons from "../../../Ui/Button.js";

const Offre = () => {
  useEffect(() => {
    Aos.init({
      duration: 3000,
    });
  }, []);

  return (
    <div className="container bgDefault justify-content-center" id="offre">
      <div className="col-md-12 d-flex justify-content-center text-center mt-5">
        <TitleWelcome>Quelques secteurs d’activité représentés</TitleWelcome>
      </div>
      <SliderPhoto />
      <div className="mt-3 d-flex justify-content-center" >
        <Buttons linkTo={process.env.REACT_APP_WELCOME_PAGE_PATH} buttonText="Inscrivez-vous c'est gratuit" />
      </div>
    </div>
  );
};

export default Offre;
