import { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Form,
  Row,
  Col,
  FormGroup,
  CardHeader,
  Label,
} from "reactstrap";
import { NotificationManager } from "react-notifications";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { companyService } from "../../../utils/_services/company.service.js";
import Image from "../../../components/Forms/others/Image.js";
import GenerateFormInput from "../../../components/Ui/GenerateFormInput.js";
import Dialog from "../../../components/Modal/Dialog.js";
import ButtonAddons from "../../../components/Search/ButtonAddons.js";
import DeleteIconButtonWithModal from "../../../components/Buttons/DeleteIconButtonWithModal.js";
import ManageInsurance from "../manageInsurance.js";
import { v4 } from "uuid";
import { convertStringToBase64 } from "../../../utils/converter.js";
import SignatureScreen from "../../../components/Signature/SignatureScreen.js";
import { siretNumberCharactersCount } from "utils/constants.js";
import {
  companyAdressConfigs,
  inputConfigs,
} from "./model.js";
import Choice from "../../../components/Forms/inputs/Choice.js";

const EditCompany = ({ companyData, userId, tabTitle }) => {
  const [company, setCompany] = useState({});
  const [originalCompany, setOriginalCompany] = useState({});
  const [modal, setModal] = useState(false);
  const [selectedInsurance, setSelectedInsurance] = useState({});
  const toggleManageInsurance = () => setModal(!modal);

  useEffect(() => {
    if (companyData?.id) {
      companyService
        .getCompanyById(companyData.id)
        .then((res) => {
          res.data.insurances?.map((x) => (x.guid = v4()));
          setCompany(res.data);
          setOriginalCompany(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [companyData]);

  const onHandleAddon = () => {
    setSelectedInsurance({});
    toggleManageInsurance();
  };

  const onSubmitManageInsurance = (value) => {
    const editedCompany = { ...company };

    if (editedCompany.insurances != null) {
      const insuranceIndex = editedCompany.insurances.findIndex(
        (insurance) => insurance.guid === value.guid
      );

      if (insuranceIndex > -1) {
        // Update existing insurance
        editedCompany.insurances[insuranceIndex] = value;
      } else {
        // Add new insurance
        editedCompany.insurances.push(value);
      }
    } else {
      // Initialize insurances array with the new insurance
      editedCompany.insurances = [value];
    }

    setCompany(editedCompany);
    toggleManageInsurance();

    onSubmit();
  };

  const manageInsurance = (currentInsurance) => {
    setSelectedInsurance(currentInsurance);
    toggleManageInsurance();
  };
  const onDeleteInsurance = (value) => {
    const editedCompany = { ...company };
    editedCompany.insurances = editedCompany.insurances.filter(
      (item) => item.guid !== value
    );
    setCompany(editedCompany);
    onSubmit();
  };

  const onSubmit = () => {
    const editedCompany = { ...company };
    updateCompany(editedCompany);
  };

  const updateCompany = (editedCompany) => {
    if (editedCompany.logo?.content) {
      editedCompany.logo.content = convertStringToBase64(
        editedCompany.logo.content
      );
    }

    if (
      editedCompany?.siretNumber === undefined ||
      editedCompany?.siretNumber === ""
    ) {
      NotificationManager.error("Le numéro de siret est obligatoire");
      return;
    }

    if (editedCompany?.id && editedCompany.id > 0) {
      companyService
        .updateCompany(editedCompany)
        .then((res) => {
          NotificationManager.success(
            "Votre entreprise a été mis a jour avec succes"
          );
          setOriginalCompany(res.data.value);
        })
        .catch((err) => {
          NotificationManager.error(
            "Un problème est survenu lors de la mise à jour de votre entreprise."
          );
        });
    } else {
      companyService
        .addCompany(editedCompany, userId)
        .then((res) => {
          const data = res.data;
          if (data.resultState === 0) {
            NotificationManager.error(data.message);
          } else {
            NotificationManager.success(
              "Votre entreprise a été mis a jour avec succes"
            );
            setOriginalCompany(data.value);
            setCompany(data.value);
          }
        })
        .catch((err) => {
          NotificationManager.error(
            "Un problème est survenu lors de la mise à jour de votre entreprise."
          );
        });
    }
  };

  const onSavePicture = ({ name, content }) => {
    const editedCompany = { ...originalCompany };
    editedCompany.logo = {
      content: content,
      name: name,
      url: null,
    };

    setCompany((prevCompany) => ({
      ...prevCompany,
      logo: {
        ...prevCompany.logo,
        content: content,
        name: name,
        url: null,
      },
    }));

    updateCompany(editedCompany);
  };

  const onChangeSignature = ({ name, content }) => {
    onChangeImage({ name, content, field: "signature" });
  };

  const onChangeStamp = ({ name, content }) => {
    onChangeImage({ name, content, field: "stamp" });
  };

  const onChangeImage = ({ name, content, field }) => {
    debugger;
    const editedCompany = { ...originalCompany };
    if (content === null) {
      setCompany((prevCompany) => ({
        ...prevCompany,
        [field]: null,
      }));
      return;
    }

    setCompany((prevCompany) => ({
      ...prevCompany,
      [field]: {
        ...prevCompany[field],
        id: 0,
        content: content,
        name: name ? name : `${field}-${editedCompany.siretNumber}.png`,
      },
    }));
  };

  const onChange = (e) => {
    setCompany({
      ...company,
      [e.target.name]: e.target.value,
    });
    const validSiret =
      e.target.name === "siretNumber" &&
      // company.name === "" &&
      e.target.value != null &&
      e.target.value.length === siretNumberCharactersCount;

    if (validSiret === true) {
      companyService
        .getCompanyBySiretNumber(e.target.value)
        .then((res) => {
          console.log(res.data);
          setCompany({
            ...company,
            [e.target.name]: e.target.value,
            name: company.name?.trim() ? company.name : res.data?.companyName,
            zipCode: company.zipCode?.trim() ? company.zipCode : res.data?.codePostalEtablissement,
            cityName: company.cityName?.trim() ? company.cityName : res.data?.libelleCommuneEtablissement,
            addressLines: company.addressLines?.trim() ? company.addressLines :
              `${res.data.numeroVoieEtablissement ?? ""} ${res.data.typeVoieEtablissement ?? ""} ${res.data.libelleVoieEtablissement ?? ""} ${res.data.complementAdresseEtablissement ?? ""}`,
            inseeStatusCode: company.inseeStatusCode?.trim() ? company.inseeStatusCode : res.data?.etatAdministratifUniteLegale,
            legalUnitCode: company.legalUnitCode?.trim() ? company.legalUnitCode : res.data?.categorieJuridiqueUniteLegale,
            creationDate: company.creationDate?.trim() ? company.creationDate : res.data?.dateCreationEtablissement ? res.data.dateCreationEtablissement.substring(0, 10) : undefined,
          })
            .catch((err) => console.log(err));
        });
    }
  };

  const onSelectChange = (field, value) => {
    setCompany((prevCompany) => ({
      ...prevCompany, [field]: value,
    }));
  };

  return (
    <>
      <Card className="shadow">
        <CardHeader>
          <h4>{tabTitle}</h4>
        </CardHeader>
        <CardBody>
          <Form onSubmit={onSubmit}>
            <Row>
              {inputConfigs.map((config, index) => (
                <Col xs="12" sm="6" md="6" lg="3" key={index}>
                  <GenerateFormInput
                    forInput={config.forInput}
                    label={config.label}
                    editModeClassName={true}
                    value={company?.[config.value]}
                    onChange={onChange}
                    name={config.value}
                    placeholder={config.placeholder}
                    type={config.type}
                    required={config.required}
                    icon={config.icon}
                    disabled={false}
                  />
                </Col>
              ))}
            </Row>
            {companyAdressConfigs.rows.map((row, index) => (
              <Row key={index}>
                {row.columns.map(
                  (
                    { size, render },
                    index // eslint-disable-line  no-unused-vars
                  ) => (
                    <Col md={size}>
                      {
                        ((render.type === "text" || render.type === "date") &&
                          <GenerateFormInput
                            forInput={render.forInput}
                            label={render.label}
                            editModeClassName={true}
                            value={render.type === "date" ? company?.[render.value]?.substring(0, 10) : company?.[render.value]}
                            onChange={onChange}
                            name={render.value}
                            placeholder={render.placeholder}
                            type={render.type}
                            required={render.required}
                            icon={render.icon}
                            disabled={false}
                          />)}
                      {render.type === "select" && (
                        <Choice
                          field={render.field}
                          title={render.label}
                          value={company?.[render.value]}
                          placeholder={render.placeholder}
                          lookup={render.lookup}
                          isRequired={render.required}
                          isReadOnly={render.disabled}
                          handleChange={onSelectChange}
                        />
                      )}
                    </Col> // eslint-disable-line  no-unused-vars
                  )
                )}
              </Row>
            ))}
            <Row>
              {/* <Col md="4" sm="12">
                <Label>Vos assurances</Label>
                {company && company.insurances != null && (
                  <ul>
                    {" "}
                    {company.insurances.map((value, index) => (
                      <li
                        className="font-weight-bolder text-dark mb-2"
                        key={`insurance-${index}`}
                      >
                        {value.title}
                        <DeleteIconButtonWithModal
                          modalTitle={"Supprimer une assurance"}
                          modalText={
                            "êtes-vous sûr de vouloir supprimer cette assurance ?"
                          }
                          deleteAction={() => onDeleteInsurance(value.guid)}
                          className={"ml-3 cursor-pointer"}
                        ></DeleteIconButtonWithModal>
                        <span>
                          <FontAwesomeIcon
                            title="Modifier"
                            icon={faPenToSquare}
                            className="ml-2 cursor-pointer"
                            color="gray"
                            onClick={() => manageInsurance(value)}
                          />
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
                {(company?.insurances === null ||
                  company.insurances === undefined ||
                  company.insurances.length < 3) && (
                    <div className="mt-2">
                      <ButtonAddons
                        addonText={"Nouvelle assurance"}
                        addonTooltip={"Nouvelle assurance"}
                        handleAddon={onHandleAddon}
                      />
                    </div>
                  )}
              </Col> */}
              <Col md="4" sm="12">
                <SignatureScreen
                  name='stamp'
                  title={"Tampon de l'entreprise"}
                  subtitle={"Ajouter un tampon de l'entreprise"}
                  canDraw={false}
                  onChangeImage={onChangeStamp}
                  imageURL={company?.stamp?.url}
                  content={company?.stamp?.content}
                />
              </Col>
              <Col md="4" sm="12">
                <div
                  style={{
                    maxWidth: "140px",
                    marginRight: "auto",
                    marginLeft: "auto",
                  }}
                >
                  <FormGroup>
                    <div>
                      <Label>Logo de l'entreprise</Label>
                    </div>
                    <div>
                      <Image
                        handleUpload={onSavePicture}
                        content={company.logo?.content}
                        url={company.logo?.url}
                      />
                    </div>
                  </FormGroup>
                </div>
              </Col>
              <Col md="4" sm="12">
                <SignatureScreen
                  name='signature'
                  title={"Signature de l'entreprise"}
                  subtitle={"Ajouter une signature de l'entreprise"}
                  canDraw={true}
                  onChangeImage={onChangeSignature}
                  imageURL={company?.signature?.url}
                  content={company?.signature?.content}
                />
              </Col>
            </Row>
          </Form>
          <div className="d-flex justify-content-end">
            <Button
              className="my-3 btn btn-success"
              title="Sauvegarder"
              onClick={onSubmit}
            >
              Sauvegarder
            </Button>
          </div>
        </CardBody>
      </Card>
      {modal && (
        <Dialog
          title="Assurance"
          component={
            <>
              <ManageInsurance
                onSubmit={onSubmitManageInsurance}
                onCancel={toggleManageInsurance}
                name={"new"}
                placeholder={"Nouvelle assurance"}
                rowsCount={2}
                maxlength={200}
                insurance={selectedInsurance}
              />
            </>
          }
          handleClose={toggleManageInsurance}
        />
      )}
    </>
  );
};

export default EditCompany;
