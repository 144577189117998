import React from "react";
import WelcomeNavbar from "../../../Navbars/Welcome/WelcomeNavbar.js";
import "./intro.css";
import Buttons from "../../../Ui/Button.js";
import work from "../../../../assets/img/welcome/welcome_btpgo.png";

const Intro = () => {
  return (
    <>
      <WelcomeNavbar />
      <div className="container-fluid" id="services">
        <div
          className="row d-flex justify-content-center "
          style={{
            alignItems: "center",
            height: "auto",
          }}
        >
          <div className="col-lg-6 col-xs-12 pt-4 text-center justify-content-center">
            {/* <span className="tittleLogo">BtpGo</span> */}
            <h1 className="titleLandingPage">
              Simplifiez vos chantiers, optimisez vos coûts, <br /> En quelques clics
            </h1>
            <h2 className="mb-16 mt-4 mr-2 ml-2 descriptionLandingPage">
              <span className="strong-intro">Trouvez le bon pro, au bon prix,</span> et boostez l'efficacité de vos projets avec BTPGO.
            </h2>
            <Buttons linkTo={process.env.REACT_APP_WELCOME_PAGE_PATH} buttonClass={"mt-5"} buttonText="Inscrivez-vous c'est gratuit" />
          </div>

          <div className="col-lg-6 col-xs-12 d-flex justify-content-center">
            <img className="imageLandingPage" alt="work" src={work} />
          </div>
        </div>
      </div>
    </>
  );

};

export default Intro;
