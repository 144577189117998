import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, FormGroup, Row, Col, CardHeader } from "reactstrap";
import "./profil.css";
import GenerateFormInput from "../Ui/GenerateFormInput";
import Image from "../Forms/others/Image";
import { BiPhoneCall, BiMailSend } from "react-icons/bi";
import image from "../../assets/img/blog/fond.jpeg";

import { useDispatch, useSelector } from "react-redux";
import {
  fetchWorkAvailabilities,
  getWorkAvailabilitiesStatus,
} from "../../features/slices/workAvailabilitySlice.js";

import { updateUser as updateUserAction } from "../../features/slices/userSlice.js";
import { convertStringToBase64 } from "../../utils/converter";

const EditProfile = ({ userData, tabTitle }) => {
  const [user, setUser] = useState(userData);
  const [originalUser, setOriginalUser] = useState(userData);
  const hiddenFileInput = React.useRef(null);

  const workAvailabilitiesStatus = useSelector(getWorkAvailabilitiesStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (workAvailabilitiesStatus === "idle") {
      dispatch(fetchWorkAvailabilities());
    }
  }, [workAvailabilitiesStatus, dispatch]);


  // Rename the local updateUser function to handleUpdateUser to avoid conflict
  const handleUpdateUser = async (editedUser) => {
    try {
      const updatedUser = await dispatch(updateUserAction(editedUser)).unwrap(); // Using updateUserAction

      // Update the user state with the updated user
      setUser(updatedUser);

      // Optionally, update the originalUser if needed
      setOriginalUser(updatedUser);
    } catch (error) {
      console.error('Failed to update user:', error);
      // Handle error, e.g., show a notification to the user
    }
  }
  const onChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    await handleUpdateUser(user);
  };

  const onSavePicture = async ({ name, content }) => {
    const editedUser = { ...originalUser };

    var base64String = convertStringToBase64(content)
    editedUser.photo = {
      content: base64String,
      name: name
    };

    setUser((prevUser) => ({
      ...prevUser,
      photo: {
        ...prevUser.photo,
        content: base64String,
        name: name,
      },
    }));

    await handleUpdateUser(editedUser);
  };

  const previewPicture = (e) => {
    const files = Array.from(e.target.files);

    // "picture" est un objet File
    if (files.length > 0) {
      const file = files[0];

      // Encode the file using the FileReader API
      const reader = new FileReader();
      reader.onloadend = () => {
        // Use a regex to remove data url part
        const base64String = reader.result
          .replace("data:", "")
          .replace(/^.+,/, "");

        setUser((prevUser) => ({
          ...prevUser,
          photo: {
            ...prevUser.photo,
            profilUrl: base64String,
            name: file.name,
          },
        }));
      };
      reader.readAsDataURL(file);

      var image = document.getElementById("profilePicture");
      image.src = URL.createObjectURL(file);
      // On change l'URL de l'image
    }
  };

  return (
    <>
      <Card className="shadow mb-2">
        <CardHeader>
          <h4>{tabTitle}</h4>
        </CardHeader>
        <CardBody>
          <Col lg="12 pl-0">
            <div
              style={{
                backgroundImage: `url(${image})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "10rem",
                display: "flex",
                marginBottom: "2rem",
              }}
            >
              <FormGroup className="p-4">
                <Image handleUpload={onSavePicture} content={user.photo?.content} url={user.photo?.url} />
                <input
                  id="profilePictureFile"
                  ref={hiddenFileInput}
                  type="file"
                  name="picture"
                  onChange={previewPicture}
                  accept=".jpg, .png, .jpeg, .gif"
                  style={{ display: "none" }}
                />
              </FormGroup>
            </div>
          </Col>

          <div className="col-md-12">
            <Row>
              <Col md="6" sm="6">
                <GenerateFormInput
                  forInput="input-last-name"
                  label="Nom"
                  editModeClassName={true}
                  value={user.lastName}
                  onChange={onChange}
                  name="lastName"
                  type="text"
                  placeholder="..."
                  disabled={false}
                />
              </Col>
              <div className="col-md-6">
                <GenerateFormInput
                  forInput="input-first-name"
                  label="Prénom"
                  editModeClassName={true}
                  value={user.firstName}
                  onChange={onChange}
                  name="firstName"
                  type="text"
                  placeholder="..."
                  disabled={false}
                />
              </div>
            </Row>
            <Row>
              <div className="col-md-6">
                <GenerateFormInput
                  forInput="input-phoneNumber"
                  label="Téléphone mobile"
                  icon={
                    <BiPhoneCall
                      className="mb-2"
                      fontSize="1rem"
                      color="rgb(111,174,222)"
                    />
                  }
                  editModeClassName={true}
                  value={user.phoneNumber}
                  onChange={onChange}
                  name="phoneNumber"
                  type="text"
                  placeholder="..."
                  disabled={false}
                />
              </div>
              <div className="col-md-6">
                <GenerateFormInput
                  forInput="input-email"
                  label="Email"
                  icon={
                    <BiMailSend
                      className="mb-2"
                      fontSize="1rem"
                      color="rgb(111,174,222)"
                    />
                  }
                  editModeClassName={true}
                  value={user.mail}
                  onChange={onChange}
                  name="mail"
                  type="email"
                  placeholder="..."
                  disabled={false}
                />
              </div>
            </Row>
          </div>
          {/* <Col lg="12">
              <GenerateFormInputWithLimit
                forInput="input-hobbies"
                label="Ce que j'aime"
                editModeClassName={true}
                value={user.hobbies}
                onChange={onChange}
                maxlength={2000}
                name="hobbies"
                rowsCount={5}
                placeholder="Ce que j'aime"
                type="textarea"
                icon={
                  <AiOutlineHeart
                    className="mb-2"
                    fontSize="1rem"
                    color="rgb(111,174,222)"
                  />
                }
                disabled={false}
              />
            </Col> */}

          <div className="d-flex justify-content-end">
            <Button
              className="my-3 btn btn-success"
              title="Sauvegarder"
              onClick={onSubmit}
            >
              Sauvegarder
            </Button>
          </div>


        </CardBody>
      </Card>
    </>
  );
};

export default EditProfile;
