import React from "react";
import WelcomeNavbar from "../../../Navbars/Welcome/WelcomeNavbar";
import Buttons from "../../../Ui/Button";
import "./introArtisans.css";

const IntroArtisans = () => {
  return (
    <>
      <div
        className="bgIntroArtisans"
        style={{
          width: "100%",
          height: "105vh",
        }}
      >
        <WelcomeNavbar />
        <div className="introArtisansRow">
          <div className="col-md-6 d-flex flex-column   artisansSize">
            {/* <div className="contact" data-aos="fade-right"> */}
            <div className="introArtisansTitle">Opérez là où vous excellez</div>
            <p className="introArtisansP">
              BtpGO vous offre un site web gratuit garantissant votre présence
              digitale et favorisant vos opportunités en tant que professionnel
              BTP.
            </p>

            <Buttons linkTo={process.env.REACT_APP_WELCOME_PAGE_PATH} buttonText="Inscription" />
          </div>
          <div className="col-md-6 d-flex justify-content-center">
            <img
              className="imgIntroArtisans"
              alt="..."
              src={require("../../../../assets/img/principal/introArtisan.png")}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IntroArtisans;
