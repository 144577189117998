import * as React from "react";
import {
  Button,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { socialService } from "../../utils/_services/social.services.js";

export default function CreatePost({ addNewPost }) {
  const [open, setOpen] = React.useState(false);
  const [uploadFile, setFile] = React.useState(undefined);

  function handleOnSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = {
      title: formData.get("title")?.toString(),
      description: formData.get("description")?.toString(),
      url: formData.get("link")?.toString(),
      type: 0,
    };

    if (uploadFile !== undefined) {
      data.attachment = {
        name: formData.get("filePost").name,
        content: uploadFile.split(",")[1],
      };
    }

    socialService
      .createPost(data)
      .then((response) => {
        if (response !== undefined) {
          addNewPost(response.data);
        } else {
          console.log(response);
        }
      })
      .catch((err) => console.log(err));
    handleClose();
  }

  const handleChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      setFile(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card>
        <CardBody className="text-center">
          <Button
            style={{ background: "#9077ed", color: "white" }}
            onClick={handleClickOpen}
            block
          >
            Commencer un post
          </Button>
          <Modal isOpen={open} toggle={handleClose} centered>
            <form onSubmit={handleOnSubmit}>
              <ModalHeader toggle={handleClose}>
                <h2>Créer une publication</h2>
              </ModalHeader>
              <ModalBody>
                <FormGroup>
                  <Label for="title">Titre</Label>
                  <Input
                    type="text"
                    name="title"
                    id="title"
                    placeholder="Titre"
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="title">Lien</Label>
                  <Input type="text" name="link" id="link" placeholder="Lien" />
                </FormGroup>
                <FormGroup>
                  <Label for="title">Description</Label>
                  <Input
                    type="textarea"
                    name="description"
                    id="description"
                    placeholder="Description"
                  />
                </FormGroup>
                <FormGroup>
                  <input
                    type="file"
                    name="filePost"
                    onChange={(e) => handleChange(e)}
                  />

                  {uploadFile && (
                    <div className="m-3">
                      <img
                        alt="post"
                        src={uploadFile}
                        className="rounded img-fluid"
                      />
                    </div>
                  )}
                </FormGroup>
              </ModalBody>
              <ModalFooter>
                <Button color="success" type="submit">
                  <i className="fa fa-facebook-f"></i>&nbsp;Publier
                </Button>{" "}
                <Button color="danger" onClick={handleClose}>
                  {" "}
                  <i className="fa fa-close"></i>&nbsp;&nbsp;Annuler
                </Button>{" "}
              </ModalFooter>
            </form>
          </Modal>
        </CardBody>
      </Card>
    </>
  );
}
