import { useEffect, useState } from "react";
import { Button, Card, CardBody, Form, Row, Col, CardHeader } from "reactstrap";
import { NotificationManager } from "react-notifications";

import { companyService } from "../../../utils/_services/company.service.js";

import GenerateFormInput from "../../../components/Ui/GenerateFormInput.js";
import { companyCommunicationLinks } from "./model.js";

const EditCompanyCommunication = ({ companyData, userId, tabTitle }) => {
  const [company, setCompany] = useState({});

  useEffect(() => {
    if (companyData?.id) {
      companyService
        .getCompanyById(companyData.id)
        .then((res) => {
          setCompany(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [companyData]);

  const onSubmit = (e) => {
    e.preventDefault();
    if (company?.siretNumber === undefined || company?.siretNumber === "") {
      NotificationManager.error("Le numéro de siret est obligatoire");
      return;
    }
    if (company?.id && company.id > 0) {
      companyService
        .updateCompany(company)
        .then((res) => {
          NotificationManager.success(
            "Votre entreprise a été mis a jour avec succes"
          );
        })
        .catch((err) => {
          NotificationManager.error(
            "Un problème est survenu lors de la mise à jour de votre entreprise."
          );
        });
    } else {
      companyService
        .addCompany(company, userId)
        .then((res) => {
          const data = res.data;
          if (data.resultState === 0) {
            NotificationManager.error(data.message);
          } else {
            NotificationManager.success(
              "Votre entreprise a été mis a jour avec succes"
            );
          }
        })
        .catch((err) => {
          NotificationManager.error(
            "Un problème est survenu lors de la mise à jour de votre entreprise."
          );
        });
    }
  };

  const onChange = (e) => {
    setCompany({
      ...company,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Card className="shadow">
      <CardHeader>
        <h4>{tabTitle}</h4>
      </CardHeader>
      <CardBody>
        <Form onSubmit={onSubmit}>
          {companyCommunicationLinks.rows.map((row, index) => (
            <Row key={index}>
              {row.columns.map(
                (
                  { size, render },
                  index // eslint-disable-line  no-unused-vars
                ) => (
                  <Col md={size}>
                    {
                      <GenerateFormInput
                        forInput={render.forInput}
                        label={render.label}
                        editModeClassName={true}
                        value={company?.[render.value]}
                        onChange={onChange}
                        name={render.value}
                        placeholder={render.placeholder}
                        type={render.type}
                        required={render.required}
                        icon={render.icon}
                        disabled={false}
                      />}
                  </Col> // eslint-disable-line  no-unused-vars
                )
              )}
            </Row>
          ))}
        </Form>
        <div className="d-flex justify-content-end">
          <Button
            className="my-3 btn btn-success"
            title="Sauvegarder"
            onClick={onSubmit}
          >
            Sauvegarder
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default EditCompanyCommunication;
