import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Toast,
  ToastBody,
} from "reactstrap";
import Save from "../Forms/buttons/Save";
import Cancel from "../Forms/buttons/Cancel";
import Text from "../Forms/inputs/Text";
import { filterArrayByCriteria } from "../../utils/_helpers/filters";


const DragAndDrop = (props) => {
  const {
    state,
    onReorder,
    onMove,
    hasChanged,
    setHasChanged,
    filtersCriteria,
    setFiltersCriteria,
    onCancel,
    onSave,
  } = props;

  const onDragEnd = (data) => {
    const { source, destination, draggableId } = data;

    // dropped outside the list
    if (!destination) {
      return;
    }
    const sInd = +source.droppableId;
    const dInd = +destination.droppableId;

    if (sInd === dInd) {
      const items = reorder(
        state[sInd].items,
        state[sInd].items.findIndex((i) => i === draggableId),
        destination.index
      );
      const newState = [...state];
      newState[sInd].items = items;
      if (onReorder) onReorder(newState);
      if (setHasChanged) setHasChanged(true);
    } else {
      const result = move(
        state[sInd].items,
        state[dInd].items,
        source,
        destination,
        draggableId
      );
      const newState = [...state];
      newState[sInd].items = result[sInd];
      newState[dInd].items = result[dInd];
      if (onMove) onMove(newState);
      if (setHasChanged) setHasChanged(true);
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /**
   * Moves an item from one list to another list.
   */
  const move = (
    source,
    destination,
    droppableSource,
    droppableDestination,
    draggableId
  ) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(
      sourceClone.findIndex((i) => i === draggableId),
      1
    );

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };
  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 1,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? "#d5f0de" : "#f4f5f7",

    // styles we need to apply on draggables
    ...draggableStyle,
  });
  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#d5f0de" : "#f4f5f7",
    padding: grid,
    width: "auto",
  });

  const onFilter = (field, criteria) => {
    setFiltersCriteria({ ...filtersCriteria, ...{ [field]: criteria } });
  };

  return (
    <div>
      <div style={{ width: "80%", margin: "0 auto" }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Row>
            {state.map((el, ind) => (
              <Col md="6">
                <Card>
                  <CardHeader style={{ background: "#f4f5f7" }}>
                    <CardTitle>
                      <div>
                        {el.title}
                        {el.hasFilter && (
                          <Text
                            field={el.id}
                            value={filtersCriteria[el.id]}
                            isRequired={false}
                            isReadOnly={false}
                            handleChange={onFilter}
                            placeholder={"Filter by ..."}
                          />
                        )}
                      </div>
                    </CardTitle>
                  </CardHeader>
                  <CardBody
                    style={{
                      background: "#f4f5f7",
                      maxHeight: "80vh",
                      overflowY: "auto",
                    }}
                  >
                    <Droppable key={ind} droppableId={`${ind}`}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                          {...provided.droppableProps}
                        >
                          {filterArrayByCriteria(
                            el.items,
                            filtersCriteria[el.id]
                          ).map((item, index) => (
                            <Draggable
                              key={item}
                              draggableId={item}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <Toast style={{ margin: "0 auto" }}>
                                    <ToastBody>{item}</ToastBody>
                                  </Toast>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
          {hasChanged && (
            <Row>
              <Col md={12} className="mt-3">
                <Cancel title={"Cancel"} handleCancel={onCancel} />
                <Save title={"Save"} handleSave={onSave} />
              </Col>
            </Row>
          )}
        </DragDropContext>
      </div>
    </div>
  );
};

export default DragAndDrop;
