import FormGenerator from 'components/Forms/FormGenerator';
import { formType } from 'components/Forms/forms';
import CustomNavLink from 'components/Forms/inputs/Navlink';
import { Button } from 'reactstrap';
// import { Button, FormGroup, Label, Input } from 'reactstrap';

const KnowEachOther = ({ data, onChange, nextStep, prevStep }) => {

    const handleChange = (field, value) => {        
        onChange({target: {name: field, value: value}});
    }
    const form = () => {
        const firstName = {
            field: "firstName",
            title: "Votre prénom",
            formType: formType.text,
            isRequired: true,
            isReadOnly: false,
        };

        let lastName = {
            field: "lastName",
            title: "Votre nom",
            formType: formType.text,
            isRequired: false,
            isReadOnly: false,
        };

        let phoneNumber = {
            field: "phoneNumber",
            title: "Votre numéro de téléphone",
            formType: formType.text,
            isRequired: false,
            isReadOnly: false,
        };

        const form = {
            rows: [
                {
                    columns: [{ size: 6, render: { ...firstName } }, { size: 6, render: { ...lastName } }],
                },
                {
                    columns: [{ size: 12, render: { ...phoneNumber } }],
                },
            ],
        };

        return form;
    };

    return (
        <>
            <FormGenerator
                form={form}
                data={data}
                index={-1}
                handleChange={handleChange}
            />
            <div className="text-center">
                <CustomNavLink
                    title={"Précèdent"}
                    className="align-middle"
                    action={prevStep}
                    to={"#"}
                />
                <Button
                    className="mt-3 ml-3"
                    color="primary"
                    onClick={nextStep}
                >
                    Continuer
                </Button>
            </div>
        </>
    )
}

export default KnowEachOther;