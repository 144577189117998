import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import search from "../../../../assets/img/welcome/CarrouselConstruction/trouver_un_pro_BTPGO.png";
import socialNetwork from "../../../../assets/img/welcome/CarrouselConstruction/Reseau_sociaux_BTPGO.png";
import tools from "../../../../assets/img/welcome/CarrouselConstruction/creation_site_BTPGO.png";
import quotesImage from "../../../../assets/img/welcome/CarrouselConstruction/Quotes_BTPGO.png";
import billingImage from "../../../../assets/img/welcome/CarrouselConstruction/facure_BTPGO.png";
import roleSettingsImage from "../../../../assets/img/welcome/CarrouselConstruction/gestion_des_roles_BTPGO.png";
import Cards from "../../../Ui/Cards";
import "../../../Ui/fond.css";
import { TitleWelcome } from "../../common.js";
import Buttons from "../../../Ui/Button";

const Services = () => {
  useEffect(() => {
    Aos.init({
      duration: 3000,
    });
  }, []);

  const dataPrincipal = [
    {
      image: search,
      title: "Trouver un pro",
      text: `BtpGo vous aide à être mis en relation avec des professionnels qui ont été évalués et notés par d'autres utilisateurs.`,
      lien: "En savoir plus ...",
    },
    {
      image: socialNetwork,
      title: "Réseau sociaux",
      text: `BtpGO est également un réseau social qui vous permettra d'obtenir plus de visibilité ou de suivre vos artisans préférés et de voir ce quils font.`,
      lien: "En savoir plus ...",
    },
    {
      image: tools,
      title: "Création de site",
      text: `Notre outil de génération de site automatisé permet à nos utilisateurs de créer leur site vitrine simplement et en quelques clics.`,
      lien: "En savoir plus ...",
    },
  ];

  const dataSecondary = [
    {
      image: quotesImage,
      title: "Devis",
      text: `BtpGo vous aide à être le pro le plus organisé qu’il soit, en mettant à votre dispositions des outils de devis ultra facile et clair.`,
      lien: "En savoir plus ...",
    },
    {
      image: billingImage,
      title: "Facture",
      text: `La facture est disponible chez BTPGO comme bon nombre d’autres fonctionnalité que pour notre utilisateurs préférés.`,
      lien: "En savoir plus ...",
    },
    {
      image: roleSettingsImage,
      title: "Gestion des rôles",
      text: `BtpGo vous permet de cadrer au mieux les accès que vous donnez à chacun afin de de toujours plus maitriser et optimiser les process.`,
      lien: "En savoir plus ...",
    },
  ];
  return (
    <div className="container-fluid bgDefault" id="services">
      <div data-aos="fade-up">
        <div className="col-md-12 d-flex justify-content-center text-center">
          <TitleWelcome>
            Ce que nous apportons
          </TitleWelcome>
        </div>
        <Cards data={dataPrincipal} />
        <Cards data={dataSecondary} />
        <div className="mt-3 d-flex justify-content-center" >
          <Buttons linkTo={process.env.REACT_APP_WELCOME_PAGE_PATH} buttonText="Inscrivez-vous c'est gratuit" />
        </div>
      </div>
    </div>
  );
};

export default Services;
