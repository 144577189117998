import React, { Component } from "react";
// import moment from 'moment';
import "./Timer.css";
import BoxTime from "./BoxTime";

class CountDown extends Component {
  constructor(props) {
    super(props);
    this.count = this.count.bind(this);
    this.state = {
      days: 0,
      minutes: 0,
      hours: 0,
      secounds: 0,
      time_up: "",
    };
    this.x = null;
    this.deadline = null;
  }
  count() {
    var now = new Date().getTime();
    var t = this.deadline - now;
    var dd = Math.floor(t / (1000 * 60 * 60 * 24));
    var hh = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var mm = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
    var ss = Math.floor((t % (1000 * 60)) / 1000);

    var days = dd < 10 ? "0" + dd : dd;
    var hours = hh < 10 ? "0" + hh : hh;
    var minutes = mm < 10 ? "0" + mm : mm;
    var seconds = ss < 10 ? "0" + ss : ss;

    this.setState({ days, minutes, hours, seconds });

    if (t < 0) {
      clearInterval(this.x);
      this.setState({
        days: 0,
        minutes: 0,
        hours: 0,
        seconds: 0,
        time_up: "TIME IS UP",
      });
    }
  }
  componentDidMount() {
    this.deadline = new Date("May 01, 2024 21:00:00").getTime();

    this.x = setInterval(this.count, 1000);
  }

  render() {
    const { days, seconds, hours, minutes } = this.state;
    return (
      <div id="countdown">
        <BoxTime key={"countDownDays"} id="day" unitText="Jours" value={days} />
        <BoxTime
          key={"countDownHours"}
          id="hour"
          unitText="Heures"
          value={hours}
        />
        <BoxTime
          key={"countDownMinutes"}
          id="minute"
          unitText="Minutes"
          value={minutes}
        />
        <BoxTime
          key={"countDownSeconds"}
          id="second"
          withBorder={false}
          unitText="Secondes"
          value={seconds}
        />
      </div>
    );
  }
}

export default CountDown;
