import React from "react";
import Intro from "../components/Welcome/Principal/intro/Intro.js";
import Services from "../components/Welcome/Principal/services/Services.js";
import Footer from "../components/Footers/Footer.js";
import Offre from "../components/Welcome/Principal/offre/Offre.js";
import Contact from "../components/Welcome/Principal/contact/Contact.js";
import Problematique from "../components/Welcome/Principal/problematique/Problematique.js";
import About from "../components/Welcome/Principal/About/About.js";
import Partenaires from "../components/Ui/Partenaires.js";

const Welcome = () => {
  return (
    <>
      <Intro />
      <Problematique />
      <Services />
      <About />
      <Offre />
      <Partenaires />
      <Contact />
      <Footer />
    </>
  );
};

export default Welcome;
