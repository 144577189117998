// import { createCanBoundTo } from "@casl/react";
import { AbilityBuilder, createMongoAbility } from "@casl/ability";
import * as subjects from "../constants/subjects";
import { defineRulesForBilling } from "./features/billingAuth";
import * as permissions from "./constants/permissions";
import { deepMergeObjectProperties } from "../../utils/converter";
import { defineRulesForAccessRoles } from "./features/accessAuth";
export * from "./features/billingAuth";

const ability = new createMongoAbility([]);

export const defineRulesFor = (permissions) => {
  const { can, rules } = new AbilityBuilder();
  defineRulesForBilling(can, permissions);
  defineRulesForAccessRoles(can, permissions);
  return ability.update(rules);
};



export const getAuthorizedMenu = (userPermissions) => {
  let authorizedMenu = [];
  if (userPermissions.includes(permissions.BTPGO_ADMIN) || userPermissions.includes(permissions.ALL)) {
    authorizedMenu.push(subjects.ALL);
    return Array.from(new Set(authorizedMenu.map((item) => item)));
  }

  if ([permissions.MY_ACCOUNT_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedMenu.push({
      id: subjects.ADMINISTRATION,
      childrens: [
        { id: subjects.MY_ACCOUNT }
      ],
    });

  if ([permissions.ROLE_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedMenu.push({
      id: subjects.ADMINISTRATION,
      childrens: [
        { id: subjects.ROLES }
      ],
    });

  if ([permissions.USER_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedMenu.push({
      id: subjects.ADMINISTRATION,
      childrens: [
        { id: subjects.USERS }],
    });

  if ([permissions.PERSONAL_INFO_MENU, permissions.PROFESSIONAL_INFO_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedMenu.push({
      id: subjects.PROFILE,
    });

  if ([permissions.SEARCH_PROFESSIONAL_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedMenu.push({
      id: subjects.SEARCH,
    });

  if ([permissions.SOCIAL_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedMenu.push({
      id: subjects.SOCIAL,
    });

  if ([permissions.BILLING_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedMenu.push({
      id: subjects.BILLING
    });

  if ([permissions.DEVIS_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedMenu.push({
      id: subjects.DEVIS
    });

  if ([permissions.PERSONAL_WEBSITE_MENU].some((perm) => userPermissions.includes(perm)))
    authorizedMenu.push({
      id: subjects.CREATE_SITE
    });

  let output = [];
  deepMergeObjectProperties(authorizedMenu, output, "id");
  return Array.from(new Set(output.map((item) => item)));
};


// export const Can = createCanBoundTo(ability);