export const cookiesPolicyData = [
  {
    titre: "Introduction",
    text: ` La présente politique relative aux cookies explique ce que sont les cookies, comment nous les utilisons sur le site BTPGo et les choix qui s'offrent à vous concernant leur utilisation.
      En accédant à notre site internet, vous consentez à l'utilisation de cookies conformément à cette politique, sauf si vous choisissez de les désactiver via les paramètres de votre navigateur ou nos outils.`,
  },
  {
    titre: "Qu'est-ce qu'un cookie ?",
    text: `Un cookie est un petit fichier texte placé sur votre appareil (ordinateur, tablette, smartphone) par un site web que vous visitez. Il permet au site de se souvenir de vos actions et préférences (comme vos identifiants de connexion, la langue, ou d'autres préférences) sur une période donnée.`
  },
  {
    titre: "Types de cookies utilisés",
    text: `
    <u>1. Cookies strictement nécessaires</u>
    <p>Ces cookies sont indispensables pour le fonctionnement du site. Ils vous permettent de naviguer sur le site et d'utiliser ses fonctionnalités essentielles.</p>
    <u>2. Cookies de performance</u>
    <p>Ces cookies collectent des informations sur la manière dont les visiteurs utilisent notre site, comme les pages les plus consultées ou les messages d'erreur. Ces données sont utilisées pour améliorer la performance du site.</p>
    <u>3. Cookies de fonctionnalité</u>
    <p>Ces cookies permettent au site de se souvenir des choix que vous faites (comme votre nom d'utilisateur, votre langue ou votre région) et de fournir des fonctionnalités améliorées.</p>
    <u>4. Cookies publicitaires</u>
    <p>Ces cookies sont utilisés pour diffuser des publicités pertinentes pour vous et adaptées à vos intérêts.</p>
    <u>5. Cookies tiers</u>
    <p>Certains cookies peuvent être placés par des tiers (comme Google Analytics ou les réseaux sociaux) pour collecter des informations anonymes sur les visiteurs.</p>
    `,
  },  
  {
    titre: "Comment gérer vos préférences en matière de cookies",
    text: `<p>Vous avez le choix d'accepter ou de refuser les cookies. Vous pouvez configurer votre navigateur pour accepter ou rejeter automatiquement les cookies, ou être informé lorsqu'un cookie est envoyé à votre appareil.</p>
    `,
  },
  {
    titre: "Instructions pour désactiver les cookies",
    text: `<ul>
    <li> Google Chrome : Allez dans "Paramètres" > "Confidentialité et sécurité" > "Cookies et autres données de site".</li>
    <li>Mozilla Firefox : Allez dans "Options" > "Vie privée et sécurité" > "Cookies et données de sites".</li>
    <li>Safari : Allez dans "Préférences" > "Confidentialité" > "Gérer les cookies".</li>
    <li>Microsoft Edge : Allez dans "Paramètres" > "Cookies et autorisations de site".</li>
    </ul>
    <p>Pour plus d'informations, consultez la documentation de votre navigateur.</p>`,
  },
  {
    titre: "Modifications de la politique relative aux cookies",
    text: `Nous nous réservons le droit de modifier cette politique à tout moment. Toute modification sera publiée sur cette page avec une date de mise à jour.`,
  },
  {
    titre: "Contact",
    text: `<p>Si vous avez des questions concernant cette politique relative aux cookies, veuillez nous contacter :</p>
   <ul><li>Par mail à contact.btpgo@gmail.com</li></ul>
Date de mise à jour : 22/12/2024
    `,
  }
];
