import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const DeleteIconButtonWithModal = ({ className, deleteAction, modalTitle, modalText, deleteButtonText, showButtonForDelete = false }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <span className={className}>
      {showButtonForDelete &&
        <Button
          className="my-3 btn btn-secondary"
          onClick={() => { setShowModal(true) }}>
          {deleteButtonText ? deleteButtonText : "Supprimer"}
        </Button>}

      {!showButtonForDelete && <FontAwesomeIcon
        title={"Supprimer"}
        icon={faTrash}
        color="gray"
        onClick={() => {
          setShowModal(true);
        }}
      />
      }

      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader>
          {modalTitle}
        </ModalHeader>
        <ModalBody>
          <p>{modalText}</p>
        </ModalBody>
        <ModalFooter>
          <Button className="my-3 btn btn-primary" onClick={() => setShowModal(false)}>
            Annuler
          </Button>{" "}
          <Button className="my-3 btn btn-danger" onClick={() => { deleteAction(); setShowModal(false) }}>
            Supprimer
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </span>
  );
};

export default DeleteIconButtonWithModal;
