import React, { useState } from "react";
import BlogIntro from "../components/Blog/BlogIntro";
import AddBlog from "../components/Blog/AddBlog";
import { userService } from "../utils/_services/user.service.js";
import GenericHeader from "../components/Headers/GenericHeader";

const Blog = () => {
  const userIsConnected = useState(userService.isLoggedIn());
  return (
    <>
      {userIsConnected ? <GenericHeader /> : <BlogIntro />}
      <AddBlog />      
    </>
  );
};

export default Blog;
