import React from "react";
import { formType } from "../../../components/Forms/forms";
import { getDate, getDateTime } from "../../../utils/date.js";
import Switch from "../../../components/Forms/inputs/Switch";

export const email = {
  field: "mail",
  title: "Email",
  formType: formType.text,
  isRequired: true,
  isReadOnly: false,
};

export const job = {
  field: "job.title",
  title: "Métier",
  formType: formType.text,
  isRequired: true,
  isReadOnly: false,
};


// export const gender = {
//   field: "gender",
//   title: "Gender",
//   formType: formType.choice,
//   lookup: profileGenderLookup(),
//   isRequired: true,
//   isReadOnly: false,
// };

export const firstName = {
  field: "firstName",
  title: "Prénom",
  formType: formType.text,
  isRequired: true,
  isReadOnly: false,
  placeholder: "Prénom",
};

export const lastName = {
  field: "lastName",
  title: "Nom",
  formType: formType.text,
  isRequired: true,
  isReadOnly: false,
  placeholder: "Nom",
};

export const city = {
  field: "commune.name",
  title: "Ville",
  formType: formType.text,
  isRequired: false,
  isReadOnly: false,
};

export const roles = {
  field: "roles",
  title: "Rôles",
  formType: formType.text,
  isRequired: false,
  isReadOnly: false,
  render: (rowData) => rowData.roles?.join(' - ')
};

export const startDate = {
  field: "startDate",
  title: "Date de début",
  formType: formType.date,
  isRequired: false,
  isReadOnly: false,
  render: (rowData) => getDate(rowData.startDate)
};

export const lastConnectionDate = {
  field: "lastConnectionDate",
  title: "Dernière connexion",
  formType: formType.text,
  isRequired: false,
  isReadOnly: false,
  placeholder: "Last Connextion Date",
  render: (rowData) => getDateTime(rowData.lastConnectionDate)
};

export const switchFieldName = "switchElementForCatAvailability";
export const switchisFreeFieldName = "switchElementForCatIsFree";
const profileStatusValidated = "Validated";
const profileStatusNotValidated = "PendingValidation";
export const profileStatus = (onChangeClick) => ({
  title: "Validé ?",
  field: "status",
  formType: formType.switch,
  isInline: true,
  width: "8%",
  lookup: { [profileStatusValidated]: "Validé", [profileStatusNotValidated]: "Pas validé" },
  // customFilterAndSearch: (filterValue, rowData) => {
  //   return filterValue.length === 0 ? true :  rowData.profileStatus === parseInt( filterValue[filterValue.length -1], 10);
  // },

  render: (rowData) => (
    <div
      style={{
        paddingLeft: "20%",
        paddingTop: "15%",
      }}
    >
      <Switch
        field={switchFieldName + "-" + rowData.id}
        title={""}
        value={rowData.status === profileStatusValidated}
        isRequired={false}
        isReadOnly={false}
        handleChange={onChangeClick ? (field, value) => {
          onChangeClick("status", rowData.id, value)
        } : () => { }
        }
        placeholder={rowData.status=== profileStatusValidated ? "Validé" : "Pas validé" }
      />
    </div>
  ),
});

export const showInSearch = (onChangeClick) => ({
  title: "Affiché dans la recherche",
  field: "showInSearch",
  formType: formType.switch,
  isInline: true,
  width: "8%",
  lookup: { [true]: "Oui", [false]: "Non" },
  render: (rowData) => (
    <div
      style={{
        paddingLeft: "20%",
        paddingTop: "15%",
      }}
    >
      <Switch
        field={switchisFreeFieldName + "-" + rowData.id}
        title={""}
        value={rowData.showInSearch}
        isRequired={false}
        isReadOnly={false}
        handleChange={onChangeClick ? (field, value) => {
          onChangeClick("showInSearch", rowData.id, value)
        } : () => { }
        }
      />
    </div>
  ),
});

export const isFree = (onChangeClick) => ({
  title: "Free trial",
  field: "isFree",
  formType: formType.switch,
  isInline: true,
  width: "8%",
  lookup: { [true]: "Yes", [false]: "No" },
  render: (rowData) => (
    <div
      style={{
        paddingLeft: "20%",
        paddingTop: "15%",
      }}
    >
      <Switch
        field={switchisFreeFieldName + "-" + rowData.id}
        title={""}
        value={rowData.isFree}
        isRequired={false}
        isReadOnly={false}
        handleChange={onChangeClick ? (field, value) => {
          onChangeClick("isFree", rowData.id, value)
        } : () => { }
        }
      />
    </div>
  ),
});

export const freePeriod = ({
  field: "freePeriod",
  title: "Free Period",
  formType: formType.numeric,
  isRequired: true,
  isReadOnly: false,
});

export const save = ({
  title: "Save",
  formType: formType.submit,
});

export const userForm = (onChangeClick) => {
  return {
    rows: [
      {
        columns: [
          { size: 6, render: { ...isFree(onChangeClick), dirty: true } },
          { size: 6, render: { ...freePeriod } },
        ],
      }
    ],
    footer: [{ render: save }],
  };
};
