import { ArrowForward } from "../../../components/Icons/icons";

const AccountAction = ({ id, title, onClick }) => {
    return (
        <div
            className="align-middle d-flex align-items-center justify-content-between cursor-pointer account-action rounded-sm p-3"
            onClick={() => { onClick(id) }}
        >
            <span>
                {title}
            </span>
            <span className="float-right">
                <ArrowForward />
            </span>
        </div>)
};

export default AccountAction; 