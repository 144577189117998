import { reactionType } from "../../utils/constants";
import ClapEmoji from '../../assets/img/social/clap-emoji.png';
import LikeEmoji from '../../assets/img/social/like-emoji.png';
import LikeEmojiCircle from '../../assets/img/social/like-emoji-circle.png';
import HeartEmojiCircle from '../../assets/img/social/heart-emoji-circle.png';
import HeartEmoji from '../../assets/img/social/heart-emoji.png';
import SmileEmoji from '../../assets/img/social/smile-emoji.png';
import CongratulationEmojiCircle from '../../assets/img/social/congratulation-emoji-circle.png';

export const getReactionImageBytype = (userReaction) => {
    switch (userReaction) {
        case "like":
            return LikeEmoji;
        case "heart":
            return HeartEmoji;
        case "congratulation":
            return ClapEmoji;
        case "smile":
            return SmileEmoji;
        default:
            break;
    }
}

export const getReactionImageCircleBytype = (userReaction) => {
    switch (reactionType[userReaction]) {
        case "like":
            return LikeEmojiCircle;
        case "heart":
            return HeartEmojiCircle;
        case "congratulation":
            return CongratulationEmojiCircle;
        case "smile":
            return SmileEmoji;
        default:
            break;
    }
}