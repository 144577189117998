
import LinkNavbar from "./LinkNavbar";

const Rubrique = () => {
  return (
    <LinkNavbar />
  );
};

export default Rubrique;
