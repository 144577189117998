import CompanyCard from "./companyCard";
import { Button } from 'reactstrap';
import CustomNavLink from "components/Forms/inputs/Navlink";

const FoundedCompany = ({ data, handleRemoveCompany, nextStep }) => {
    return (<div >
        <h6 className="text-center text-success">✔️ Parfait, voici les informations de votre entreprise.</h6>
        <CompanyCard
            company={data.company}
            message={"🛈 Vous pouvez modifier ces informations ultérieurement."} />
        <div className="text-right">
            <CustomNavLink
                title={<span className="text-muted">⇄ Change d'entreprise</span>}
                action={handleRemoveCompany}
                to={"#"}
            />
        </div>
        <div className="text-center">
            <Button
                className="mt-3"
                color="primary"
                onClick={nextStep}
            >
                Continuer
            </Button>
        </div>
    
    </div>);
}

export default FoundedCompany;