import { Container } from "reactstrap";
import "../../assets/plugins/aileron/css/aileron.css";

const UserHeader = ({ currentUser }) => {
  return (
    <>
      <div
        className="header d-flex align-items-center"
        style={{
          background:
            "linear-gradient(90deg,  rgba(179, 216, 232, 1) 0%,  rgba(39, 166, 221, 1) 100%)",
        }}
      >
        <Container className="px-3 py-2">
          <h3 style={{ color: "#3153b0", fontFamily: "'Aileron', sans-serif", fontWeight: "bold" }}>
            {" "}
            Bienvenue {currentUser.firstName}
          </h3>
          <p className="text-white" style={{ fontFamily: 'Roboto Condensed', fontWeight: 400 }}>
            Découvrez enfin le travail divertissant grâce à BTPGO <br />
            Evoluez et faites de votre aventure BtpGO la preuve de votre
            réussite professionnelle
          </p>         
        </Container>
      </div>
    </>
  );
};

export default UserHeader;
