import { reactionText } from "../../../utils/constants";
import { getReactionImageCircleBytype } from "../common";
import { useEffect, useState } from "react";

export function PostReactionsCountComponent({ reactions, onReactionClick }) {
    const [uniqueReactions, setUniqueReactions] = useState([]);

    useEffect(() => {
        setUniqueReactions([...new Set(reactions.map(r => r.reactionType))]);
    }, [reactions]);

    return (
        <div>
            {uniqueReactions.length > 0 ? (
                <p className="m-0 fit-content underline cursor-pointer" onClick={onReactionClick}>
                    { uniqueReactions.map(reaction =>
                        <img
                            src={getReactionImageCircleBytype(reaction)}
                            alt={reactionText[reaction]}
                            style={{
                                width: reaction === 1 ? "28px" : "17px",
                                height: reaction === 1 ? "28px" : "17px",
                                marginRight: reaction === 1 ? "-4px" : "1px",
                                marginLeft: reaction === 1 ? "-4px" : "0px"
                            }} />)}
                    <u>
                        <span style={{ fontSize: "12px", marginLeft: "5px" }}>{reactions.length}</span>
                    </u>
                </p>
            ) : <p className="pl-2 pt-1 pb-0  small">Soyez le premier à réagir !</p>}
        </div>
    );
}