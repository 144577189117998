export const features = [
    {
        title: "Annuiaire Pro",
        features: [
            {
                name: "Accès à l'annuaire des professionnels",
                free: {
                    checked: false,
                    comment: "Limité (10 profils/mois)"
                },
                basic: {
                    checked: true,
                    comment: "Illimité"
                },
                premium: {
                    checked: true,
                    comment: "Illimité"
                },
                entreprise: {
                    checked: true,
                    comment: "Illimité"
                },
            },
            {
                name: "Sans Publicités",
                free: {
                    checked: false,
                    comment: "Obligatoires (6 à 15 sec)"
                },
                basic: {
                    checked: true,
                },
                premium: {
                    checked: true,
                },
                entreprise: {
                    checked: true,
                },
            },
            {
                name: "Apparance dans l'annuaire",
                free: {
                    checked: false,
                    comment: "Limité (2 recherches/jour)"
                },
                basic: {
                    checked: false,
                    comment: "Limité (5 recherches/jour)"
                },
                premium: {
                    checked: true,
                    comment: "Illimité"
                },
                entreprise: {
                    checked: true,
                    comment: "Illimité"
                },
            },
        ],
    },
    {
        title: "Devis et Factures",
        features: [
            {
                name: "Création de devis",
                free: {
                    checked: false,
                },
                basic: {
                    checked: true,
                    comment: "Basique"
                },
                premium: {
                    checked: true,
                    comment: "Avancée (presonalisable)"
                },
                entreprise: {
                    checked: true,
                    comment: "Avancée (presonalisable)"
                },
            },
            {
                name: "Création de factures",
                free: {
                    checked: false,
                },
                basic: {
                    checked: true,
                    comment: "Basique"
                },
                premium: {
                    checked: true,
                    comment: "Avancée (presonalisable)"
                },
                entreprise: {
                    checked: true,
                    comment: "Avancée (presonalisable)"
                },
            },
            {
                name: "Devis et Factures illimités",
                free: {
                    checked: false,
                    comment: "Limité (1/jour)"
                },
                basic: {
                    checked: false,
                    comment: "Limité (3/jour)"
                },
                premium: {
                    checked: true,
                },
                entreprise: {
                    checked: true,
                },
            },
            // {
            //     name: "Facturation automatique",
            //     free: {
            //         checked: false,
            //     },
            //     basic: {
            //         checked: false,
            //     },                
            //     premium: {
            //         checked: true,
            //     },
            //     entreprise: {
            //         checked: true,
            //     },
            // },
        ],
    },
    {
        title: "Gestion de projet",
        features: [
            {
                name: "Tableau de tâches",
                free: {
                    checked: false,
                },
                basic: {
                    checked: false,
                },
                premium: {
                    checked: true,
                },
                entreprise: {
                    checked: true,
                },
            },
            {
                name: "Gestion de projets",
                free: {
                    checked: false,
                },
                basic: {
                    checked: false,
                },
                premium: {
                    checked: true,
                },
                entreprise: {
                    checked: true,
                },
            },
            {
                name: "Collaboration et partage de projets",
                free: {
                    checked: false,
                },
                basic: {
                    checked: false,
                },
                premium: {
                    checked: true,
                },
                entreprise: {
                    checked: true,
                },
            },
            {
                name: "Analyse des coûts et rentabilité",
                free: {
                    checked: false,
                },
                basic: {
                    checked: false,
                },
                premium: {
                    checked: true,
                    comment: "Fonctionnalité future"
                },
                entreprise: {
                    checked: true,
                },
            },
            {
                name: "Gestion des contrats et documents légaux",
                free: {
                    checked: false,
                },
                basic: {
                    checked: false,
                },
                premium: {
                    checked: false,
                },
                entreprise: {
                    checked: true,
                },
            },
        ],
    },
    {
        title: "Tableau de bord",
        features: [
            {
                name: "Tableau de bord des performances",
                free: {
                    checked: false,
                },
                basic: {
                    checked: false,
                },
                premium: {
                    checked: true,
                    comment: "Graphiques interactifs"
                },
                entreprise: {
                    checked: true,
                    comment: "Presonalisation avancée"
                },
            },
            {
                name: "Personnalisation du tableau de bord",
                free: {
                    checked: false,
                },
                basic: {
                    checked: false,
                },
                premium: {
                    checked: false,
                },
                entreprise: {
                    checked: true,
                },
            },
        ],
    },
    {
        title: "Réseau social",
        features: [
            {
                name: "Connexions ou demandes d'amis illimitées",
                free: {
                    checked: false,
                    comment: "Limité (5/mois)"
                },
                basic: {
                    checked: false,
                    comment: "limité (20/mois)"
                },
                premium: {
                    checked: false,
                    comment: "limité (50/mois)"
                },
                entreprise: {
                    checked: true,
                    comment: "Illimité"
                },
            },
            {
                name: "Création de post en illimitées",
                free: {
                    checked: false,
                    comment: "Limité (5/mois)"
                },
                basic: {
                    checked: false,
                    comment: "limité (10/mois)"
                },
                premium: {
                    checked: false,
                    comment: "limité (20/mois)"
                },
                entreprise: {
                    checked: true,
                    comment: "Illimité"
                },
            },
        ],
    },
    // {
    //     title: t("Dashboard"),
    //     features: [
    //         t("Track your activities with a detailed overview"),
    //         t("Financial Snapshot"),
    //         t("Analyze your performance for continuous improvement"),
    //         t("Revenue & Expenses Overview"),
    //         t("Work Time & Task Distribution"),
    //         t("Upcoming Events & Reminders"),
    //         t("Recent Prospections"),
    //     ],
    //     description: t("Get a complete view of your activities and progress"),
    // },
    // {
    //     title: t("Task Board"),
    //     features: [
    //         t("Visualize your tasks with Kanban boards"),
    //         t("Add flexibility with customizable Columns, ticket Tagging & Prioritization"),
    //         t("Track time and manage task estimates effortlessly"),
    //         t("Task Statistics")
    //     ],
    //     description: t("Organize, prioritize, and track tasks effectively"),
    // },
    // {
    //     title: t("Calendar"),
    //     features: [
    //         t("Stay organized with an intuitive calendar view"),
    //         t("Create and manage single or recurring events"),
    //         t("Set reminders to never miss important dates"),
    //     ],
    //     description: t("Plan your schedule and stay on top of your commitments"),
    // },
    // {
    //     title: t("Time Sheets"),
    //     features: [
    //         t("Project/Task Tracking"),
    //         t("Overview of worked hours on a daily or monthly basis"),
    //         t("Manage time off, sick leave, or vacation"),
    //         t("Billable vs Non-Billable days"),
    //         t("Reporting and Analytics")
    //     ],
    //     description: t("Monitor time spent and boost efficiency"),
    // },
    // {
    //     title: t("Billing"),
    //     features: [
    //         t("Create unlimited invoices effortlessly"),
    //         t("Stay compliant with e-invoicing regulations"),
    //         t("Personalize invoices to match your brand"),
    //         t("Generate and send invoices in just a few clicks"),
    //         t("Automate reminders for overdue payments"),
    //         t("Electronic signatures for documents"),
    //         t("Track client contracts seamlessly"),
    //         t("Customize client-specific emails easily"),
    //         t("Add unlimited client contacts"),
    //         t("Synchronize appointments with built-in reminders"),
    //     ],
    //     description: t("Simplify your invoicing and payment processes"),
    // },
    // {
    //     title: t("Treasury"),
    //     features: [
    //         t("Manually manage transactions with ease"),
    //         t("Track revenue based on payments received"),
    //         t("Monitor collected and deductible VAT"),
    //         t("Automate expense entries for accuracy"),
    //         t("Categorize expenses to manage budgets effectively"),
    //         t("Schedule and automate recurring payments"),
    //     ],
    //     description: t("Optimize financial management and cash flow"),
    // },
    // {
    //     title: t("CRM"),
    //     features: [
    //         t("Maintain and generate your CV in PDF easily"),
    //         t("Manage company profiles and client data effectively"),
    //         t("Maintain a unified database for all your contacts"),
    //         t("Track and manage offers received from clients effortlessly"),
    //     ],
    //     description: t("Boost client relationships and recruitment processes"),
    // },
];