export const checkValidity = (inputs, item) => {
    // inputs.forEach((input) => {
    //   switch (input.formType) {
    //     case formType.email:
    //       if (
    //         (input.isRequired && !item[input.field]) ||
    //         !isValidEmail(item[input.field])
    //       )
    //         throw new Error("not valid mail");
    //       break;

    //     case formType.phone:
    //       if (
    //         (input.isRequired && !item[input.field]) ||
    //         (item[input.field] && !isValidPhone(item[input.field]))
    //       )
    //         throw new Error("not valid phone");
    //       break;

    //     case formType.iban:
    //       if (
    //         (input.isRequired && !item[input.field]) ||
    //         (item[input.field] && !isValidIban(item[input.field]))
    //       )
    //         throw new Error("not valid iban");
    //       break;

    //     case formType.datetime:
    //       if (
    //         (input.isRequired && !item[input.field]) ||
    //         !isValidDateTime(item[input.field], input.minDate)
    //       )
    //         throw new Error("not valid datetime");
    //       break;

    //     case formType.date:
    //       if (
    //         (input.isRequired && !item[input.field]) ||
    //         !isValidDate(item[input.field], input.minDate)
    //       )
    //         throw new Error("not valid date");
    //       break;

    //     case formType.time:
    //       if (
    //         (input.isRequired && !item[input.field]) ||
    //         !isValidTime(item[input.field], input.minDate)
    //       )
    //         throw new Error("not valid time");
    //       break;

    //     case formType.numeric:
    //       if (input.isRequired && parseInt(item[input.field]) <= 0)
    //         throw new Error("not valid numeric");
    //       break;

    //     case formType.multiChoice:
    //       if (
    //         input.isRequired &&
    //         (!item[input.field] || item[input.field].length === 0)
    //       )
    //         throw new Error("not valid multiChoice");
    //       break;
    //     case formType.multiSelect:
    //       if (
    //         input.isRequired &&
    //         (!item[input.field] || item[input.field].length === 0)
    //       )
    //         throw new Error("not valid multiSelect");
    //       break;
    //     case formType.multiSelectText:
    //       if (
    //         (input.isRequired &&
    //           (!item[input.field] || item[input.field].length === 0)) || (input.textValidatorFunc && validateArray(item[input.field], input.textValidatorFunc).length > 0)
    //       )
    //         throw new Error("not valid multiSelectText");
    //       break;
    //     default:
    //       if (
    //         input.isRequired &&
    //         !item[input.field] &&
    //         ![formType.switch, formType.checkbox].includes(input.formType)
    //       )
    //         throw new Error("not valid other");
    //   }
    // });
    return true;  
};

export const capitalizeWords = (str) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const validateArray = (array, validator) => {
  const errorValue = [];
  if (!array || array.length === 0) return errorValue;
  for (let i = 0; i < array.length; i++) {
    if (!validator(array[i])) {
      errorValue.push(array[i]);
    }
  }
  return errorValue;
};

export const contains = "contains",
  equal = "=",
  notEqual = "<>",
  upper = ">",
  upperOrEqual = ">=",
  lower = "<",
  lowerOrEqual = "<=",
  strictBetween = "strictBetween",
  between = "between";
