import { Button, FormGroup, Label, Input, Alert } from 'reactstrap';
import CompanyCard from "./companyCard";
import { SearchIcon } from "../../components/Icons/icons";
import CustomNavLink from '../../components/Forms/inputs/Navlink';

const SearchCompany = ({ data, onChange, searchedCompanies, onSearchCompanyClick, searchMessage, onCompanyClick, previousStep, nextStep }) => {
    return (
        <>
            <FormGroup>
                <Label for="siret">Quel est le nom ou le siret/siren de votre entreprise ?</Label>
                <div className="d-flex w-100">
                    <Input type="text"
                        id="searchCompanyParam"
                        name="searchCompanyParam"
                        value={data.siret}
                        onChange={onChange}
                        placeholder="Nom, Siret ou Siren.."
                        className="flex-grow-1 mr-2"
                    />
                    <Button
                        color="primary"
                        onClick={onSearchCompanyClick}
                        className="d-flex align-items-center justify-content-center "
                    >
                        <SearchIcon />
                    </Button>
                </div>
            </FormGroup>
            {searchedCompanies.length > 1 && (
                <h6>Plusieurs entreprises trouvées, choisisez-en une:</h6>
            )}
            {searchMessage && <h6 className="text-danger my-3">{searchMessage}</h6>}
            {searchedCompanies.length > 1 && (
                <div className="my-3" style={{ maxHeight: '380px', overflow: 'auto' }}>
                    {searchedCompanies.map((c) => (
                        <CompanyCard company={c} onCompanyClick={onCompanyClick} />
                    ))}
                </div>
            )}

            <Alert color="success" className='text-muted'>
                💡<strong>Gagnez du temps:</strong> en recherchant votre entreprise, votre profile sera automatiquement configuré avec vos informations et 100% conformes !
            </Alert>
            <div className="d-flex justify-content-between">
                <CustomNavLink
                    title={<span style={{
                        color: '#007bff',
                        textDecoration: 'underline'
                    }}>Revenir à l'étape précédente</span>}
                    action={previousStep}
                    to={"#"}
                />
                <CustomNavLink
                    title={<span style={{
                        color: '#007bff',
                        textDecoration: 'underline'
                    }}>Je n'ai pas encore d'entreprise</span>}
                    action={nextStep}
                    to={"#"}
                />

            </div>
        </>
    )

};

export default SearchCompany;