import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import prospection from "../../../../assets/img/welcome/illustration_3.png";
import valorisation from "../../../../assets/img/welcome/efficacity.png";
import digitalisation from "../../../../assets/img/welcome/partnerWork.png";
import "./problematique.css";
import Cards from "../../../Ui/Cards";
import "../../../Ui/fond.css";
import { TitleWelcome } from "../../common.js";
import Buttons from "../../../Ui/Button";

const data = [
  {
    image: prospection,
    title: "Gain de Temps",
    text: `Accédez rapidement à un réseau de professionnels qualifiés.`,
    // lien: "En savoir plus ...",
  },
  {
    image: valorisation,
    title: "Efficacité",
    text: `Des mises en relation simples et efficaces pour vos projets BTP.`,
    // lien: "En savoir plus ...",
  },
  {
    image: digitalisation,
    title: "Maîtrise des Coûts",
    text: `Trouvez le bon partenaire au meilleur prix pour vos chantiers.`,
    // lien: "En savoir plus ...",
  },
];
const Problematique = () => {
  useEffect(() => {
    Aos.init({
      duration: 3000,
    });
  }, []);

  return (
    <div
      className="container-fluid bgDefault "
      id="problematique"
      // data-aos="fade-up"
    >
      <div className="d-flex justify-content-center text-center">
        <TitleWelcome>
          Construisez votre réseau grâce à ces outils simples et adaptés
        </TitleWelcome>
      </div>
      <Cards data={data} />
      <div className="mt-3 d-flex justify-content-center" >
        <Buttons linkTo={process.env.REACT_APP_WELCOME_PAGE_PATH} buttonText="Inscrivez-vous c'est gratuit" />
      </div>
    </div>
  );
};

export default Problematique;
