import React, { useState, useEffect } from "react";
import DragAndDrop from "../../../components/DragAndDrop/dragAndDrop";
import Choice from "../../../components/Forms/inputs/Choice";
import Label from "../../../components/Forms/inputs/Label";
import Back from "../../../components/Forms/others/Back";
import { Card, CardBody } from "reactstrap";

const UserDetail = (props) => {
  const { index, user, allroles, handleSave, handleBackClick } = props;
  const [editedUser, setEditedUser] = useState(user);
  const [roles, setRoles] = useState([]);
  const [filtersCriteria, setFiltersCriteria] = useState({
    source: "",
    destination: "",
  });

  const [state, setState] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);

  const onChange = (field, key) => {
    setRoles(allroles.filter((item) => !editedUser.roles.includes(item)));
  };

  const source = {
    id: "source",
    hasFilter: true,
    title: (
      <Label
        field={"ref"}
        title={
          <h3 style={{ "font-size": "1.2em", margin: "8.6px auto", fontWeight: "bold" }}>
            {" "}
            {editedUser ? editedUser.firstName + " " + editedUser.lastName : ""}
          </h3>
        }
      />
    ),

    items: editedUser ? editedUser.roles.sort() : [],
  };

  const destination = {
    id: "destination",
    hasFilter: true,
    title: (
      <Choice
        value={"All"}
        placeholder={"Select Menu ..."}
        lookup={{ all: "All" }}
        isRequired={false}
        isReadOnly={false}
        handleChange={onChange}
      />
    ),
    items: roles.sort(),
  };

  useEffect(() => {
    if (!hasChanged && user) {
      setEditedUser(user);

      const unusedRolesName = allroles.filter(
        (item) => !user.roles.includes(item)
      );
      setRoles(unusedRolesName);
    }
    // eslint-disable-next-line
  }, [props, hasChanged]);

  useEffect(() => {
    if (editedUser) {
      setState([source, destination]);
    }
    // eslint-disable-next-line
  }, [editedUser, roles, filtersCriteria]);

  const manageState = (newState) => {
    const newRoles = newState[0].items;
    setEditedUser({ ...editedUser, ...{ roles: newRoles } });

    setRoles(allroles.filter((item) => !newRoles.includes(item)));
  };

  const onSave = () => {
    handleSave(index, state[0].items);
    setHasChanged(false);
  };

  const onCancel = () => {
    setHasChanged(false);
  };

  return (
    <>
      <Back title={"Back to users"} handleClick={handleBackClick} />

      <Card>
        <CardBody>
          <DragAndDrop
            state={state}
            onMove={manageState}
            hasChanged={hasChanged}
            setHasChanged={setHasChanged}
            filtersCriteria={filtersCriteria}
            setFiltersCriteria={setFiltersCriteria}
            onCancel={onCancel}
            onSave={onSave}
          />
        </CardBody>
      </Card></>
  );
};

export default UserDetail;
