import React from "react";
import { FormGroup, Input, FormFeedback, Label } from "reactstrap";
import "./fond.css";

const GenerateFormInput = ({
  forInput,
  label,
  editModeClassName,
  value,
  onChange,
  name,
  placeholder,
  type,
  disabled,
  className,
  style,
  icon,
  invalid,
  invalidMessage,
  iconNextInput,
  required = false,
  field,
}) => {
  if (!value && !onChange) {
    return (
      <FormGroup className={className} style={style}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Label  htmlFor={forInput}>
            {label}
          </Label>
          {icon && (
            <div style={{ color: "rgb(111,174,222)", marginLeft: "7px" }}>
              {icon}
            </div>
          )}
        </div>
      </FormGroup>
    );
  }
  return (
    <>
      <FormGroup className={className} style={style}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Label htmlFor={forInput}>
            {label}{required && ("*")}
          </Label>
          {icon && (
            <div style={{ color: "rgb(111,174,222)", marginLeft: "7px" }}>
              {icon}
            </div>
          )}
        </div>
        <div className="d-flex flex-row">
          <Input
            className={
              editModeClassName
                ? "pointer inputGenerateBorder"
                : "not-allowed inputGenerateBorder"
            }
            value={value}
            // onChange={(e) => { 
            //   debugger;             
            //   onChange(field, e.target.value);
            // }}
            onChange={onChange}
            name={name}
            id={name}
            placeholder={placeholder}
            type={type}
            disabled={disabled}
            invalid={invalid}
            required={required}
          />
          {iconNextInput}
          <FormFeedback>{invalidMessage}</FormFeedback>
        </div>
      </FormGroup>
    </>
  );
};

export default GenerateFormInput;
