import React, { useEffect, useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { companyService } from "../../utils/_services/company.service";
import UserTypeStep from "./userTypeChoice";
import { IsReadyHeader, KnowEachOtherHeader, WelcomeHeader } from "./common";
import SearchCompanyStep from "./searchCompany";
import FoundedCompany from "./foundedCompany";
import KnowEachOther from "./knowEachOther";
import useFetchUser from "../../hooks/useFetchUser";
import { useRefreshUser } from "../../hooks/useRefreshUser";
import FonctionnalityChoice from "./fonctionnalityChoice";
import { userService } from "../../utils/_services/user.service";
import { getPatchUserObject } from "../../utils/_services/utils/userUtils";

const OnBoardingDialog = ({ handleClose }) => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState({});
  const [searchedCompanies, setSearchedCompanies] = useState([]);
  const [searchMessage, setSearchMessage] = useState("");
  const user = useFetchUser();
  const refreshUser = useRefreshUser()

  useEffect(() => {
    if (user) {
      setData({
        ...data,
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const nextStep = () => {
    if (step < 4) {
      setStep(step + 1);
    }

    console.log(data);
  };

  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const onSelectUserType = (type) => {
    setData({
      ...data,
      userType: type,
    });
    nextStep();
  };

  const onSearchCompanyClick = (e) => {
    e.preventDefault();
    setSearchMessage("");
    companyService
      .searchCompany(data.searchCompanyParam)
      .then((res) => {
        if (res.data.length === 0) setSearchMessage("Aucune entreprise trouvee");
        if (res.data.length === 1) onCompanyClick(res.data[0]);
        setSearchedCompanies(res.data);
      })
      .catch((err) => {
        console.log(err)
        setSearchMessage("Une erreur est survenue");
      });
  };

  const onCompanyClick = (company) => {
    setData({
      ...data,
      company: company
    })
  };

  const handleRemoveCompany = () => {
    const { company, ...rest } = data; // Extract `company` and keep the rest
    setData(rest); // Update the state without the `company` property
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const onFinishClick = (userFonctionnalites) => {
    const editedUser = getPatchUserObject({ ...user, firstName: data.firstName, lastName: data.lastName, phoneNumber: data.phoneNumber });
    const editedCompany = { ...data.company, siretNumber: data.company?.siret, name: data.company?.companyName };

    userService.confirmFirstConnection(
      {
        userId: user.id,
        userFonctionnalities: userFonctionnalites,
        user: editedUser,
        company: editedCompany
      }
    ).then((res) => {
      console.log(res);
      refreshUser();
    }).catch((err) => console.log(err));
    handleClose();    
  }

  return (
    <>
      <div className="stepper">
        {[1, 2, 3].map((s) => (
          <div
            key={s}
            className={`step ${step >= s ? 'active' : ''}`}
          ></div>
        ))}
      </div>
      <div className="mx-md-5">

        {step === 1 && (
          <>
            <WelcomeHeader />
            <UserTypeStep onSelectUserType={onSelectUserType} />
          </>
        )}
        {step === 2 && (
          <>
            <WelcomeHeader />
            {!data.company && (
              <SearchCompanyStep
                data={data}
                onChange={onChange}
                searchedCompanies={searchedCompanies}
                onSearchCompanyClick={onSearchCompanyClick}
                searchMessage={searchMessage}
                onCompanyClick={onCompanyClick}
                nextStep={nextStep}
                previousStep={prevStep} />
            )}
            {
              data.company && (
                <FoundedCompany data={data} handleRemoveCompany={handleRemoveCompany} nextStep={nextStep} />
              )
            }
          </>
        )}
        {step === 3 && (
          <>
            <KnowEachOtherHeader />
            <KnowEachOther data={data} onChange={onChange} nextStep={nextStep} prevStep={prevStep} />
          </>
        )}
        {step === 4 && (
          <>
            <IsReadyHeader />
            <FonctionnalityChoice onFinishClick={onFinishClick} />
          </>
        )}

      </div>
    </>
  );
};

export default OnBoardingDialog;
