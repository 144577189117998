import { useQuery } from "@tanstack/react-query";
import { defineRulesFor } from "../utils/authorization";
import { userService } from "../utils/_services/user.service.js";


export default function useUserPermissions() {
    const { data = { permissions: [], ability: null }, error, isLoading } = useQuery({
        queryKey: ["permissions"],
        queryFn: () => {
            return  userService
                .getUserPermissions()
                .then((res) => ({ permissions: res.data.result.data.permissions, ability: defineRulesFor(res.data.result.data.permissions) }))            
        },
        options: {
            staleTime: Infinity, // Prevent refetching
            cacheTime: Infinity, // Keep the data in cache indefinitely
            refetchOnWindowFocus: false, // Do not refetch on window focus
        },
    });

    return { data, error, isLoading };
}