import { Container } from "reactstrap";

const GenericHeader = () => {
  return (
    <>
      <div
        className="header pb-2 pt-3 pt-md-6 opacity-8"
        style={{
          // background: "rgb(179, 216, 232)",
          background:
            "linear-gradient(90deg,  rgba(179, 216, 232, 1) 0%,  rgba(39, 166, 221, 1) 100%)",
        }}
      >
        <Container fluid></Container>
      </div>
    </>
  );
};

export default GenericHeader;
