export const ALL = "All";
export const BILLING = "Billing";
export const ROLES = 'Roles';
export const USERS = "Users"
export const PRICEPLAN_ROLES = "PricePlan_roles";
export const ACCESSMANAGEMENT = "AccessManagement";
export const ADMINISTRATION = "Administration";
export const MY_ACCOUNT = "MyAccount";
export const SETTINGS = "Settings";
export const PROFILE = "Profile";
export const SEARCH = "Search";
export const SOCIAL = "Social";
export const DEVIS = "Devis";
export const SUBSCRIPTION = "Subscription";
export const CREATE_SITE = "CreateSite";