import React, { useEffect, useState } from "react";
import CustomCarousel from "../../../components/Carousel/Carousel";
import RolesTable from "./Tables/Roles";
import RoleDetail from "./RoleDetail";
import {
  getAll,
  get,
  add,
  update,
  remove,
} from "../../../utils/_services/role.service.js";
import GenericHeader from "../../../components/Headers/GenericHeader";
import { Container } from "reactstrap";
const Roles = () => {
  const [roles, setRoles] = useState([]);
  const [selectedRoleIndex, setSelectedRoleIndex] = useState(-1);
  const [activeSlide, setActiveSlide] = useState(0);

  const rolesSlideIndex = 0;
  const roleSlideIndex = 1;
  const nextSlide = () => setActiveSlide(activeSlide < 1 ? activeSlide + 1 : activeSlide);
  const previousSlide = () => setActiveSlide(activeSlide > 0 ? activeSlide - 1 : activeSlide);

  useEffect(() => {
    loadRoles();
  }, []);

  const loadRoles = async () => {
    const result = await getAll();
    setRoles(result.data.result.data);
  };

  const onRowClick = async (index) => {
    const result = await get(roles[index].id);
    const editedRoles = [...roles];
    editedRoles[index].permissions =
      result.length > 0 ? result.map((r) => r.name) : [];
    setRoles(editedRoles);
    setSelectedRoleIndex(index);
    nextSlide();
  };

  const onSave = async (index, data) => {
    const editedRoles = [...roles];

    if (index > -1) {
      const result = await update({
        roleId: editedRoles[index].id,
        permissions: data,
      });
      if (result) {
        editedRoles[index].permissions = data;
        setRoles(editedRoles);
      }
    } else {
      const result = await add(data);
      if (result) {
        editedRoles.push(result);
        setRoles(editedRoles);
        setSelectedRoleIndex(editedRoles.length - 1);
      }
    }
  };

  const onDeleteClick = async (index) => {
    await remove(roles[index].id);
    const editedRoles = [...roles];
    editedRoles.splice(index, 1);
    setRoles(editedRoles);
  };

  const items = [
    {
      id: rolesSlideIndex,
      component: (
        <RolesTable
          roles={roles}
          handleRowClick={onRowClick}
          handleDeleteClick={onDeleteClick}
          handleSave={onSave}
        />),
    },
    {
      id: roleSlideIndex,
      component: (
        <RoleDetail
          index={selectedRoleIndex}
          role={roles[selectedRoleIndex]}
          handleSave={onSave}
          handleBackClick={previousSlide}
        />
      ),
    },
  ];

  return (
    <>
      <GenericHeader />
      <Container style={{ maxWidth: "2000px", marginTop: "25px" }}>
        {/* <Loader> */}
        <CustomCarousel items={items} activeIndex={activeSlide} />
        {/* </Loader> */}
      </Container>
    
    </>
  );
};

export default Roles;
