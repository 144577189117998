import { useState, useEffect, useRef } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import AdminNavbar from "../components/Navbars/AdminNavbar.js";
import Sidebar from "../components/Sidebar/Sidebar.js";
import { userService } from "../utils/_services/user.service.js";
import loading from "../assets/img/admin/Process.gif";
import Success from "../views/subscription/Success";
import Canceled from "../views/subscription/Canceled";
import OnBoardingDialog from "../views/OnBoarding/index.js";
import { NotificationContainer } from "react-notifications";
import routes from "../routes.js";
import { useSelector, useDispatch } from "react-redux";
import {
  getUserStatus,
  getCurrentUser,
  fetchUser,
} from "../features/slices/userSlice.js";
import useUserPermissions from "../hooks/useUserPermissions";
import { getAuthorizedRoutes } from "../utils/_helpers/navigation";
import Dialog from "components/Modal/Dialog.js";

const Admin = (props) => {
  const mainContent = useRef(null);
  const [userIsConnected, setUserIsConnected] = useState(
    userService.isLoggedIn()
  );

  const currentUser = useSelector(getCurrentUser);
  const userStatus = useSelector(getUserStatus);
  const dispatch = useDispatch();
  const permissions = useUserPermissions();
  const [show, setShow] = useState(true);
  const toggleDialog = () => setShow(!show);

  useEffect(() => {
    if (userStatus === "idle") {
      dispatch(fetchUser());
    }
    
  }, [userStatus, dispatch]);

  useEffect(() => {
    if (userService.isLoggedIn() === false) {
      userService.initKeycloak().then((keycloak) => {
        if (userService.getTokenParsed() === undefined) {
          userService.doLogin();
        }

        setUserIsConnected(userService.isLoggedIn());

        if (userStatus === "idle") {
          dispatch(fetchUser());
        }
      });
    }
  }, []);

  const flattenMenuItems = (menuItems) => {
    const result = [];

    const flatten = (items) => {
      items.forEach((item) => {
        result.push({ ...item }); // Add the current item to the result
        if (item.childrens) {
          flatten(item.childrens); // Process the children recursively
        }
      });
    };

    flatten(menuItems); // Start flattening from the root menuItems
    return result;
  };

  const getRoutes = (routes) => {
    const authorizedRoutes = getAuthorizedRoutes(routes, permissions.data.permissions);
    return flattenMenuItems(authorizedRoutes).filter(prop => prop.type === "item").map((prop, key) => {
      // console.log(authorizedMenus.find((r) => r.id ===prop.id));
      // if ( (prop.layout === "/admin" || prop.layout === "/ap") && authorizedMenus.find((r) => r.id ===prop.id)) {
      return (
        <Route
          path={prop.layout + prop.path}
          component={prop.component}
          key={key}
        />
      );
      // } else {
      //   return null;
      // }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      {userStatus === "succeeded" && userIsConnected && permissions && permissions.isLoading === false && permissions.data && currentUser ? (
        <>
          <Sidebar
            {...props}
            routes={routes}
            style={{ height: "10rem" }}
            currentUser={currentUser}
            logo={{
              innerLink: "/ap/user-profile",
              imgSrc: require("../assets/img/welcome/logo_btpgo-btipou.png"),
              imgAlt: "...",
            }}
          />
          <div className="main-content" ref={mainContent}>
            <AdminNavbar
              {...props}
              currentUser={currentUser}
              brandText={getBrandText(props.location.pathname)}
            />
            <Switch>
              {getRoutes(routes)}
              <Route
                path="/admin/subscription-canceled"
                component={Canceled}
                key="23"
              />
              <Route
                path="/admin/subscription-success"
                component={Success}
                key="23"
              />
              <Redirect from="*" to="/" />
            </Switch>
            <Container fluid className="position-relative bottom-0">
              {/* <Footer /> */}
            </Container>
          </div>
          {currentUser && currentUser.isFirstConnection && !currentUser.company?.siretNumber &&
            <Dialog
              component={
                <OnBoardingDialog handleClose={toggleDialog} />
              }
            />
          }
        </>
      ) : (
        <div className="d-flex justify-content-center">
          <img className="loading" src={loading} alt=".." />
        </div>
      )}
      <NotificationContainer />
    </>
  );
};

export default Admin;
