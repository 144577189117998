import Footer from "../components/Footers/Footer.js";
import ContactProperty from "../components/Welcome/Property/contact/ContactProperty";
import Description from "../components/Welcome/Property/description/Description";
import IntroProperty from "../components/Welcome/Property/intro/IntroProperty";
import ProblematiqueProperty from "../components/Welcome/Property/problematique/ProblematiqueProperty";
import SolutionProperty from "../components/Welcome/Property/solutions/SolutionProperty";
import React from "react";

const WelcomProperty = () => {
  return (
    <>
      <IntroProperty />
      <ContactProperty />
      <ProblematiqueProperty />
      <SolutionProperty />
      <Description />
      <Footer />
    </>
  );
};

export default WelcomProperty;
