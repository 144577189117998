import { useState } from "react";
import { Button } from "reactstrap";

const FonctionnalityChoice = ({onFinishClick}) => {
    const functionalities = [
        "Gagner des chantiers",
        "Devis",
        "Factures",
        "Réseau Social BTP",
        "Site web Professionnel",
        "Touver des professionals",
        "Autres",
    ];
    const [selectedFunctionalities, setSelectedFunctionalities] = useState([]);
    const handleBadgeClick = (functionality) => {
        setSelectedFunctionalities((prevSelected) =>
            prevSelected.includes(functionality)
                ? prevSelected.filter((item) => item !== functionality) // Deselect if already selected
                : [...prevSelected, functionality] // Select if not already selected
        );
    };

    return (
        <div className="p-1 text-center">
            <div className="text-muted mb-3">Une Dernière chose: quelles fonctionnalités vous intéressent le plus pour votre activité ?</div>
            <div
                className="px-7 d-flex flex-wrap justify-content-center"
                style={{ gap: "3%", fontWeight: "bold" }}
            >
                {functionalities.map((functionality) => (
                    <span
                        key={functionality}
                        className={`chip-badge ${selectedFunctionalities.includes(functionality)
                                ? "chip-selected"
                                : "chip-default"
                            }`}
                        onClick={() => handleBadgeClick(functionality)}
                    >
                        {functionality}
                    </span>
                ))}
            </div>

            <Button
                className="mt-5 ml-3"
                color="primary"
                disabled={selectedFunctionalities.length === 0}
                onClick={() => onFinishClick(selectedFunctionalities)}
            >
                Découvrir BtpGo
            </Button>

        </div>
    );
}
export default FonctionnalityChoice