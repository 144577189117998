import React from "react";
import { NavLink } from "react-router-dom";

export default function CustomNavLink(props) {
    const { field, title, action, to } = props;
    return (
        <NavLink id={field} to={to} onClick={action}  >
            {title}
        </NavLink>
    );
}
